import axios from 'axios';
import { requestHandler, successHandler, errorHandler } from './interceptor/axiosInterceptor';
import constants from "../constants/constants";

const axiosInstance = axios.create({
    baseURL: constants.API_URL + "api/"
})

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
)

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    err => errorHandler(err)
)

export default axiosInstance;