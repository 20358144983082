import React, { useEffect } from "react";

function withAppcuesIdentification(WrappedComponent) {
  function AppcuesIdentification(props) {
    useEffect(() => {
      window.Appcues.identify(props.loggedInUser?.id, {
        firstName: props.loggedInUser?.firstName,
        lastName: props.loggedInUser?.lastName,
        username: props.loggedInUser?.username,
        tier:props.loggedInUser?.tier
      });
    },  [props.loggedInUser]);

    return <WrappedComponent {...props} />;
  }
  return React.memo(AppcuesIdentification);
}

export default withAppcuesIdentification;
