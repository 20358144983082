import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import InputLabel from '@material-ui/core/InputLabel';
import { FilePond } from "react-filepond";
import { useHistory } from "react-router-dom";
import { register } from "../_redux/authCrud";
import { getErrorField, convertJsonToFormData } from "../../../../helpers/helper";
import * as alert from "../../../../redux/alertSnackBarRedux";

const initialValues = {
  id: 0,
  firstName: "",
  lastName: "",
  phoneNumber: "",
  roleId: 0,
  clientId: null,
  username: "",
  password: "",
  logoPath: '',
  businessName: '',
  confirmPassword: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  companyAddress: '',
  isDeleted: false,
  noOfProject: 1,
  removeWaterMark: false,
  removeCobrandedReport: true,
  clientTierId: 0,
  logoFile: '',
};

function Registration(props) {
  const { intl, showAlert } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState([]);
  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneNumber: Yup.string()
      .min(10, "Minimum 10 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    contactEmail: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    logoPath: Yup.string(),
    logoFile: Yup.string()
      .nullable(),
    username: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    businessName: Yup.string()
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    contactFirstName: Yup.string()
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    contactLastName: Yup.string()
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    contactPhone: Yup.string()
      .min(10, "Minimum 10 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    companyAddress: Yup.string()
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(convertJsonToFormData({
        ...values,
        logoFile: logo.length > 0 ? logo[0].file : null
      }))
        .then((res) => {
          disableLoading();
          showAlert({ message: res.message, variant: 'success' });
          setSubmitting(false);
          history.push('/login');
        })
        .catch((ex) => {
          setSubmitting(false);
          showAlert({ message: ex.message, variant: 'error' });
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <div style={{ display: "block", width: '90%' }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold font-size-h3">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'wrap', rowGap: '50px' }} className="form-outline w-100">
          <div className="container pl-0 pr-0" style={{ width: '100%' }}>
            <div className="row pb-5">
              <div className="col-12">
                <h2>User Information</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">First Name</InputLabel>

                  <input
                    placeholder="First name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "firstName"
                    )}`}
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.firstName}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Last Name</InputLabel>
                  <input
                    placeholder="Last name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "lastName"
                    )}`}
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.lastName}</div>
                    </div>
                  ) : null}
                </div>
              </div>


              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Phone Number</InputLabel>
                  <input
                    placeholder="Phone Number"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "phoneNumber"
                    )}`}
                    name="phoneNumber"
                    {...formik.getFieldProps("phoneNumber")}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.phoneNumber}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Email</InputLabel>
                  <input
                    placeholder="Email"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "username"
                    )}`}
                    name="username"
                    {...formik.getFieldProps("username")}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.username}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Password</InputLabel>
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Confirm Password</InputLabel>
                  <input
                    placeholder="Confirm Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "confirmPassword"
                    )}`}
                    name="confirmPassword"
                    {...formik.getFieldProps("confirmPassword")}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.confirmPassword}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>


            <div className="row pb-5">
              <div className="col-12">
                <h2>Client Information</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Business Name</InputLabel>
                  <input
                    placeholder="Business name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "businessName"
                    )}`}
                    name="businessName"
                    {...formik.getFieldProps("businessName")}
                  />
                  {formik.touched.businessName && formik.errors.businessName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.businessName}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Contact First Name</InputLabel>
                  <input
                    placeholder="Contact first name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "contactName"
                    )}`}
                    name="contactFirstName"
                    {...formik.getFieldProps("contactFirstName")}
                  />
                  {formik.touched.contactFirstName && formik.errors.contactFirstName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.contactFirstName}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Contact Last Name</InputLabel>

                  <input
                    placeholder="Contact last name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "contactLastName"
                    )}`}
                    name="contactLastName"
                    {...formik.getFieldProps("contactLastName")}
                  />
                  {formik.touched.contactLastName && formik.errors.contactLastName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.contactLastName}</div>
                    </div>
                  ) : null}
                </div>
              </div>


              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Contact Phone</InputLabel>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder="Contact Phone"
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "contactPhone"
                      )}`}
                      name="contactPhone"
                      {...formik.getFieldProps("contactPhone")}
                    />
                    {formik.touched.contactPhone && formik.errors.contactPhone ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.contactPhone}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Contact Email</InputLabel>

                  <input
                    placeholder="Contact Email"
                    type="contactEmail"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "contactEmail"
                    )}`}
                    name="contactEmail"
                    {...formik.getFieldProps("contactEmail")}
                  />
                  {formik.touched.contactEmail && formik.errors.contactEmail ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.contactEmail}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Company Address</InputLabel>

                  <input
                    placeholder="Company Address"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "companyAddress"
                    )}`}
                    name="companyAddress"
                    {...formik.getFieldProps("companyAddress")}
                  />
                  {formik.touched.companyAddress && formik.errors.companyAddress ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.companyAddress}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 mb-5">
                <div className="form-group fv-plugins-icon-container">
                  <InputLabel htmlFor="standard-age-native-simple">Company Logo</InputLabel>
                  <FilePond
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    onupdatefiles={setLogo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group" style={{ display: 'flex', justifyContent: 'flex-end', width: '87%' }}>
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form >
    </div >
  );
}

export default injectIntl(connect(null, { ...auth.actions, ...alert.actions })(Registration));
