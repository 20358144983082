import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import {
    FormControl,
    InputLabel,
    TextField,
    Select,
} from '@material-ui/core';
import { FilePond } from "react-filepond";
import { useFormik } from "formik";
import moment from 'moment';

import CreateProjectValidator from "../../../../helpers/validators/CreateProjectValidator";
import { getErrorField, convertJsonToFormData } from '../../../../helpers/helper';
import * as projectService from '../../../../services/service/projectService';
import * as projectTypeService from '../../../../services/service/projectTypeService';
import * as clientService from '../../../../services/management/clientService';
import * as alert from '../../../../redux/alertSnackBarRedux';
import * as confirmModal from "../../../../redux/confirmModalRedux";
import constants from "../../../../constants/constants";

const initialState = {
    id: 0,
    projectName: '',
    clientId: '',
    scanDate: '',
    modelLink: '',
    projectTypeId: '',
    notes: '',
    isDeleted: false,
    filesToSave: []
};

const ProjectManagePage = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const { showAlert, showConfirmModal, hideConfirmModal, setProcessing } = props;
    const [project, setProject] = useState(initialState);
    const [clientList, setClientList] = useState([]);
    const [clientId, setClientId] = useState(0);
    const [projectImages, setProjectImages] = useState([]);
    const [filePondFiles, setFilePondFiles] = useState([]);
    const [projectTypeList, setProjectTypeList] = useState([]);
    const [formFieldValues, setFormFieldValues] = useState({});
    const [nspectionTypeSelected, setNspectionTypeSelected] = useState(null);
    const [formJson, setFormJson] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (id && id > 0) {
            projectService.getProjectById(id).then(res => {
                const model = { ...res, scanDate: moment(res.scanDate).format('yyyy-MM-DD') };
                setProject(model);

                if (model.projectType) {
                    setNspectionTypeSelected(model.projectType.name);
                    if (model.formJSON) {
                        setFormJson(JSON.parse(model.formJSON));
                    }
                }

                const existingFiles = model.projectModelImages.map(image => ({
                    source: `${constants.API_URL}api/${image.imagePath}`,
                    options: {
                        type: 'local',
                        file: {
                            name: image.imagePath.split('/').pop(),
                            size: 0
                        }
                    }
                }));
                setFilePondFiles(existingFiles);
            }).catch(err => {
                showAlert({ message: err.message, variant: 'error' });
            });
        }

        clientService.getClients().then(res => {
            setClientList(res);
        });

        if (clientId) {
            projectTypeService.getProjectTypes(clientId).then(res => {
                setProjectTypeList(res);
            });
        } else {
            projectTypeService.getAllProjectTypes().then(res => {
                setProjectTypeList(res);
            })
        }
    }, [id, clientId, refresh]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: id ? project : initialState,
        validationSchema: CreateProjectValidator,
        onSubmit: (values, { setSubmitting }) => {
            saveProject(values, setSubmitting)
        }
    });

    const handleFileUpload = (fileItems) => {
        setProjectImages(fileItems.map((fileItem) => (fileItem.file)));
    };

    const saveProject = (values, setSubmitting) => {
        setProcessing(true);
        setLoading(true);

        if (!values.modelLink && !projectImages.length > 0) {
            window.alert('Please upload images for the project or enter your model link to proceed.')
        } else {
            let valueToPost = values;

            if (nspectionTypeSelected && constants[nspectionTypeSelected]) {
                valueToPost = { ...valueToPost, formJSON: JSON.stringify(fillJsonFormValue()) }
            }

            const model = convertJsonToFormData(valueToPost);
            projectImages.forEach(file => {
                model.append("filesToSave", file);
            });

            projectService.saveProject(model).then(res => {
                showAlert({ message: res.message, variant: 'success' });
                if(id && id > 0) {
                    setRefresh(!refresh);
                } else {
                    history.push('/projects');
                }
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' });
            }).finally(() => {
                setSubmitting(false);
                setProcessing(false);
                hideConfirmModal();
                // resetProjectToEdit();
                setLoading(false);
            })
        }
    }

    // const resetProjectToEdit = () => {
    //     setProject(initialState);
    //     hideConfirmModal();
    //     setFilePondFiles([]);
    //     setNspectionTypeSelected(null);
    //     setFormJson(null);
    //     formik.resetForm();
    // };

    const fillJsonFormValue = () => {
        const tempFormJson = formJson;
        const objectKeys = Object.keys(formFieldValues);

        for (let i = 0; i < objectKeys.length; i += 1) {
            for (let j = 0; j < tempFormJson.length; j += 1) {
                const currentKey = objectKeys[i];

                if (tempFormJson[j].key === currentKey) {
                    tempFormJson[j].value = formFieldValues[currentKey];
                    break;
                }
            }
        }

        return tempFormJson;
    }

    const renderFormField = (field) => {
        let { type, key, label, value } = field;
        switch (type) {
            case 'text':
                return (
                    <div key={key} className="col-lg-6">
                        <TextField
                            id={`outlined-${key}`}
                            label={label}
                            margin="normal"
                            variant="outlined"
                            value={(formFieldValues[key] || formFieldValues[key] === '') ? formFieldValues[key] : value}
                            onChange={({ target }) => handleValueChange(target.value, key)}
                        />
                    </div>
                );
            case 'date':
                return (
                    <div key={key} className="col-lg-6">
                        <TextField
                            type="date"
                            id={`outlined-${key}`}
                            label={label}
                            margin="normal"
                            variant="outlined"
                            placeholder=' '
                            value={(formFieldValues[key] || formFieldValues[key] === '') ? formFieldValues[key] : value}
                            onChange={({ target }) =>   (target.value, key)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                );
            default:
                return <p>Loading...</p>;
        }
    };

    const handleValueChange = (val, key) => {
        setFormFieldValues((prevValues) => ({
            ...formFieldValues,
            [key]: val,
        }));
    };

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                {id && id > 0 ? "Edit" : "Create"} Nspection
                            </span>
                            <span className="text-muted mt-3 font-weight-bold fz-xs">
                                View and Manage Nspection
                            </span>
                        </h3>
                    </div>
                    <div className="card-body">
                        {loading ? (
                            <Spinner animation="border" />
                        ) : (
                            <form onSubmit={formik.handleSubmit}>
                                <div className="card-toolbar">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <FilePond
                                                acceptedFileTypes={['image/*']}
                                                files={filePondFiles}
                                                allowMultiple={true}
                                                disabled={id && id > 0}
                                                onupdatefiles={handleFileUpload}
                                                maxFiles={3}
                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <TextField
                                                error={getErrorField(formik, "projectName")}
                                                id="outlined-name"
                                                label="Nspection Name"
                                                margin="normal"
                                                variant="outlined"
                                                {...formik.getFieldProps("projectName")}
                                            />
                                            {formik.touched.projectName && formik.errors.projectName && (
                                                <div className="text-danger">{formik.errors.projectName}</div>
                                            )}

                                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 15 }}>
                                                <InputLabel htmlFor="outlined-age-native-simple">Client</InputLabel>
                                                <Select
                                                    native
                                                    label="Client"
                                                    disabled={id && id > 0}
                                                    value={formik.values.clientId}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        formik.setFieldValue("clientId", value === "" ? "" : Number(value));
                                                        setClientId(value === "" ? 0 : Number(value));
                                                    }}
                                                    inputProps={{
                                                        name: 'Client',
                                                        id: 'outlined-age-native-simple',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {clientList.map(x => (
                                                        <option key={x.id} value={x.id}>{x.businessName}</option>
                                                    ))}
                                                </Select>
                                                {formik.touched.clientId && formik.errors.clientId && (
                                                    <div className="text-danger">{formik.errors.clientId}</div>
                                                )}
                                            </FormControl>

                                            <TextField
                                                error={getErrorField(formik, "scanDate")}
                                                id="outlined-scanDate"
                                                type="date"
                                                label="Scan Date"
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                {...formik.getFieldProps("scanDate")}
                                            />
                                            {formik.touched.scanDate && formik.errors.scanDate && (
                                                <div className="text-danger">{formik.errors.scanDate}</div>
                                            )}
                                        </div>

                                        <div className="col-lg-6">
                                            <TextField
                                                id="outlined-multiline-flexible"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                margin="normal"
                                                variant="outlined"
                                                {...formik.getFieldProps("notes")}
                                            />

                                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                                                <InputLabel htmlFor="outlined-age-native-simple">Nspection Type</InputLabel>
                                                <Select
                                                    error={getErrorField(formik, "projectTypeId")}
                                                    native
                                                    label="Nspection Type"
                                                    disabled={id && id > 0}
                                                    value={formik.values.projectTypeId}
                                                    onChange={e => {
                                                        if (e.target.value === "") {
                                                            formik.setFieldValue("projectTypeId", "");
                                                        }
                                                        else {
                                                            const selectedNspection = projectTypeList.find((x) => x.id == e.target.value);
                                                            if (selectedNspection) {
                                                                setNspectionTypeSelected(selectedNspection.name);
                                                                if (project.formJSON) {
                                                                    setFormJson(constants[selectedNspection.name].fields);
                                                                }
                                                            }

                                                            formik.setFieldValue("projectTypeId", Number(e.target.value));
                                                        }
                                                    }}
                                                    inputProps={{
                                                        name: 'ProjectType',
                                                        id: 'outlined-age-native-simple',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        projectTypeList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                                    }
                                                </Select>
                                                {formik.touched.projectTypeId && formik.errors.projectTypeId ? (
                                                    <div className="text-danger">
                                                        {formik.errors.projectTypeId}
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>

                                        <div className="col-lg-12">
                                            <TextField
                                                error={getErrorField(formik, "modelLink")}
                                                id="outlined-modelLink"
                                                label="3D Model Link"
                                                disabled={id && id > 0}
                                                margin="normal"
                                                variant="outlined"
                                                {...formik.getFieldProps("modelLink")}
                                            />
                                            {formik.touched.modelLink && formik.errors.modelLink && (
                                                <div className="text-danger">{formik.errors.modelLink}</div>
                                            )}
                                        </div>

                                        {nspectionTypeSelected && formJson && (
                                            <>
                                                <div className='col-lg-12'>
                                                    <h4>{constants[nspectionTypeSelected].title}</h4>
                                                </div>
                                                {formJson.map((x) => renderFormField(x))}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex align-content-end w-100 justify-content-end'>
                                    <Button
                                        className="btn-danger ml-3 mr-3"
                                        type="button"
                                        onClick={() => {
                                            // resetProjectToEdit();
                                            history.push('/projects');
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {
                        !(id && id > 0) ?
                            (
                                <Button className="btn-success"
                                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid) || (!projectImages.length > 0 && !formik.values.modelLink)}
                                    type="button"
                                    onClick={() => {
                                        showConfirmModal({
                                            title: 'Create Nspection',
                                            text: 'Please revise all of your information before creating the Nspection. Are you sure you want to proceed?',
                                            rightBtnText: 'Confirm',
                                            btnAction: () => saveProject(formik.values, formik.setSubmitting)
                                        })
                                    }}
                                >Create Nspection
                                    {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                                </Button>
                            ) :
                            (
                                <Button className="btn-success"
                                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid) || (!projectImages.length > 0 && !formik.values.modelLink)}
                                    type="submit"
                                >Update Nspection
                                    {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}</Button>
                            )
                    }
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ProjectManagePage);