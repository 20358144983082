import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "PointCategory/";

const getPointCategoryById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetPointCategoryById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getPointCategoryByProjectTypeId = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetPointCategoryByProjectTypeId?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getPointCategories = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetPointCategories', constants.REQUIRE_INTERCEPTORS);

const savePointCategory = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SavePointCategory', model, constants.REQUIRE_INTERCEPTORS);

const deletePointCategory = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeletePointCategory?id=' + id, constants.REQUIRE_INTERCEPTORS);

const deletePointCategoryIsDeletedFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeletePointCategoryIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS);

export {
    getPointCategoryById,
    getPointCategoryByProjectTypeId,
    getPointCategories,
    savePointCategory,
    deletePointCategory,
    deletePointCategoryIsDeletedFlag
}