import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from 'react-bootstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SketchPicker } from 'react-color';
import * as poiStatusService from '../../../services/settings/POIStatusService';
import POIStatusValidator from '../../../helpers/validators/PointCategoryValidator';
import * as alert from '../../../redux/alertSnackBarRedux';

const initialPOIStatusState = {
  id: 0,
  name: '',
  description: '',
  poiColorCode: '',
  isDefault: false,
  isDeleted: false,
};

const ProjectTypePOIStatusManagePage = (props) => {
  const { showAlert } = props;
  const { id, statusId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [poiColor, setPoiColor] = useState('');
  const [poiStatusData, setPOIStatusData] = useState(initialPOIStatusState);
  const [projectType, setProjectType] = useState(location.state?.projectType || null);

  useEffect(() => {
    if (statusId) {
      loadPOIStatusData(statusId);
    }
  }, [statusId]);

  const loadPOIStatusData = (id) => {
    poiStatusService.getPOIStatusById(id).then(res => {
      setPOIStatusData(res);
      setPoiColor(res.poiColorCode || '');
    }).catch(ex => {
      showAlert({ message: ex.message, variant: 'error' });
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: poiStatusData,
    validationSchema: POIStatusValidator,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      const updatedValues = { ...values, poiColorCode: poiColor, projectTypeId: id };
      poiStatusService.savePOIStatus(updatedValues)
        .then(res => {
          showAlert({ message: res.message, variant: 'success' });
          history.push(`/settings/poi-listing/${id}`, { projectType });
        })
        .catch(ex => {
          showAlert({ message: ex.message, variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        });
    },
  });

  const handleColorChange = (color) => {
    setPoiColor(color.hex);
    formik.setFieldValue('poiColorCode', color.hex);
  };

  return (
    <div className="col-lg-12 col-xxl-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body">
          <h3 className="card-title">
            {/* Show Project Type Name */}
            {projectType && (
              <div>
                <h5>{projectType.name}</h5>
              </div>
            )}
            {statusId ? 'Edit POI Status' : 'Add New POI Status'}
          </h3>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Name"
              variant="outlined"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              {...formik.getFieldProps('description')}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isDefault}
                  onChange={(e) => formik.setFieldValue('isDefault', e.target.checked)}
                />
              }
              label="User Upload Value"
            />
            {loading && <LinearProgress />}
            <SketchPicker color={poiColor} onChangeComplete={handleColorChange} />
            <div className="d-flex justify-content-end mt-4">
              <Button
                variant="secondary"
                onClick={() => history.push(`/settings/poi-listing/${id}`, { projectType })}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                className="ml-2"
              >
                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                {statusId ? 'Update' : 'Create'} POI Status
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, alert.actions)(ProjectTypePOIStatusManagePage);
