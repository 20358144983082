/* eslint-disable no-restricted-imports */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { convertJsonToFormData } from '../../helpers/helper';
import TextField from '@material-ui/core/TextField';
import CommentValidator from "../../helpers/validators/CommentValidatorForAuthorizedUser";
import { useFormik } from "formik";
import { getErrorField } from '../../helpers/helper';

import * as projectPinCommentService from '../../services/settings/projectPinCommentService';
import * as alert from '../../redux/alertSnackBarRedux';
import moment from 'moment';

const initialState = {
    id: 0,
    projectPinId: 0,
    anonymousName: '',
    projectTypePoiStatusId: 0,
    comment: '',
    commentedOn: '',
    matterportResolutionLink: '',
    pOIResolutionImagePath: '',
    isDeleted: false
}
const AddCommentModal = (props) => {

    const { show, hide, showAlert, pin, token, auth } = props;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialState,
        validationSchema: CommentValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveProjectPinComment(values, setSubmitting);
        }
    });

    const saveProjectPinComment = (values, setSubmitting) => {
        var model = {
            ...values,
            projectTypePoiStatusId: pin.projectTypePOIStatusId,
            projectPinId: pin.id,
            pOIResolutionImagePath: '',
            matterportResolutionLink: '',
            commentedOn: moment().format('MM/DD/yyyy hh:mm a'),
            userId: null,
            isDeleted: false,
        };

        if (token) {
            projectPinCommentService.addAnonymousComments({ ...model, token })
                .then(res => {
                    showAlert({ message: res.message, variant: 'success' });
                    hideModal();
                }).catch(ex => {
                    showAlert({ message: ex.message, variant: 'error' });
                }).finally(() => {
                    setSubmitting(false);
                })
        }
        else {
            projectPinCommentService.saveProjectPinComment(convertJsonToFormData(model))
                .then(res => {
                    showAlert({ message: res.message, variant: 'success' });
                    hideModal();
                }).catch(ex => {
                    showAlert({ message: ex.message, variant: 'error' });
                }).finally(() => {
                    setSubmitting(false);
                })
        }
    }

    const hideModal = () => {
        formik.resetForm();
        hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Comment
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row" style={{ alignContent: 'center' }}>
                        <div className="col-lg-12">
                            {
                                token ? (<>
                                    <TextField
                                        error={getErrorField(formik, "anonymousName")}
                                        id="outlined-name"
                                        label="Full Name"
                                        margin="normal"
                                        variant="outlined"
                                        className="large-textarea"
                                        {...formik.getFieldProps("anonymousName")}
                                    />

                                    {formik.touched.anonymousName && formik.errors.anonymousName ? (
                                        <div className="text-danger">
                                            {formik.errors.anonymousName}
                                        </div>
                                    ) : null}
                                </>

                                ) : (
                                    <>
                                        <TextField
                                            id="outlined-name"
                                            disabled
                                            label="Full Name"
                                            margin="normal"
                                            variant="outlined"
                                            value={auth?.user?.firstName + ' ' + auth?.user?.lastName}
                                            className="large-textarea"
                                        />
                                    </>
                                )
                            }

                        </div>
                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "comment")}
                                id="outlined-name"
                                label="Comment"
                                multiline
                                rowsMax="4"
                                margin="normal"
                                variant="outlined"
                                className="large-textarea"
                                {...formik.getFieldProps("comment")}
                            />

                            {formik.touched.comment && formik.errors.comment ? (
                                <div className="text-danger">
                                    {formik.errors.comment}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideModal}>Cancel</Button>
                    <Button className="btn-success" disabled={formik.isSubmitting || (formik.touched && !formik.isValid)} type="submit">Add Comment</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
  })
  
  export default connect(mapStateToProps, { ...alert.actions })(AddCommentModal);