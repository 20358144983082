const constants = {
    API_URL: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_LIVE_URL : process.env.REACT_APP_DEV_URL,
    REQUIRE_INTERCEPTORS: {
        requestHandlerEnabled: true,
        responseHandlerEnabled: true
    },
    // MATTERPORT_API_KEY: process.env.REACT_APP_ENVIRONMENT === "development" ? '1sz5y21iidtbqnpip4y8keayc' : '7xfyy3zpqwfcf67rg23x5bf2a',
    MATTERPORT_API_KEY: process.env.REACT_APP_ENVIRONMENT === "development" ? '1sz5y21iidtbqnpip4y8keayc' : '1yrc5xm0gwd5pzxp76yw2x6aa',
    MATTERPORT_VERSION: '3.5',
    'Property Improvement Plan': {
        title: 'Property Details & Renovation Schedule',
        fields: [
            {
                "key": "propertyName",
                "label": "Property Name",
                "type": "text",
                "value": "",
            },
            {
                "key": "propertyLocation",
                "label": "Property Location",
                "type": "text",
                "value": "",
            },
            {
                "key": "hyattSpiritCode",
                "label": "Hyatt Spirit Code",
                "type": "text",
                "value": "",
            },
            {
                "key": "sellerOwnershipInformation",
                "label": "Owner Information (Seller)",
                "type": "text",
                "value": "",
            },
            {
                "key": "buyerOwnershipInformation",
                "label": "Ownership Information (Buyer)",
                "type": "text",
                "value": "",
            },
            {
                "key": "currentManagementInformation",
                "label": "Management Information (Current)",
                "type": "text",
                "value": "",
            },
            {
                "key": "proposedManagementInformation",
                "label": "Management Information (Proposed)",
                "type": "text",
                "value": "",
            },
            {
                "key": "totaNumberOfGuestRooms",
                "label": "Total Number Of Guestrooms",
                "type": "text",
                "value": "",
            },
            {
                "key": "totalNumberOfFloors",
                "label": "Total Number of Floors",
                "type": "text",
                "value": "",
            },
            {
                "key": "meetingSpace",
                "label": "Meeting Space s.f.:",
                "type": "text",
                "value": "",
            },
            {
                "key": "constructionGeneration",
                "label": "Construction Generation",
                "type": "text",
                "value": "",
            },
            {
                "key": "originalConstructionDate",
                "label": "Original Construction Date",
                "type": "date",
                "value": "",
            },
            {
                "key": "hyattOpenDate",
                "label": "Hyatt Open Date",
                "type": "date",
                "value": "",
            },
            {
                "key": "mostCurrentRenovation",
                "label": "Most Current Renovation",
                "type": "text",
                "value": "",
            },
            {
                "key": "pipWalkthroughDate",
                "label": "PIP Walkthrough Date",
                "type": "date",
                "value": "",
            },
            {
                "key": "pipIssueDate",
                "label": "PIP Issue Date",
                "type": "date",
                "value": "",
            },
            {
                "key": "pipIsValidUntil",
                "label": "PIP Is Valid Until",
                "type": "date",
                "value": "",
            },
            {
                "key": "followUpWalkthroughDate",
                "label": "Follow-up Walkthrough Date",
                "type": "date",
                "value": "",
            },
            {
                "key": "leadCertification",
                "label": "Leed Certification",
                "type": "text",
                "value": "",
            },
            {
                "key": "7yearCycle",
                "label": "7 Year Cycle",
                "type": "text",
                "value": "",
            },
            {
                "key": "7yearCycleDescription",
                "label": "7 Year Cycle Description",
                "type": "text",
                "value": "",
            },
            {
                "key": "7yearCycleDate",
                "label": "7 Year Cycle Date",
                "type": "date",
                "value": "",
            },
            {
                "key": "14yearCycle",
                "label": "14 Year Cycle",
                "type": "text",
                "value": "",
            },
            {
                "key": "14yearCycleDescription",
                "label": "14 Year Cycle Description",
                "type": "text",
                "value": "",
            },
            {
                "key": "14yearCycleDate",
                "label": "14 Year Cycle Date",
                "type": "date",
                "value": "",
            },
            {
                "key": "21yearCycle",
                "label": "21 Year Cycle",
                "type": "text",
                "value": "",
            },
            {
                "key": "21yearCycleDescription",
                "label": "21 Year Cycle Description",
                "type": "text",
                "value": "",
            },
            {
                "key": "21yearCycleDate",
                "label": "21 Year Cycle Date",
                "type": "date",
                "value": "",
            }
        ]
    }
};

export default constants;