import React from 'react';
import {
  Text,
  View,
  Image,
  StyleSheet,
  Font
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    // fontFamily: 'Lato Bold'
  },
  wrapper: {
    height: 60,  // Adjust based on the height needed for your introductory text
  },
  textHack: {
    position: 'absolute',
    opacity: 0.5,
    fontSize: 10,
    top: 0,
    left: 0,
  },
  container: {
    marginTop: 4,
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: 10,
  },
  tableNameRow: {
    flexDirection: 'row',
    backgroundColor: '#e2eaf5',
    color: 'black',
    border: 1
  },
  tableNameTextView: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#fff',
    textAlign: 'center',
    fontSize: 14,
    // fontFamily: 'Lato Bold' 
  },
  dataRowContainer: {
    flexDirection: 'row',
  },
  dataCellContainer: {
    flex: 1,
    borderLeft: 0.55,
    borderBottom: 0.55,
    borderRight: 0.55,
    padding: 2,
    display: 'flex',
    justifyContent: 'center'
  },
  dataCellAnswer: {
    padding: 2,
    fontSize: 8,
    // fontFamily: 'Lato Bold',
  },
  dataCellLabel: {
    padding: 2,
    fontSize: 8,
  },
  secondTableCellContainer: {
    borderLeft: 0.55,
    borderBottom: 0.55,
    borderRight: 0.55,
    padding: 2,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  }
});

Font.register({
  family: "Lato Bold",
  src: "https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf",
});

const PropertyImprovementPlanReportPropertyDetails = (props) => {
  const { formJson } = props;

  const getFormValue = (key) => {
    const field = formJson.find((x) => x.key === key);
    if (field) {
      return field.value;
    }

    return "";
  }

  return (
    <>


      <View style={styles.wrapper}>
        <Text style={styles.textHack}>
          The improvements in this report are related to visual and FF&E items based on conditions noted during the walkthrough of
          the property. This Property Improvement Plan is valid until 08/01/2024, and at such time is subject to an updated PIP plan as
          needed and determined by the Brand. All improvements listed below are in addition to compliance with the required Brand
          Renovation Cycle.
        </Text>
        <Text style={styles.textHack}>
          The improvements in this report are related to visual and FF&E items based on conditions noted during the walkthrough of
          the property. This Property Improvement Plan is valid until 08/01/2024, and at such time is subject to an updated PIP plan as
          needed and determined by the Brand. All improvements listed below are in addition to compliance with the required Brand
          Renovation Cycle.
        </Text>    
        <Text style={styles.textHack}>
          The improvements in this report are related to visual and FF&E items based on conditions noted during the walkthrough of
          the property. This Property Improvement Plan is valid until 08/01/2024, and at such time is subject to an updated PIP plan as
          needed and determined by the Brand. All improvements listed below are in addition to compliance with the required Brand
          Renovation Cycle.
        </Text>   
        <Text style={styles.textHack}>
          The improvements in this report are related to visual and FF&E items based on conditions noted during the walkthrough of
          the property. This Property Improvement Plan is valid until 08/01/2024, and at such time is subject to an updated PIP plan as
          needed and determined by the Brand. All improvements listed below are in addition to compliance with the required Brand
          Renovation Cycle.
        </Text>
      </View>

      <View style={styles.container}>
        <View style={styles.tableContainer}>
          {/* Header row with colspan */}
          <View style={styles.tableNameRow}>
            <View style={styles.tableNameTextView}>
              <Text colSpan={5}>PROPERTY DETAILS</Text>
            </View>
          </View>
          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Property Name:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('propertyName')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Construction Generation:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('constructionGeneration')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Property Location:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('propertyLocation')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Original Construction Date:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('originalConstructionDate')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Hyatt Spirit Code:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('hyattSpiritCode')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Hyatt Open Date:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('hyattOpenDate')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Ownership Information (Seller):</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('sellerOwnershipInformation')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Most Current Renovation:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('mostCurrentRenovation')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Ownership Information (Buyer):</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('buyerOwnershipInformation')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>PIP Walkthrough Date:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('pipWalkthroughDate')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Management Information (Current):</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('currentManagementInformation')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>PIP Issue Date:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('pipIssueDate')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Management Information (Proposed):</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('proposedManagementInformation')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>PIP is Valid until:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('pipIsValidUntil')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Total Number of Guestrooms:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('totaNumberOfGuestRooms')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Follow-up Walkthrough Date:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('followUpWalkthroughDate')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Total Number of Floors:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('totalNumberOfFloors')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Leed Certification:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('leadCertification')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Meeting Space s.f.:</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}>{getFormValue('meetingSpace')}</Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}></Text>
            </View>
            <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellAnswer}></Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.container}>
        <View style={styles.tableContainer}>
          {/* Header row with colspan */}
          <View style={styles.tableNameRow}>
            <View style={styles.tableNameTextView}>
              <Text colSpan={5}>RENOVATION SCHEDULE</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={{ ...styles.secondTableCellContainer, width: 40 }}>
              <Text style={styles.dataCellAnswer}>7 Year Cycle:</Text>
            </View>
            <View style={{ ...styles.secondTableCellContainer, width: 90 }}>
              <Text style={styles.dataCellLabel}>{getFormValue('7yearCycle')}</Text>
            </View>
            <View style={{ ...styles.secondTableCellContainer, width: 250 }}>
              <Text style={styles.dataCellLabel}>{getFormValue('7yearCycleDescription')}</Text>
            </View> <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Renovation Cycle to be completed by:</Text>
            </View> <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>{getFormValue('7yearCycleDate')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={{ ...styles.secondTableCellContainer, width: 40 }}>
              <Text style={styles.dataCellAnswer}>14 Year Cycle:</Text>
            </View>
            <View style={{ ...styles.secondTableCellContainer, width: 90 }}>
              <Text style={styles.dataCellLabel}>{getFormValue('14yearCycle')}</Text>
            </View>
            <View style={{ ...styles.secondTableCellContainer, width: 250 }}>
              <Text style={styles.dataCellLabel}>{getFormValue('14yearCycleDescription')}</Text>
            </View> <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Renovation Cycle to be completed by:</Text>
            </View> <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>{getFormValue('14yearCycleDate')}</Text>
            </View>
          </View>

          <View style={styles.dataRowContainer}>
            <View style={{ ...styles.secondTableCellContainer, width: 40 }}>
              <Text style={styles.dataCellAnswer}>21 Year Cycle:</Text>
            </View>
            <View style={{ ...styles.secondTableCellContainer, width: 90 }}>
              <Text style={styles.dataCellLabel}>{getFormValue('21yearCycle')}</Text>
            </View>
            <View style={{ ...styles.secondTableCellContainer, width: 250 }}>
              <Text style={styles.dataCellLabel}>{getFormValue('21yearCycleDescription')}</Text>
            </View> <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>Renovation Cycle to be completed by:</Text>
            </View> <View style={styles.dataCellContainer}>
              <Text style={styles.dataCellLabel}>{getFormValue('21yearCycleDate')}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  )
}


export default PropertyImprovementPlanReportPropertyDetails;