import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as projectTypeMetaDataFieldService from "../../../services/settings/projectTypeMetaDataFieldService";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';

const ProjectTypeMetaDataFieldListingPage = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const { projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        loadTableData();
    }, [loadTableData])

    const loadTableData = useCallback(() => {
        projectTypeMetaDataFieldService.getProjectTypeMetaDataFieldByProjectTypeId(id).then(res => {
            setData(res);
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        });
    }, [id, showAlert])

    const editField = (fieldId) => {
        history.push(`/settings/projectType-metaDataField/manage/${id}/${fieldId}`, { projectType });
    }

    const deleteField = (fieldId, projectTypeId) => {
        let newModel = { id: fieldId, projectTypeId: projectTypeId, isDeleted: true }
        projectTypeMetaDataFieldService.softDeleteProjectTypeMetaDataField(newModel).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "question",
            label: "Question",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => editField(data[dataIndex].id)}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete Nspection Property",
                                    text: "Are you sure you want to delete this Nspection Property?",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deleteField(data[dataIndex].id, data[dataIndex].projectTypeId)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1 ml-2"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },
        },
    ];

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                {projectType?.name}
                            </span>
                            <span className="card-label font-weight-bolder text-dark">
                                Nspection Properties
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => history.push(`/settings/projectType-metaDataField/manage/${id}`, { projectType })}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add Nspection Property
                            </button>
                        </div>
                    </div>
                    <ProjectTypeNavbar id={id} projectType={projectType} />
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ProjectTypeMetaDataFieldListingPage);