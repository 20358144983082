import axios from 'axios';
import axiosInstance from "../../../../services/apiService";
export const LOGIN_URL = "Users/Authenticate";
export const REGISTER_URL = "Users/Register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(user) {
  return axiosInstance.post(LOGIN_URL, user, {
    requestHandlerEnabled: false,
    responseHandlerEnabled: true
  });
}

export function register(user) {
  return axiosInstance.post(REGISTER_URL, user,{
    requestHandlerEnabled: false,
    responseHandlerEnabled: true,
    fileHandler: true
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
