/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React from "react";
import WithAppcuesIdentification from "./appcuesHOC/WithAppcuesIdentification";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import AlertSnackBar from "./components/AlertSnackBar";
import ConfirmModal from "./components/ConfirmModal";
import ProjectReportPage from "./pages/public/ProjectReportPage";

const AuthPageWithAppcuesIdentification = WithAppcuesIdentification(AuthPage);
const BasePageWithAppcuesIdentification = WithAppcuesIdentification(BasePage);

function Routes() {
  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const { role } = useSelector(
    ({ auth }) => ({
      role: auth.role ?? null,
    }),
    shallowEqual
  );

  const { clientRefId } = useSelector(
    ({ auth }) => ({
      clientRefId: auth.clientRefId ?? null,
    }),
    shallowEqual
  );

  return (
    <>
      <Switch>
        <Route path="/project-report" component={ProjectReportPage} />
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPageWithAppcuesIdentification loggedInUser={user}/>
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <Layout role={role} clientRefId={clientRefId}>
            <BasePageWithAppcuesIdentification loggedInUser={user}/>
          </Layout>
        )}
      </Switch>
      <AlertSnackBar />
      <ConfirmModal />
    </>
  );
}

export default Routes;


