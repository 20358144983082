/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import Banner from './../../../../_metronic/_assets/js/components/banner';
import { toAbsoluteUrl } from "../../../_helpers";
import { QuickActions } from "./components/QuickActions";
import * as settingService from '../../../../services/settings/settingService';
import { useHistory, Link } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import jwt_decode from 'jwt-decode';
import * as clientService from '../../../../services/management/clientService'
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import * as authRedux from "../../../../app/modules/Auth/_redux/authRedux";
import * as customCSS from "../../../../app/assets/styles/custom.css";
import Alert from 'react-bootstrap/Alert';

const linkModel = {
  url: '',
  text: 'link'
}

const customBannerStyling = {
  'background': 'black',
};

const SubHeader = (props) => {
  const { auth, upgradeTier } = props;
  const [showBanner, setShowBanner] = useState(true);
  const [link, setLink] = useState({ ...linkModel })
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  useEffect(() => {
    if (auth && auth.user && auth.user.tier === 'Individual') {
      settingService.getSettingByKey('StarterSignUpLink').then(res => {
        setLink({ ...linkModel, url: res, text: 'link' });
      }).catch(err => {
        console.log('err')
      })
    }
    if (auth && auth.user && auth.user.tier === 'Starter') {
      settingService.getSettingByKey('ProfessionalSignUpLink').then(res => {
        setLink({ ...linkModel, url: res, text: 'link' });
      }).catch(err => {
        console.log('err')
      })
    }
  }, [auth])

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
    setLink({ ...linkModel })
  };

  const updateClientTier = (tierToUpdateTo) => {
    let tokenDecoded = jwt_decode(auth.authToken);
    if (Object.keys(tokenDecoded).length) {
      history.push(`/chargify/${tokenDecoded.ClientId}`, {
        clientTier: tierToUpdateTo
      });
      // clientService.upgradeClientTier(tokenDecoded.ClientId, tierToUpdateTo).then(res => {
      //   if (res.result) {
      //     upgradeTier(tierToUpdateTo);
      //   }
      // }).catch(err => {
      //   console.log('tier upgrade err', err);
      // })
    }
  }

  const handleLinkClick = (linkValue, val) => {
    // window.open(linkValue.url, '_blank');
    updateClientTier(val);
  }

  return (
    <>
      <div
        id="kt_subheader"
        className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
      >
        <div
          className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {/* begin::Mobile Toggle */}
            {layoutProps.subheaderMobileToggle && (
              <button
                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}
            {/* end::Mobile Toggle */}

            {/* begin::Heading */}
            <div className="d-flex align-items-baseline mr-5">
              {/* begin::Title */}
              <h2 className="subheader-title text-dark font-weight-bold my-2 mr-3">
                {subheader.title}
              </h2>
              {/* end::Title */}

              <BreadCrumbs items={subheader.breadcrumbs} />
            </div>
            {/* end::Heading */}
          </div>
          {/* Toolbar */}
          {/* Toolbar */}
          <div className="d-flex align-items-center flex-wrap">
            {(auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager' || auth.role === 'User') ? (
              <div className="d-flex flex-wrap align-items-center">
                {/* <Link
                  to="/projects"
                  className="btn btn-warning font-weight-bold px-2 px-lg-5 mr-2 mb-2 mb-lg-0"
                >
                  <span className="svg-icon svg-icon-lg svg-icon-white">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
                  </span>
                  <span className="d-none d-md-inline">View Nspections</span>
                </Link> */}
                {(auth.role === "SuperAdmin" || auth.role === "Admin") && (
                  <div className="mr-2 mb-2 mb-lg-0">
                    <QuickActions />
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="toggle-quick-actions">Logout</Tooltip>}
            >
              <a className="btn btn-icon btn-secondary btn-md" onClick={logoutClick}>
                <span className="svg-icon svg-icon-lg svg-icon-dark-50">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Door-open.svg")} />
                </span>
              </a>
            </OverlayTrigger>
          </div>
        </div>
      </div>

      <div
        className={`${layoutProps.subheaderContainerCssClasses}`}
        style={{ width: '100%' }}
      >
        <div className="d-flex align-items-center flex-wrap mr-1">
          {
            auth && auth.user.tier === 'Individual' && auth.role === 'Admin' && (
              <div style={{ width: '100%' }}>
                <Alert style={{
                  fontSize: 16,
                  fontWeight: '500',
                  // PRIMARY - BLUE
                  // backgroundColor: '#c9e9ff',

                  // SUCCESS - GREEN
                  // backgroundColor: '#d9fadd',

                  // WARNING - YELLOW
                  backgroundColor: '#ffd88d',

                  // ERROR - RED
                  // backgroundColor: '#ffebee',

                  color: '#151515',
                  padding: '10px 15px',
                }}
                  show={showBanner}
                >
                  You are currently using individual tier account. To access the full features, click <Alert.Link
                    onClick={() => handleLinkClick(link, 'Starter')}
                  >here</Alert.Link> to upgrade
                </Alert>
              </div>
            )
          }
          {
            auth && auth.user.tier === 'Individual' && auth.role !== 'Admin' && (
              <div style={{ width: '100%' }}>
                <Alert style={{
                  fontSize: 16,
                  fontWeight: '500',
                  // PRIMARY - BLUE
                  // backgroundColor: '#c9e9ff',

                  // SUCCESS - GREEN
                  // backgroundColor: '#d9fadd',

                  // WARNING - YELLOW
                  backgroundColor: '#ffd88d',

                  // ERROR - RED
                  // backgroundColor: '#ffebee',

                  color: '#151515',
                  padding: '10px 15px',
                }}
                  show={showBanner}
                >
                  You are currently using individual tier account. To access the full features, please contact your administrator.
                </Alert>
              </div>
            )
          }

          {
            auth && auth.user.tier === 'Starter' && auth.role === 'Admin' && (
              <div style={{ width: '100%' }}>
                <Alert style={{
                  fontSize: 16,
                  fontWeight: '500',
                  // PRIMARY - BLUE
                  // backgroundColor: '#c9e9ff',

                  // SUCCESS - GREEN
                  // backgroundColor: '#d9fadd',

                  // WARNING - YELLOW
                  // backgroundColor: '#ffd88d',

                  // ERROR - RED
                  backgroundColor: '#ffebee',

                  color: '#151515',
                  padding: '10px 15px',
                }}
                  show={showBanner}
                >
                  You are currently using starter tier account. To access the full features, click <Alert.Link
                    onClick={() => handleLinkClick(link, 'Business')}
                  >here</Alert.Link> to upgrade
                </Alert>
              </div>
            )
          }

          {
            auth && auth.user.tier === 'Starter' && auth.role !== 'Admin' && (
              <div style={{ width: '100%' }}>
                <Alert style={{
                  fontSize: 16,
                  fontWeight: '500',
                  // PRIMARY - BLUE
                  // backgroundColor: '#c9e9ff',

                  // SUCCESS - GREEN
                  // backgroundColor: '#d9fadd',

                  // WARNING - YELLOW
                  // backgroundColor: '#ffd88d',

                  // ERROR - RED
                  backgroundColor: '#ffebee',

                  color: '#151515',
                  padding: '10px 15px',
                }}
                  show={showBanner}
                >
                  You are currently using starter tier account. To access the full features, please contact your administrator.
                </Alert>
              </div>
            )
          }
        </div>
      </div>
      <style>{`
  @media (max-width: 991.98px) {
    .subheader {
      overflow-x: hidden;
    }

    .d-flex.align-items-center.flex-wrap {
      flex-wrap: wrap !important;
      max-width: 100%;
    }

    /* Handle Appcues button */
    #appcues-container,
    [data-appcues] {
      position: static !important;
      max-width: 100% !important;
      margin-bottom: 0.5rem !important;
    }

    #appcues-container .appcues-button,
    [data-appcues] .appcues-button {
      margin: 0 0.5rem 0.5rem 0 !important;
      white-space: nowrap !important;
    }

    /* Make buttons wrap properly */
    .btn {
      white-space: nowrap;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }

    /* Hide button text on very small screens */
    @media (max-width: 480px) {
      .btn .d-none {
        display: none !important;
      }
    }
  }

  /* Container control */
  .${layoutProps.subheaderContainerCssClasses} {
    max-width: 100%;
    overflow-x: hidden;
    padding-left: 15px;
    padding-right: 15px;
  }
`}</style>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, authRedux.actions)(SubHeader);