/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";

export function QuickActions() {
  return (
    <>
      {/* <Link
        to="/Users"
        className="btn btn-info btn-fixed-height font-weight-bold px-2 px-lg-5 mr-3"
      >
        <span className="svg-icon svg-icon-lg svg-icon-white">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
        </span>
        View Users
    </Link> */}
    </>
  );
}
