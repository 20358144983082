import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Report/";

const getHashLinkForReport = async (projectId, userId) => await axiosInstance.get(ENDPOINTORIGIN + 'GetHashLinkForReport?projectId=' + projectId + '&userId=' + userId, constants.REQUIRE_INTERCEPTORS);
const resolveShortToken = async (token) => {
    return await axiosInstance.get(`Report/ResolveShortToken?token=${token}`);
};
export {
    getHashLinkForReport,
    resolveShortToken
}