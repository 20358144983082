export function getErrorField(formik, fieldname) {
    return (formik.touched[fieldname] && formik.errors[fieldname]);
};

export function convertJsonToFormData(json) {
    const form_data = new FormData();
    for (const key in json) {
      if (Array.isArray(json[key])) { json[key].forEach((item) => {
        return form_data.append(key, item);
      }); } else { form_data.append(key, (json[key] === null) ? '' : json[key]); }
    }
  
    return form_data;
};

export function validateDescription(description, customFieldType, customFieldTypeOptions) {
    if (!customFieldType || !description || description.trim().length === 0) return null;
    switch (customFieldType) {
        case 'fractional':
            const fractionalRegex = /^\d+\s*\d*\/\d*$/;
            return !fractionalRegex.test(description) ? "Invalid fractional value." : null;
        case 'decimal':
            const decimalRegex = /^\d*(\.\d+)?$/;
            return !decimalRegex.test(description) ? "Invalid decimal value." : null;
        case 'fractionalWithUnit':
            const fractionalWithUnitRegex = /^\d+\s*\d*\/\d*\s*"?$/;
            return !fractionalWithUnitRegex.test(description) ? "Invalid fractional with unit value." : null;
        case 'textual':
            return null;
        case 'email':
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !emailRegex.test(description) ? "Invalid email address." : null;

        case 'phone':
            const phoneRegex = /^\+?(\d{1,3})?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?$/;
            return !phoneRegex.test(description) ? "Invalid phone number." : null;
        case 'select':
            const validOptions = customFieldTypeOptions.split(',').map(option => option.trim());
            return !validOptions.includes(description.trim()) ? "Invalid selection." : null;
        case 'date':
            // Using the format YYYY-MM-DD for validation
            const dateRegex = /^(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[1,3-9]|1[0-2])-(?:29|30)|(?:0[1,3-9]|1[0-2])-31)$/;
            return !dateRegex.test(description) ? "Invalid date format. Expected YYYY-MM-DD." : null;
        default:
            return null;
    }
};

export function pointToString(point) {
    var { x, y, z } = point;

    return `{ x: ${x.toFixed(3)}, y: ${y.toFixed(3)}, z: ${z.toFixed(3)} }`;
}

export function checkTierPlanAndRoleTier2(tier, role) {
    if (tier === "Enterprise" || tier === "Business" || role === "SuperAdmin") {
        return true;
    }
    else {
        return false;
    }
}

export function checkTierPlanAndRoleFreeTier(tier, role) {
    if (tier === "Individual" || role === "SuperAdmin") {
        return true;
    }
    else {
        return false;
    }
}

export function checkTierPlanAndRoleTier3(tier, role) {
    if (tier === "Enterprise" || role === "SuperAdmin") {
        return true;
    }
    else {
        return false;
    }
}