import * as Yup from "yup";

export default Yup.object().shape({
    id: Yup.number(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phoneNumber: Yup.string().nullable(),
    clientId: Yup.string().nullable(),
    username: Yup.string().required(),
    password: Yup.string().required(),
    roleId: Yup.string().required()
});