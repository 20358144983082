import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectPinComment/";

const getProjectPinCommentById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinCommentById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectPinCommentByProjectPinId = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinCommentByProjectPinId?projPinId=' + id , constants.REQUIRE_INTERCEPTORS);

const getProjectPinComments = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinComments', constants.REQUIRE_INTERCEPTORS);

const saveProjectPinComment = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectPinComment', model, {...constants.REQUIRE_INTERCEPTORS, fileHandler:true});
const addAnonymousComments = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'AddAnonymousComments', model, constants.REQUIRE_INTERCEPTORS);
const deleteProjectPinComment = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProjectPinComment?id=' + id, constants.REQUIRE_INTERCEPTORS);

export {
    getProjectPinCommentById,
    getProjectPinCommentByProjectPinId,
    getProjectPinComments,
    saveProjectPinComment,
    addAnonymousComments,
    deleteProjectPinComment
}