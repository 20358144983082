export const actionTypes = {
    ShowConfirmModal: "SHOW_CONFIRM_MODAL",
    HideConfirmModal: "HIDE_CONFIRM_MODAL",
    SetProcessing: "SET_PROCESSING"
};

const initialConfirmModalState = {
    isVisible: false,
    title: "",
    text: "",
    btnAction: () => console.log("Action not defined"),
    rightBtnText: "",
    isProcessing: false
};

export const reducer = (state = initialConfirmModalState, action) => {

    switch (action.type) {
        case actionTypes.ShowConfirmModal: {
            return {
                isVisible: true,
                title: action.payload.title,
                text: action.payload.text,
                rightBtnText: action.payload.rightBtnText,
                btnAction: action.payload.btnAction,
                isProcessing: false
            };
        }

        case actionTypes.SetProcessing: {
            return {
                ...state,
                isProcessing: true
            };
        }

        case actionTypes.HideConfirmModal: {
            return initialConfirmModalState;
        }

        default:
            return state;
    }
};

export const actions = {
    showConfirmModal: (model) => ({ type: actionTypes.ShowConfirmModal, payload: model }),
    hideConfirmModal: () => ({ type: actionTypes.HideConfirmModal }),
    setProcessing: () => ({ type: actionTypes.SetProcessing})
};
