/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import constants from "../../constants/constants";
import { convertJsonToFormData } from '../../helpers/helper';
import { useFormik } from "formik";
import * as alert from '../../redux/alertSnackBarRedux';
import ProjectPinCommentValidator from '../../helpers/validators/ProjectPinCommentValidator';

import { FilePond, registerPlugin } from "react-filepond";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import * as projectPinService from '../../services/service/projectPinService';
import * as projectPinCommentService from '../../services/settings/projectPinCommentService';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const moment = require('moment');

const initialModel = {
    id: 0,
    comment: '',
    projectPinId: 0,
    projectTypePOIStatusId: 0,
    userId: null,
    poiResolutionImagePath: '',
    matterportResolutionLink: '',
    isDeleted: false,
    commentedOn: moment().format('MM/DD/yyyy hh:mm a'),
    user: null,
    projectPin: {},
    projectTypePOIStatus: null
};

const ResolvePOIStatusModal = (props) => {

    const { show, hide, projectPin, onModalClose, showAlert, auth } = props;
    const poiResolutionImagePathCondition = (projectPin && projectPin.projectPinComments && projectPin.projectPinComments.length) ? projectPin.projectPinComments[0].poiResolutionImagePath
        : projectPin.poiResolutionImagePath;
    const matterportResolutionLinkCondition = (projectPin && projectPin.projectPinComments && projectPin.projectPinComments.length) ? projectPin.projectPinComments[0].matterportResolutionLink
        : projectPin.matterportResolutionLink;
    const projectPinComment = { ...initialModel, projectPin: projectPin, poiResolutionImagePath: poiResolutionImagePathCondition, matterportResolutionLink: matterportResolutionLinkCondition };
    const [poiImage, setPoiImage] = useState([]);
    const [poiResolutionImagePath, setPoiResolutionImagePath] = useState('');
    const [poiMatterPortResolutionLink, setPoiMatterPortResolutionLink] = useState('');
    const [manageOptionVisible, setManageOptionVisible] = useState(true);
    const [poiMatterPortResolutionLinkVisible, setPoiMatterPortResolutionLinkVisible] = useState(false);
    const [poiImageUploaderVisible, setPoiImageUploaderVisible] = useState(false);

    useEffect(() => {
        if (projectPin && show) {

            setPoiResolutionImagePath(poiResolutionImagePathCondition);
            setPoiMatterPortResolutionLink(matterportResolutionLinkCondition);
            formik.setFieldValue("matterportResolutionLink", matterportResolutionLinkCondition);


            if (poiResolutionImagePathCondition && !matterportResolutionLinkCondition) {
                setPoiImageUploaderVisible(true);
                setPoiMatterPortResolutionLinkVisible(false);
                setManageOptionVisible(false);
            }
            if (!poiResolutionImagePathCondition && matterportResolutionLinkCondition) {

                setPoiMatterPortResolutionLinkVisible(true);
                setPoiImageUploaderVisible(false);
                setManageOptionVisible(false);
            }
            if (!poiResolutionImagePathCondition && !matterportResolutionLinkCondition) {
                setPoiMatterPortResolutionLinkVisible(false);
                setPoiImageUploaderVisible(false);
                setManageOptionVisible(true);
            }
        }
    }, [projectPin, show]);

    const handleUploadImage = (e) => {
        setPoiImageUploaderVisible(true);
        setPoiMatterPortResolutionLinkVisible(false);
        formik.setFieldValue("matterportResolutionLink", '');
        setPoiMatterPortResolutionLink('')
        setManageOptionVisible(false);
    }

    const handleMatterPortResolutionLink = (e) => {
        setPoiMatterPortResolutionLinkVisible(true);
        setPoiImageUploaderVisible(false);
        setPoiResolutionImagePath('');
        setPoiImage([]);
        setManageOptionVisible(false);
    }

    const handleAcceptPoiStatus = () => {
        projectPinService.saveProjectPin({ ...projectPin, isApproved: true, isResolved: true })
            .then(async res => {
                onModalClose();
                hideModal();
                showAlert({ message: res.message, variant: 'success' });
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' });
            });
    }

    const handleRejectPoiStatus = () => {
        projectPinService.saveProjectPin({ ...projectPin, isApproved: false, isResolved: false })
            .then(async res => {
                onModalClose();
                hideModal();
                showAlert({ message: res.message, variant: 'success' });
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' });
            });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: projectPinComment,
        validationSchema: ProjectPinCommentValidator,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            addProjectPinComment(values, setSubmitting);
        }
    });

    const addProjectPinComment = (values, setSubmitting) => {
        let finalModel = {
            ...values,
            poiResolutionImageFile: poiImage.length > 0 ? poiImage[0].file : null,
            poiResolutionImagePath: poiResolutionImagePath,
            comment: '',
            projectPinId: projectPin.id,
            projectTypePOIStatusId: projectPin.projectTypePOIStatusId,
            projectPin: projectPin
        };

        projectPinCommentService.saveProjectPinComment(convertJsonToFormData(finalModel))
            .then(async res => {
                onModalClose();
                hideModal();
                showAlert({ message: res.message, variant: 'success' });
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' });
            }).finally(() => {
                setSubmitting(false);
            })
    }

    const hideModal = () => {
        hide();
    }


    const handleOnPressUploadImage = () => {
        handleUploadImage();
    }
    const handleOnPressAddMatterPortResolutionLink = () => {
        handleMatterPortResolutionLink();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Manage
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {
                        poiResolutionImagePath ?
                            <>
                                <div
                                    className="image-input-wrapper background-image-container"
                                    style={{ backgroundImage: `url(${constants.API_URL + 'api/' + poiResolutionImagePath})`, height: '200px' }}
                                />
                                <button
                                    onClick={() => {
                                        setPoiResolutionImagePath('');
                                    }} className="btn btn-info w-100 mt-lg-5 mb-lg-5">Change N-Point Image</button>
                            </> :
                            (poiImageUploaderVisible && !poiMatterPortResolutionLinkVisible && !manageOptionVisible) ?
                                <FilePond

                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    onupdatefiles={setPoiImage}
                                />
                                :
                                <></>
                    }
                    {
                        (!manageOptionVisible && poiMatterPortResolutionLinkVisible && !poiImageUploaderVisible) ?
                            <FormControl className="col-lg-12">
                                <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    rowsMax="4"
                                    margin="normal"
                                    variant="outlined"
                                    className="large-textarea"
                                    {...formik.getFieldProps("matterportResolutionLink")}
                                />
                            </FormControl>
                            :
                            <></>
                    }

                    {(manageOptionVisible && !poiImageUploaderVisible && !poiMatterPortResolutionLinkVisible) ?
                        <Col>
                            <Row>
                                <Button className="btn btn-success 
                    btn-hover-success 
                    btn-block btn-lg"
                                    onClick={handleOnPressUploadImage}
                                >Upload Picture</Button>
                            </Row>
                            <br />
                            <Row>
                                <Button className="btn btn-success 
                    btn-hover-success 
                    btn-block btn-lg"
                                    onClick={handleOnPressAddMatterPortResolutionLink}
                                >Add Link</Button>
                            </Row>
                        </Col>
                        :
                        <></>}

                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex w-100">
                        <div className="d-flex justify-content-start" style={{ width: '74%' }}>
                            {
                                poiMatterPortResolutionLinkVisible ? <Button className="btn-primary" onClick={handleOnPressUploadImage}>Add Image</Button> :
                                    poiImageUploaderVisible ?
                                        <Button className="btn-primary" onClick={handleOnPressAddMatterPortResolutionLink}>Add Link</Button> :
                                        <></>
                            }
                        </div>
                        <div className="d-flex justify-content-end">
                            {
                                (auth.role === 'SuperAdmin' || auth.role === 'Admin' || auth.role === 'Manager') && (poiResolutionImagePath || poiMatterPortResolutionLink) ?
                                    <Button className="btn-danger mr-1" onClick={handleRejectPoiStatus}>Reject</Button>
                                    :
                                    <Button className="btn-light mr-1" onClick={hideModal}>Cancel</Button>
                            }
                            {!manageOptionVisible && (auth.role === 'SuperAdmin' || auth.role === 'Admin' || auth.role === 'Manager') && (poiResolutionImagePath || poiMatterPortResolutionLink) ?
                                <Button className="btn-success" onClick={handleAcceptPoiStatus}>Accept</Button>
                                :
                                <Button className="btn-success" disabled={formik.isSubmitting} type="submit">Submit</Button>}
                        </div>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, alert.actions)(ResolvePOIStatusModal);