import React, { useEffect, useState, createRef, useRef } from 'react';
import { connect } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core';
import '../../../_metronic/_assets/css/Chargify.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import AddPaymentModal from '../../components/AddPaymentModal';
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import * as authRedux from "../../../app/modules/Auth/_redux/authRedux";
import * as clientServices from '../../../services/management/clientService';
import * as clientTierServices from '../../../services/management/clientTierService';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    helperText: {
        color: 'red',
        fontSize: '1.2em'
    }
}));

const ChargifyForm = (props) => {
    const classes = useStyles();
    const { clientId } = useParams();
    const history = useHistory();

    const { auth, showAlert, upgradeTier } = props;
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [disableAddNewCardButton, setDisableAddNewCardButton] = useState(false);
    const [selectedProductName, setSelectedProductName] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [paymentProfiles, setPaymentProfiles] = useState([]);
    const [selectedPaymentProfile, setSelectedPaymentProfile] = useState(null);
    const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setIsPageLoading(true);
        if (auth.role === "SuperAdmin" || auth.role === "Admin") {
            fetchCurrentSubscription();
            fetchProductsFromChargify();
            if (auth && auth.user && auth.user.id) {
                fetchPaymentProfilesForClient();
            }
        }
        setIsPageLoading(false);
    }, [refresh]);

    const fetchPaymentProfilesForClient = () => {
        clientServices.getPaymentProfilesForClient().then(res => {
            setPaymentProfiles(res.data);
        }).catch(err => {
            setDisableAddNewCardButton(true);
            console.log(err);
        })
    }

    const fetchCurrentSubscription = () => {
        clientServices.getSubscriptionById().then(res => {
            if (res.result) {
                setSelectedProductName(res.data.product.name);
            }
        }).catch(err => {
            setDisableAddNewCardButton(true);
            showAlert({ message: err.message, variant: 'error' });
        })
    }

    const handleSelectPaymentProfile = (event) => {
        setSelectedPaymentProfile(event.target.value);
    };

    const fetchProductsFromChargify = () => {
        clientTierServices.getProductsFromChargify().then(res => {
            setProducts(res);
        }).catch(err => {
            setDisableAddNewCardButton(true);
            showAlert({ message: err.message, variant: 'error' });
        })
    }

    const handleProductSelectChange = (event) => {
        setSelectedProductName(event.target.value);
        if (isSubmitted) setIsSubmitted(false);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (selectedProductName && selectedPaymentProfile && selectedProductName !== 'Enterprise') {
            setLoading(true);
            savePaymentProfileTokenAndCreateSubscription();
        }
    }

    const handleModalClose = () => {
        setIsAddCardModalOpen(false);
        setRefresh(!refresh);
    }

    const savePaymentProfileTokenAndCreateSubscription = () => {
        clientServices.savePaymentProfileAndOrSubscribe({ clientTierName: selectedProductName, paymentProfileId: selectedPaymentProfile }).then(res => {
            if (res.result) {
                upgradeTier(selectedProductName);
                history.replace('/dashboard');
                showAlert({ message: res.message, variant: 'success' });
            }
        }).catch(err => {
            showAlert({ message: err.message, variant: 'error' });
        }).finally(() => {
            setIsSubmitted(false);
            setLoading(false);
            setSelectedProductName('');
            setSelectedPaymentProfile(null);
        })
    }

    const handleNewCardModalAllowance = () => {
        if(!disableAddNewCardButton) {
            setIsAddCardModalOpen(true)
        } else {
            showAlert({ message: 'Unable to pull your payment profile information.', variant: 'error' });
        }
    }

    return (
        isPageLoading ? (
            <div>Loading...</div>
        ) : (
            <div className="row project-edit-container">
                <div className="chargify-js-wrapper">
                    <div className="chargify-js-content">
                        <h1>Update Subscription</h1>
                        &nbsp;
                        <h3>To vist our page for detailed information. <a target="_blank" rel="noopener noreferrer" href="https://www.nspect.net/price/">Click here</a>.</h3>
                        <div className='d-flex justify-content-end'>
                            <button onClick={handleNewCardModalAllowance}>
                                Add new card
                            </button>
                        </div>


                        <div class="modal-body">
                            <div className="row">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark">
                                        Choose the tier plan
                                    </span>
                                </h3>
                            </div>
                            <div className="col-lg-12">
                                <ExpansionPanel expanded={isIframeVisible} onChange={() => setIsIframeVisible(!isIframeVisible)}>
                                    <ExpansionPanelSummary>
                                        <h3>Compare Tier plans</h3>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <iframe
                                            title="Pricing Details"
                                            src="https://www.nspect.net/price/"
                                            style={{ width: '100%', height: '800px', border: 'none' }}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            &nbsp;
                            &nbsp;
                            <div className="col-lg-8">
                                <FormControl required className={`col-lg-8 ${classes.formControl}`}>
                                    <InputLabel shrink style={{ fontSize: '18px', marginBottom: '8px' }} id="product-select-label" htmlFor="outlined-age-native-simple">Tier Plan</InputLabel>
                                    &nbsp;
                                    <Select
                                        labelId="product-select-label"
                                        id="product-select"
                                        value={selectedProductName ? selectedProductName : ''}
                                        onChange={handleProductSelectChange}
                                        style={{ marginTop: '8px', fontSize: '18px' }}
                                    >
                                        {products
                                            .map(field => (
                                                <MenuItem key={field.id} value={field.name}>
                                                    {field.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {isSubmitted && !selectedProductName && <FormHelperText className={classes.helperText} error>You must select an option.</FormHelperText>}
                                    {(selectedProductName === 'Enterprise') && <FormHelperText className={classes.helperText}>Please schedule a call with us to buy this payment plan. <a target="_blank" rel="noopener noreferrer" href="https://info.nspect.net/meetings/brandonforeman/nspect-demo"><b>Click here</b></a> to continue.</FormHelperText>}
                                </FormControl>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark">
                                        Choose the payment method
                                    </span>
                                </h3>
                            </div>
                            <div className="col-lg-8">
                                <FormControl className={`col-lg-8 ${classes.formControl}`} disabled={!paymentProfiles.length}>
                                    <InputLabel id="payment-profile-select-label" shrink style={{ fontSize: '18px', marginBottom: '8px' }}>Payment Profile</InputLabel>
                                    &nbsp;

                                    <Select
                                        labelId="payment-profile-select-label"
                                        id="payment-profile-select"
                                        value={selectedPaymentProfile}
                                        onChange={handleSelectPaymentProfile}
                                        style={{ marginTop: '8px', fontSize: '18px' }}
                                    >
                                        {paymentProfiles.length > 0 ?
                                            paymentProfiles.map((profile, index) => (
                                                <MenuItem key={index} value={profile.id}>
                                                    {`Card ending in ${profile.masked_card_number}`}
                                                </MenuItem>
                                            ))
                                            : <MenuItem value="" disabled>No payment profiles available</MenuItem>
                                        }
                                    </Select>
                                    {isSubmitted && !selectedPaymentProfile && <FormHelperText error className={classes.helperText}>You must select a payment profile.</FormHelperText>}
                                </FormControl>
                                {!paymentProfiles.length && <FormHelperText className={classes.helperText}>Please add a payment profile to proceed.</FormHelperText>}
                            </div>
                        </div>
                        <AddPaymentModal
                            open={isAddCardModalOpen}
                            handleClose={handleModalClose}
                            clientId={clientId}
                        />
                        <div className='d-flex justify-content-end'>
                            <button className='btn-primary' onClick={() => {
                                history.goBack();
                            }}>
                                Cancel
                            </button>
                            &nbsp;
                            &nbsp;
                            <button onClick={handleFormSubmit} disabled={loading}>
                                Subscribe
                                {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                            </button>
                        </div>


                    </div>

                </div>
            </div>
        )
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions, ...authRedux.actions })(ChargifyForm);