/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import constants from "../../constants/constants";
import CreateProjectValidator from "../../helpers/validators/CreateProjectValidator";
import { useFormik } from "formik";
import { FilePond } from "react-filepond";
import { getErrorField, convertJsonToFormData } from '../../helpers/helper';
import * as projectService from '../../services/service/projectService';
import * as projectTypeService from '../../services/service/projectTypeService';
import * as clientService from '../../services/management/clientService';
import * as alert from '../../redux/alertSnackBarRedux';
import * as confirmModal from "../../redux/confirmModalRedux";
import moment from 'moment';

const initialState = {
    id: 0,
    projectName: '',
    clientId: '',
    scanDate: '',
    modelLink: '',
    projectTypeId: '',
    notes: '',
    isDeleted: false,
    filesToSave: []
}
const NewProjectModal = (props) => {
    const filePondRef = useRef(null); // Initialize as null
    const { show, hide, reloadTable, showAlert, showConfirmModal, projectToEditId, hideConfirmModal, setProcessing } = props;
    const [project, setProject] = useState(initialState);
    const [clientList, setClientList] = useState([]);
    const [clientId, setClientId] = useState(0);
    const [projectImages, setProjectImages] = useState([]);
    const [filePondFiles, setFilePondFiles] = useState([]);
    const [filePondInstance, setFilePondInstance] = useState(null);
    const [isProjectSubmitting, setIsProjectSubmitting] = useState(false);
    const [projectTypeList, setProjectTypeList] = useState([]);
    const [formFieldValues, setFormFieldValues] = useState({});
    const [nspectionTypeSelected, setNspectionTypeSelected] = useState(null);
    const [formJson, setFormJson] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleValueChange = (val, key) => {
        setFormFieldValues((prevValues) => ({
            ...formFieldValues,
            [key]: val,
        }));
    };

    useEffect(() => {
        if (projectToEditId) {
            projectService.getProjectById(projectToEditId).then(res => {
                const model = { ...res, scanDate: moment(res.scanDate).format('yyyy-MM-DD') };
                setProject(model);

                if (model.projectType) {
                    setNspectionTypeSelected(model.projectType.name);
                    if (model.formJSON) {
                        setFormJson(JSON.parse(model.formJSON));
                    }
                }

                // Populate FilePond with existing images
                const existingFiles = model.projectModelImages.map(image => ({
                    source: `${constants.API_URL}api/${image.imagePath}`,  // Make sure to correct this URL if it is different
                    options: {
                        type: 'local',
                        file: {
                            name: image.imagePath.split('/').pop(), // Extract filename from path
                            size: 0 // Size is unknown
                        }
                    }
                }));
                setFilePondFiles(existingFiles);
            }).catch(err => {
                showAlert({ message: err.message, variant: 'error' });
            });
        }
        clientService.getClients().then(res => {
            setClientList(res);
        });
        if (clientId) {
            projectTypeService.getProjectTypes(clientId).then(res => {
                setProjectTypeList(res);
            });
        } else {
            projectTypeService.getAllProjectTypes().then(res => {
                setProjectTypeList(res);
            })
        }

    }, [show, clientId, projectToEditId]);

    // Function to determine initial values based on operation
    const getInitialValues = () => {
        if (projectToEditId) {
            // Editing an existing project, return the project values
            return {
                id: project.id,
                projectName: project.projectName,
                clientId: project.clientId,
                scanDate: moment(project.scanDate).format('yyyy-MM-DD'),
                modelLink: project.modelLink,
                projectTypeId: project.projectTypeId,
                notes: project.notes,
                isDeleted: project.isDeleted,
                filesToSave: [],
            };
        } else {
            return initialState;
        }
    };


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(),
        validationSchema: CreateProjectValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveProject(values, setSubmitting)
        }
    });

    const handleFileUpload = (fileItems) => {
        setProjectImages(fileItems.map((fileItem) => (fileItem.file)));
    };

    const saveProject = (values, setSubmitting) => {
        setProcessing(true);
        setLoading(true);
        setIsProjectSubmitting(true);
        if (!values.modelLink && !projectImages.length > 0) {
            window.alert('Please upload images for the project or enter your model link to proceed.')
        }
        else {
            var valueToPost = values;

            if (nspectionTypeSelected && constants[nspectionTypeSelected]) {
                valueToPost = { ...valueToPost, formJson: JSON.stringify(fillJsonFormValue()) }
            }

            var model = convertJsonToFormData(valueToPost);
            projectImages.forEach(file => {
                model.append("filesToSave", file);
            });
            projectService.saveProject(model).then(res => {
                showAlert({ message: res.message, variant: 'success' });
                reloadTable();
                hideModal();
            }).catch(ex => {
                showAlert({ message: ex.message, variant: 'error' });
            }).finally(() => {
                setSubmitting(false);
                setProcessing(false);
                hideConfirmModal();
                setLoading(false);
            })
        }
    }

    const fillJsonFormValue = () => {
        const tempFormJson = formJson;
        const objectKeys = Object.keys(formFieldValues);

        for (let i = 0; i < objectKeys.length; i += 1) {
            for (let j = 0; j < tempFormJson.length; j += 1) {
                const currentKey = objectKeys[i];

                if (tempFormJson[j].key === currentKey) {
                    tempFormJson[j].value = formFieldValues[currentKey];
                    break;
                }
            }
        }

        return tempFormJson;
    }

    const hideModal = () => {
        // Clear Formik form
        formik.resetForm();
        setProject(initialState);

        // Clear FilePond files
        setProjectImages([]);
        setFilePondFiles([]);

        hide();

        // Debug logging
        console.log("FilePond files cleared");

        // Create a new instance of FilePond and replace the current instance
        if (filePondInstance) {
            const newFilePondInstance = FilePond.create(); // Create a new FilePond instance
            setFilePondInstance(newFilePondInstance);
        }

        setFormJson(null);
        setNspectionTypeSelected(null);
        setFormFieldValues({});
    };

    const renderFormField = (field) => {
        let {
            type,
            key,
            label,
            value,
        } = field;
        switch (type) {
            case 'text':
                return (
                    <div key={key} className="col-lg-6">
                        <TextField
                            id="outlined-name"
                            label={label}
                            margin="normal"
                            variant="outlined"
                            value={(formFieldValues[key] || formFieldValues[key] === '') ? formFieldValues[key] : value}
                            onChange={({ target }) => {
                                handleValueChange(target.value, key);
                            }}
                        />
                    </div>
                );
            case 'date':
                return (
                    <div key={key} className="col-lg-6">
                        <TextField
                            type="date"
                            id="outlined-name"
                            label={label}
                            margin="normal"
                            variant="outlined"
                            placeholder=' '
                            value={(formFieldValues[key] || formFieldValues[key] === '') ? formFieldValues[key] : value}
                            onChange={({ target }) => handleValueChange(target.value, key)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                );
            default:
                return <p>Loading...</p>;
        }
    };


    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {projectToEditId > 0 ? "Update" : "Create New"} Nspection
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                error={getErrorField(formik, "projectName")}
                                id="outlined-name"
                                label="Nspection Name"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("projectName")}
                            />

                            {formik.touched.projectName && formik.errors.projectName ? (
                                <div className="text-danger">
                                    {formik.errors.projectName}
                                </div>
                            ) : null}

                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                                <InputLabel htmlFor="outlined-age-native-simple">Client</InputLabel>
                                <Select
                                    error={getErrorField(formik, "clientId")}
                                    native
                                    label="Client"
                                    disabled={projectToEditId}
                                    value={formik.values.clientId}
                                    onChange={e => {
                                        if (e.target.value === "") {
                                            formik.setFieldValue("clientId", "");
                                            setClientId(0);
                                        }
                                        else {
                                            formik.setFieldValue("clientId", Number(e.target.value));
                                            setClientId(Number(e.target.value));
                                        }
                                    }}
                                    inputProps={{
                                        name: 'Client',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {
                                        clientList.map(x => <option key={x.id} value={x.id}>{x.businessName}</option>)
                                    }
                                </Select>
                                {formik.touched.clientId && formik.errors.clientId ? (
                                    <div className="text-danger">
                                        {formik.errors.clientId}
                                    </div>
                                ) : null}
                            </FormControl>

                            <TextField
                                error={getErrorField(formik, "scanDate")}
                                id="outlined-name"
                                type="date"
                                placeholder="Scan Date"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("scanDate")}
                            />

                            {formik.touched.scanDate && formik.errors.scanDate ? (
                                <div className="text-danger">
                                    {formik.errors.scanDate}
                                </div>
                            ) : null}

                        </div>
                        <div className="col-lg-6">
                            <TextField
                                id="outlined-multiline-flexible"
                                className="notes-textarea"
                                label="Notes"
                                multiline
                                rowsMax="4"
                                margin="normal"
                                variant="outlined"

                                {...formik.getFieldProps("notes")}
                            />
                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                                <InputLabel htmlFor="outlined-age-native-simple">Nspection Type</InputLabel>
                                <Select
                                    error={getErrorField(formik, "projectTypeId")}
                                    native
                                    label="Nspection Type"
                                    disabled={projectToEditId}
                                    value={formik.values.projectTypeId}
                                    onChange={e => {
                                        if (e.target.value === "") {
                                            formik.setFieldValue("projectTypeId", "");
                                        }
                                        else {
                                            const selectedNspection = projectTypeList.find((x) => x.id == e.target.value);
                                            if (selectedNspection) {
                                                setNspectionTypeSelected(selectedNspection.name);
                                                if (project.formJSON) {
                                                    setFormJson(constants[selectedNspection.name].fields);
                                                }
                                            }

                                            formik.setFieldValue("projectTypeId", Number(e.target.value));
                                        }
                                    }}
                                    inputProps={{
                                        name: 'ProjectType',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {
                                        projectTypeList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                    }
                                </Select>
                                {formik.touched.projectTypeId && formik.errors.projectTypeId ? (
                                    <div className="text-danger">
                                        {formik.errors.projectTypeId}
                                    </div>
                                ) : null}
                            </FormControl>
                        </div>

                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "modelLink")}
                                id="outlined-name"
                                label="3D Model Link"
                                margin="normal"
                                disabled={projectToEditId}
                                variant="outlined"
                                {...formik.getFieldProps("modelLink")}
                            />

                            {formik.touched.modelLink && formik.errors.modelLink ? (
                                <div className="text-danger">
                                    {formik.errors.modelLink}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-lg-12">
                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                                <InputLabel htmlFor="standard-age-native-simple">2D Inspection</InputLabel>
                                <FilePond
                                    ref={filePondRef}
                                    acceptedFileTypes={['image/*']}
                                    files={filePondFiles}
                                    allowMultiple={true}
                                    disabled={projectToEditId}
                                    onupdatefiles={handleFileUpload}
                                    maxFiles={3}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    oninit={(instance) => {
                                        setFilePondInstance(instance);
                                    }}
                                />
                                {projectToEditId && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                                            zIndex: 1,
                                        }}
                                    ></div>
                                )}
                            </FormControl>
                        </div>

                    </div>
                    <div className="row">
                        {
                            nspectionTypeSelected && formJson && (
                                <>
                                    <div className='col-lg-12'>
                                        <h4>{constants[nspectionTypeSelected].title}</h4>
                                    </div>
                                    {
                                        formJson.map((x) => (
                                            renderFormField(x)
                                        ))
                                    }
                                </>
                            )
                        }
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideModal}>Cancel</Button>
                    {
                        !projectToEditId ?
                            (
                                <Button className="btn-success"
                                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid) || (!projectImages.length > 0 && !formik.values.modelLink)}
                                    type="button"
                                    onClick={() => {
                                        showConfirmModal({
                                            title: 'Create Nspection',
                                            text: 'Please revise all of your information before creating the Nspection. Are you sure you want to proceed?',
                                            rightBtnText: 'Confirm',
                                            btnAction: () => saveProject(formik.values, formik.setSubmitting)
                                        })
                                    }}
                                >Create Nspection
                                    {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                                </Button>
                            ) :
                            (
                                <Button className="btn-success"
                                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid) || (!projectImages.length > 0 && !formik.values.modelLink)}
                                    type="submit"
                                >Update Nspection
                                    {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}</Button>
                            )
                    }
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(NewProjectModal);