import React from 'react';
import {
  Text,
  View,
  Image,
  StyleSheet,
  Font
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 16,
    width: 440,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  textHack: {
    position: 'absolute',
    opacity: 0.5,
    textAlign: 'center',
    fontSize: 16,
    width: 440,
  },
  image: {
    width: 55,
    height: 55,
    marginTop: 7
  },
  logoColumn: {
    width: 50
  }
});

const PropertyImprovementPlanReportHeader = () => {
  return (
    <View style={styles.container}>
      <View style={styles.detailColumn}>
        <Image
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Hyatt_Place_logo.svg/962px-Hyatt_Place_logo.svg.png"
          style={styles.image}
        />
      </View>
      <View style={styles.text}>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
        <Text style={styles.textHack}>Hyatt Place/Hyatt House Property Improvement Plan</Text>
      </View>
      <View style={styles.detailColumn}>
        <Image
          src={process.env.PUBLIC_URL + '/media/logos/Hyatt-House.png'}
          style={{ width: 80, height: 75 }}
        />
      </View>
    </View>
  )
}


export default PropertyImprovementPlanReportHeader;