import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectTypeCustomField/";

const getProjectTypeCustomFieldById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeCustomFieldById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectTypeCustomFieldByProjectTypeId = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeCustomFieldByProjectTypeId?projTypeId=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectTypeCustomFields = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeCustomFields', constants.REQUIRE_INTERCEPTORS);

const saveProjectTypeCustomField = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectTypeCustomField', model, constants.REQUIRE_INTERCEPTORS);

const deleteProjectTypeCustomField = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProjectTypeCustomField?id=' + id, constants.REQUIRE_INTERCEPTORS);

const deleteProjectTypeCustomFieldIsDeletedFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteProjectTypeCustomFieldIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS);

export {
    getProjectTypeCustomFieldById,
    getProjectTypeCustomFieldByProjectTypeId,
    getProjectTypeCustomFields,
    saveProjectTypeCustomField,
    deleteProjectTypeCustomField,
    deleteProjectTypeCustomFieldIsDeletedFlag
}