import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectPin/";

const getProjectPinById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectPinsByProjectId = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinsByProjectId?projectId=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectPinsByProjectIdForReport = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinsByProjectIdForReport?projectId=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectPinsByProjectIdAndImageId = async (id, imageId) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinsByProjectIdAndImageId?projectId=' + id + '&imageId=' + imageId, constants.REQUIRE_INTERCEPTORS);
const getProjectPinsByProjectIdAndImageIdForShareable = async (token) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinsByProjectIdAndImageIdForShareable?token=' + token, constants.REQUIRE_INTERCEPTORS);

const getProjectPinsByProjectIdForSharable = async (token) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinsByProjectIdForSharable?token=' + token, constants.REQUIRE_INTERCEPTORS);

const getProjectPinsByProjectIdForReportSharable = async (token) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinsByProjectIdForReportSharable?token=' + token, constants.REQUIRE_INTERCEPTORS);

const getProjectPins = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPins', constants.REQUIRE_INTERCEPTORS);

const saveProjectPin = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectPin', model, { ...constants.REQUIRE_INTERCEPTORS });

const saveProjectPinAnonymous = async (formData, data) => {
  try {
    formData.append('projectPinCheckBoxes', JSON.stringify(data.projectPinCheckBoxes));
    formData.append('projectPinCustomFields', JSON.stringify(data.projectPinCustomFields));

    const response = await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectPinAnonymous', formData, {
      ...constants.REQUIRE_INTERCEPTORS,
      fileHandler: true,
    });
    console.log('RESPONSE', response)
    if (response) {
      return response;
    }
  } catch (error) {
    console.log('Error when posting anonymous Project Pin:', error);
  }
};

const updateProjectPin = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'UpdateProjectPin', model, { ...constants.REQUIRE_INTERCEPTORS, fileHandler: true });

const deleteProjectPin = async (id, isDeleted) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProjectPin?id=' + id + '&isDeleted=' + isDeleted, constants.REQUIRE_INTERCEPTORS);

export {
  getProjectPinById,
  getProjectPinsByProjectIdForSharable,
  getProjectPinsByProjectIdForReportSharable,
  getProjectPins,
  saveProjectPin,
  deleteProjectPin,
  updateProjectPin,
  getProjectPinsByProjectIdForReport,
  getProjectPinsByProjectIdAndImageId,
  saveProjectPinAnonymous,
  getProjectPinsByProjectIdAndImageIdForShareable,
  getProjectPinsByProjectId
}