import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectTypeMetaDataField/";

const getProjectTypeMetaDataFieldById = async (id) => 
    await axiosInstance.get(ENDPOINTORIGIN + 'GetById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectTypeMetaDataFieldByProjectTypeId = async (id) => 
    await axiosInstance.get(ENDPOINTORIGIN + 'GetByProjectTypeId?projectTypeId=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectTypeMetaDataFields = async () => 
    await axiosInstance.get(ENDPOINTORIGIN + 'GetAll', constants.REQUIRE_INTERCEPTORS);

const saveProjectTypeMetaDataField = async (model) => 
    await axiosInstance.post(ENDPOINTORIGIN + 'Save', model, constants.REQUIRE_INTERCEPTORS);

const softDeleteProjectTypeMetaDataField = async (model) => 
    await axiosInstance.post(ENDPOINTORIGIN + 'SoftDelete', model, constants.REQUIRE_INTERCEPTORS);

export {
    getProjectTypeMetaDataFieldById,
    getProjectTypeMetaDataFieldByProjectTypeId,
    getProjectTypeMetaDataFields,
    saveProjectTypeMetaDataField,
    softDeleteProjectTypeMetaDataField
}