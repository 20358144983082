import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GenerateNewReportPage from '../services/project/GenerateNewReportPage';
import * as reportService from '../../../services/settings/reportService';

const ProjectReportPage = (props) => {
    const useQueryString = () => new URLSearchParams(useLocation().search);
    const shortToken = useQueryString().get('token');

    const [resolvedToken, setResolvedToken] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (shortToken) {
            reportService.resolveShortToken(shortToken)
                .then(response => {
                    const originalToken = response.data.originalToken;

                    // Store the resolved token in 'reportToken' instead of 'authToken'
                    localStorage.setItem('reportToken', originalToken);
                    setResolvedToken(originalToken); // Set resolved token (real JWT)
                })
                .catch(err => {
                    console.error("Error resolving token", err);
                })
                .finally(() => {
                    setLoading(false); // Set loading to false after resolving token
                });
        } else {
            setLoading(false); // No token found, stop loading
        }
    }, [shortToken]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-fluid">
            {resolvedToken ? (
                <GenerateNewReportPage isReadOnly={true} token={resolvedToken} />
            ) : (
                <div>Error: No valid token found.</div>
            )}
        </div>
    );
}

export default ProjectReportPage;
