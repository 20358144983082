import React, { useEffect, useState, createRef, useRef } from 'react';
import { connect } from "react-redux";
import { Modal, Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import '../../_metronic/_assets/css/Chargify.css';
import * as alert from "../../redux/alertSnackBarRedux";
import * as confirmModal from "../../redux/confirmModalRedux";
import * as authRedux from "../../app/modules/Auth/_redux/authRedux";
import * as settingService from '../../services/settings/settingService';
import * as clientServices from '../../services/management/clientService';
import * as clientTierServices from '../../services/management/clientTierService';

const AddPaymentModal = (props) => {
    const { open, handleClose, clientId, showAlert } = props;
    const form = createRef();
    var chargify = useRef(new window.Chargify());
    const [securityToken, setSecurityToken] = useState(null);
    const [clientInfo, setClientInfo] = useState({});
    const [publicKey, setPublicKey] = useState('');
    const [serverHost, setServerHost] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchServerHost();
        fetchChargifyPublicKey();
        if (clientId) {
            fetchClientInfoById(clientId);
        }
        generateSecurityToken();
    }, []);

    useEffect(() => {
        if (open && securityToken) {
            chargify.current.load({
                publicKey: publicKey,
                securityToken: securityToken,
                type: 'card',
                serverHost: serverHost,
                hideCardImage: false,
                optionalLabel: '(optional field)',
                requiredLabel: '*',
                addressDropdowns: true,
                fields: {
                    firstName: {
                        selector: '#chargify1',
                        label: 'First Name on Account',
                        required: false,
                        value: clientInfo.contactName.split(' ')[0] ? clientInfo.contactName.split(' ')[0] : '',
                        message: 'First name is not valid. Please update it.',
                        maxlength: '30',
                    },
                    lastName: {
                        selector: '#chargify2',
                        label: 'Last Name on Account',
                        required: false,
                        value: clientInfo.contactName.split(' ')[1] ? clientInfo.contactName.split(' ')[1] : '',
                        message: 'This field is not valid. Please update it.',
                        maxlength: '30',
                    },
                    number: {
                        selector: '#chargify3',
                        label: 'Number',
                        placeholder: 'xxxx xxxx xxxx xxxx',
                        message: 'This field is not valid. Please update it.',
                    },
                    month: {
                        selector: '#chargify4',
                        label: 'Mon',
                        placeholder: 'mm',
                        message: 'This field is not valid. Please update it.',
                    },
                    year: {
                        selector: '#chargify5',
                        label: 'Year',
                        placeholder: 'yyyy',
                        message: 'This field is not valid. Please update it.',
                    },
                    cvv: {
                        selector: '#chargify6',
                        label: 'CVV code',
                        placeholder: '123',
                        required: false,
                        message: 'This field is not valid. Please update it.',
                    },
                    address: {
                        selector: '#chargify7',
                        label: 'Address',
                        placeholder: '1234 Hill St',
                        required: false,
                        message: 'This field is not valid. Please update it.',
                        maxlength: '70',
                    },
                    address2: {
                        selector: '#chargify8',
                        label: 'Address 2',
                        placeholder: '1234 Hill St',
                        required: false,
                        message: 'This field is not valid. Please update it.',
                        maxlength: '70',
                    },
                    city: {
                        selector: '#chargify9',
                        label: 'City',
                        placeholder: 'Austin',
                        required: false,
                        message: 'This field is not valid. Please update it.',
                        maxlength: '30',
                    },
                    country: {
                        selector: '#chargify10',
                        label: 'Country',
                        placeholder: 'Select...',
                        required: false,
                        message: 'This field is not valid. Please update it.',
                        maxlength: '2',
                    },
                    state: {
                        selector: '#chargify11',
                        label: 'State',
                        placeholder: 'Select...',
                        required: false,
                        message: 'This field is not valid. Please update it.',
                        maxlength: '2',
                    },
                    zip: {
                        selector: '#chargify12',
                        label: 'Zip Code',
                        placeholder: '10001',
                        required: false,
                        message: 'This field is not valid. Please update it.',
                        maxlength: '5',
                    },
                },
            });
        }

    }, [open, securityToken]);

    const fetchChargifyPublicKey = () => {
        settingService.getSettingByKey("ChargifyPublicKey").then(res => {
            setPublicKey(res);
        }).catch(err => {
            console.log("unable to fetch public key for chargify", err);
        })
    }

    const fetchServerHost = () => {
        settingService.getSettingByKey("ChargifyURL").then(res => {
            setServerHost(res);
        }).catch(err => {
            console.log("unable to fetch server host for chargify", err);
        })
    }

    const fetchClientInfoById = (id) => {
        clientServices.getClientById(id).then(res => {
            setClientInfo(res);
        }).catch(err => {
            console.log(err);
        })
    }

    const generateSecurityToken = () => {
        clientServices.generateSecurityToken().then(res => {
            setSecurityToken(res);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        chargify.current.token(
            form.current,
            function success(token) {
                setLoading(true);
                savePaymentProfileToken(token);
                form.submit();
            },
            function error(err) {
                showAlert({ message: err.message, variant: 'error' });
                console.log('token ERROR - err: ', err);
            }
        );
    }

    const savePaymentProfileToken = (token) => {
        if (token) {
            clientServices.savePaymentProfile({ paymentToken: token }).then(res => {
                handleClose();
                showAlert({ message: res.message, variant: 'success' });
            }).catch(err => {
                showAlert({ message: err, variant: 'error' });
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            show={open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add new Payment Profile
                </Modal.Title>
            </Modal.Header>
            <form className="host-form" id="host-form" ref={form} onSubmit={handleFormSubmit}>
                <Modal.Body>
                    <div className="row" style={{ alignContent: 'center' }}>
                        <div className="cardfront">
                            <div id="chargify1"></div>
                            <div id="chargify2"></div>
                        </div>

                        <div className="cardback">
                            <div id="chargify3"></div>
                            <div id="chargify6"></div>
                        </div>

                        <div className="name">
                            <div id="chargify4"></div>
                            <div id="chargify5"></div>
                        </div>

                        <div className="address1">
                            <div id="chargify7"></div>
                        </div>

                        <div className="address2">
                            <div id="chargify8"></div>
                        </div>

                        <div className="address3">
                            <div id="chargify9"></div>
                            <div id="chargify10"></div>
                        </div>

                        <div className="address4">
                            <div id="chargify11"></div>
                            <div id="chargify12"></div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-light" onClick={handleClose}>Cancel</Button>
                    <button type="submit" disabled={loading}>
                        Save Card
                        {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                    </button>
                </Modal.Footer>

                <input id="chargify-token" type="hidden" />


            </form>
        </Modal >
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions, ...authRedux.actions })(AddPaymentModal);
