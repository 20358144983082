import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Setting/";

const getSettingById = async (id) =>
  await axiosInstance.get(`${ENDPOINTORIGIN}GetSettingById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getSettingByKey = async (key) =>
  await axiosInstance.get(`${ENDPOINTORIGIN}GetSettingByKey?key=${key}`, constants.REQUIRE_INTERCEPTORS);

const getSettings = async () =>
  await axiosInstance.get(`${ENDPOINTORIGIN}GetSettings`, constants.REQUIRE_INTERCEPTORS);

const getSettingsForClient = async (clientId) =>
  await axiosInstance.get(`${ENDPOINTORIGIN}GetSettingsForClient?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const getSettingByKeyForClient = async (key, clientId) =>
  await axiosInstance.get(`${ENDPOINTORIGIN}GetSettingByKeyForClient?key=${key}&clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

// Function for creating a new setting
const saveSetting = async (model) => {
  return await axiosInstance.post(
    `${ENDPOINTORIGIN}SaveSetting`,
    model,
    constants.REQUIRE_INTERCEPTORS
  );
};

// Function for updating an existing setting
const updateSetting = async (model) => {
  return await axiosInstance.put(
    `${ENDPOINTORIGIN}UpdateSetting`,
    model,
    constants.REQUIRE_INTERCEPTORS
  );
};

// Function for deleting a setting
const deleteSetting = async (id) =>
  await axiosInstance.delete(`${ENDPOINTORIGIN}DeleteSetting?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getSettingById,
  getSettingByKey,
  getSettings,
  getSettingsForClient,
  getSettingByKeyForClient,
  saveSetting,
  updateSetting,
  deleteSetting
};
