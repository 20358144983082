/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Field, useFormik } from "formik";
// import { TextField } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import UpdateImagesValidator from "../../helpers/validators/UpdateImagesValidator";
import { FilePond } from "react-filepond";
import { getErrorField, convertJsonToFormData } from '../../helpers/helper';
import * as projectService from '../../services/service/projectService';
import * as alert from '../../redux/alertSnackBarRedux';
import * as confirmModal from "../../redux/confirmModalRedux";



const UploadImagesModal = (props) => {
    const { show, hide, showAlert, showConfirmModal, hideConfirmModal, project, refresh, setProcessing } = props;
    const [projectImages, setProjectImages] = useState([]);
    const [hideButtons, setHideButtons] = useState(false);
    const [isProject3dUploader, setIsProject3dUploader] = useState(false);
    const [imageUploaderOutput, setImageUploaderOutput] = useState(null);
    const [project3DLinkOutput, setProject3DLinkOutput] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            modelLink: project.modelLink || ''
        },
        validationSchema: UpdateImagesValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (isProject3dUploader) {
                showConfirmModal({
                    title: 'Replace 3D Link',
                    text: 'Are you sure you want to replace your current 3D link? You will loose all the Nspection pins from your current 3D model if you decide to change your model link.',
                    rightBtnText: 'Confirm',
                    btnAction: () => updateProject3dModel(values, setSubmitting)
                })
            }
            else {
                updateProjectImages(values, setSubmitting)
            }
        }
    });

    const handleFileUpload = (fileItems) => {
        setProjectImages(fileItems.map((fileItem) => (fileItem.file)));
    };

    const imageUploader = () => {
        setHideButtons(true);
        setImageUploaderOutput(<div className="row">
            <div className="col-lg-12">
                <FormControl variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                    <InputLabel htmlFor="standard-age-native-simple">2D Nspection</InputLabel>
                    <FilePond
                        allowMultiple={true}
                        onupdatefiles={handleFileUpload}
                        maxFiles={3}
                    />
                </FormControl>
            </div>

        </div>
        )
    }

    const linkUploader = () => {
        setHideButtons(true);
        setIsProject3dUploader(true);
        const newModelLink = project.modelLink;
        formik.setFieldValue("modelLink", newModelLink);
        // setProject3DLinkOutput(
        //     <div className="col-lg-12">
        //         <TextField
        //             error={getErrorField(formik, "modelLink")}
        //             id="outlined-name"
        //             margin="normal"
        //             label="Model Link"
        //             variant="outlined"
        //             name='modelLink'
        //             value={formik.values.modelLink}
        //           onChange={formik.handleChange}
        //         />
        //         {formik.touched.modelLink && formik.errors.modelLink ? (
        //             <div className="text-danger">
        //                 {formik.errors.modelLink}
        //             </div>
        //         ) : null}
        //     </div>
        // )
    }

    const updateProjectImages = (values, setSubmitting) => {
        setIsSubmitting(true);
        var model = convertJsonToFormData({ ...project, ...values });
        projectImages.forEach(file => {
            model.append("filesToSave", file);
        });
        projectService.updateImages(model).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            hideModal();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' });
        }).finally(() => {
            setSubmitting(false);
            setIsSubmitting(false);
            hideConfirmModal();
        })
    }

    const updateProject3dModel = (values, setSubmitting) => {
        setIsSubmitting(true);
        projectService.udpate3dLink({ ...project, ...values }).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            hideModal();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' });
        }).finally(() => {
            setSubmitting(false);
            setIsSubmitting(false);
            hideConfirmModal();
        })
    }

    const hideModal = () => {
        setProjectImages([]);
        formik.resetForm();
        setHideButtons(false);
        setIsProject3dUploader(false);
        setImageUploaderOutput(null);
        setProject3DLinkOutput(null);
        hide();
        refresh();
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Choose Nspection Model to Update
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {
                        !hideButtons &&
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Button className="btn-success" type="button" onClick={imageUploader}>Upload images</Button>
                            <br />
                            <Button className="btn-success" type="button" onClick={linkUploader}>Replace 3D Link</Button>
                        </div>
                    }

                    {hideButtons && imageUploaderOutput && !project3DLinkOutput && imageUploaderOutput}

                    {hideButtons && !imageUploaderOutput &&
                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "modelLink")}
                                id="outlined-name"
                                margin="normal"
                                label="Model Link"
                                variant="outlined"
                                name='modelLink'
                                value={formik.values.modelLink}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.modelLink ? (
                                <div className="text-danger">
                                    {formik.errors.modelLink}
                                </div>
                            ) : null}
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideModal}>Cancel</Button>
                    <Button className="btn-success"
                        disabled={isSubmitting || (formik.touched && !formik.isValid) || !hideButtons}
                        type="submit"
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                </Modal.Footer>
            </form>

        </Modal>
    );
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(UploadImagesModal);