import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Project/";

const getProjectById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectByClientId = async (userId) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectByClientId?userId=' + userId, constants.REQUIRE_INTERCEPTORS);

const getProjectByIdForSharable = async (token) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectByIdForSharable?token=' + token, constants.REQUIRE_INTERCEPTORS);

const getProjectIdForAnonymousUser = async (token) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectIdForAnonymousUser?token=' + token, constants.REQUIRE_INTERCEPTORS);

const getProjects = async (isCompleted) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjects?isCompleted=' + isCompleted, constants.REQUIRE_INTERCEPTORS);

const getArchivedProjects = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetArchivedProjects', constants.REQUIRE_INTERCEPTORS);

const saveProject = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProject', model, { ...constants.REQUIRE_INTERCEPTORS, fileHandler: true });

const saveProjectMetaDataAnswers = async (projectId, answers) =>
    await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectMetaDataAnswers', { projectId, answers }, constants.REQUIRE_INTERCEPTORS);

const udpate3dLink = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'Update3dLink', model, { ...constants.REQUIRE_INTERCEPTORS });

const updateImages = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'Update2dImage', model, { ...constants.REQUIRE_INTERCEPTORS, fileHandler: true });

const deleteProject = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProject?id=' + id, constants.REQUIRE_INTERCEPTORS);

const completeProject = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'completeProject?id=' + id, constants.REQUIRE_INTERCEPTORS);

const deleteProjectByIsDeleteFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteProjectByIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS)

const getProjectDocuments = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectDocuments?id=' + id, constants.REQUIRE_INTERCEPTORS);

const uploadProjectDocument = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'UploadProjectDocument', model, { ...constants.REQUIRE_INTERCEPTORS, fileHandler: true });

const deleteProjectDocument = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProjectDocument?id=' + id, constants.REQUIRE_INTERCEPTORS);

export {
    getProjectById,
    getProjectByClientId,
    getProjectByIdForSharable,
    getProjectIdForAnonymousUser,
    getProjects,
    saveProject,
    saveProjectMetaDataAnswers,
    udpate3dLink,
    updateImages,
    deleteProject,
    completeProject,
    getArchivedProjects,
    deleteProjectByIsDeleteFlag,
    getProjectDocuments,
    uploadProjectDocument,
    deleteProjectDocument
}