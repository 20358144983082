/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import CreateProjectTypeValidator from "../../helpers/validators/CreateProjectTypeValidator";
import { useFormik } from "formik";
import { getErrorField } from '../../helpers/helper';


import * as projectTypeService from '../../services/service/projectTypeService';
import * as alert from '../../redux/alertSnackBarRedux';

const initialState = {
    id: 0,
    name: '',
    description: '',
    clientId: '',
    isDeleted: false,
    client: {}
}
const UpdateProjectTypeModal = (props) => {

    const { show, hide, reloadTable, showAlert, data } = props;

    const [projectType, setProjectType] = useState(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: CreateProjectTypeValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            updateProjectType(values, setSubmitting);
        }
    });

    const updateProjectType = (values, setSubmitting) => {
        let newValues = { ...values, clientId: data.clientId }
        projectTypeService.saveProjectType(newValues).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            reloadTable();
            hideModal();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'success' });
        }).finally(() => {
            setSubmitting(false);
        })
    }

    const hideModal = () => {
        setProjectType(initialState);
        formik.resetForm();
        hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Nspection Type
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row" style={{ alignContent: 'center' }}>
                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "name")}
                                id="outlined-name"
                                label="Name"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("name")}
                            />

                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                    {formik.errors.name}
                                </div>
                            ) : null}

                            <TextField
                                error={getErrorField(formik, "description")}
                                id="outlined-description"
                                label="Description"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("description")}
                            />

                            {formik.touched.description && formik.errors.description ? (
                                <div className="text-danger">
                                    {formik.errors.description}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideModal}>Cancel</Button>
                    <Button className="btn-success" disabled={formik.isSubmitting || (formik.touched && !formik.isValid)} type="submit">Update Nspection Type</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default connect(null, alert.actions)(UpdateProjectTypeModal);