import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { useFormik } from "formik";
import TextField from '@material-ui/core/TextField';
import { Button } from "react-bootstrap";
import ProjectTypeMetaDataFieldValidator from "../../../helpers/validators/ProjectTypeMetaDataFieldValidator";
import { getErrorField } from "../../../helpers/helper";
import * as projectTypeMetaDataFieldService from "../../../services/settings/projectTypeMetaDataFieldService";
import * as alert from "../../../redux/alertSnackBarRedux";

const ProjectTypeMetaDataFieldManagePage = (props) => {
    const location = useLocation();
    const { id, fieldId } = useParams();
    const history = useHistory();
    const [projectType, setProjectType] = useState(location.state?.projectType || null);
    const { showAlert } = props;

    useEffect(() => {
        if (fieldId) {
            projectTypeMetaDataFieldService.getProjectTypeMetaDataFieldById(fieldId).then(res => {
                formik.setValues(res);
            });
        }
    }, [fieldId]);

    const formik = useFormik({
        initialValues: {
            id: 0,
            question: '',
            projectTypeId: id,
            isDeleted: false
        },
        validationSchema: ProjectTypeMetaDataFieldValidator,
        onSubmit: (values) => {
            let newField = { ...values, projectTypeId: id };
            projectTypeMetaDataFieldService.saveProjectTypeMetaDataField(newField).then(res => {
                showAlert({ message: res.message, variant: 'success' });
                history.push(`/settings/projectType-metaDataField-listing/${id}`, { projectType });
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        }
    });

    return (
        <div className="col-lg-12 col-xxl-12">
            <div className="card card-custom card-stretch gutter-b">
                <div className="card-body">
                    <h3 className="card-title">
                        {/* Show Project Type Name */}
                        {projectType && (
                            <div>
                                <h5>{projectType.name}</h5>
                            </div>
                        )}
                        {fieldId ? "Edit Custom Field" : "Add Custom Field"}
                    </h3>
                    <form className="form" onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <TextField
                                    error={getErrorField(formik, "question")}
                                    id="outlined-question"
                                    label="Question"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps("question")}
                                />
                                {formik.touched.question && formik.errors.question ? (
                                    <div className="text-danger">
                                        {formik.errors.question}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button className="btn-light mr-2" onClick={() => history.push(`/settings/projectType-metaDataField-listing/${id}`, { projectType })}>
                                Cancel
                            </Button>
                            <Button className="btn-success" type="submit" disabled={formik.isSubmitting}>
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { ...alert.actions })(ProjectTypeMetaDataFieldManagePage);