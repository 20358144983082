/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { validateDescription } from '../../helpers/helper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import * as Yup from "yup";
import { getErrorField } from '../../helpers/helper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import * as projectPinCustomFieldService from '../../services/settings/projectPinCustomFieldService';
import * as alert from '../../redux/alertSnackBarRedux';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const UpdateCustomFieldModal = (props) => {
    const classes = useStyles();
    const { show, hide, showAlert, token, pin } = props;
    const [projectPinCustomField, setProjectPinCustomField] = useState(null);
    const [customFields, setCustomFields] = useState([]);


    useEffect(() => {
        if (pin && pin.id) {
            if (token) {
                fetchProjectPinCustomFieldShareable({ token, id: pin.id });
            }
            else {
                fetchProjectPinCustomField(pin.id);
            }
        }
    }, [pin.id]);

    const fetchProjectPinCustomField = (customFieldId) => {
        projectPinCustomFieldService.getProjectPinCustomFieldByProjectPinId(customFieldId).then(res => {
            setCustomFields(res);
        }).catch(err => {
            console.log(err);
        })
    }
    const handleCheckboxChange = (e, index, option) => {
        const updatedFields = [...customFields];
        let selectedOptions = updatedFields[index].description ? updatedFields[index].description.split(', ') : [];

        if (e.target.checked) {
            selectedOptions = [...selectedOptions, option];
        } else {
            selectedOptions = selectedOptions.filter(item => item !== option);
        }

        updatedFields[index].description = selectedOptions.join(', ');
        setCustomFields(updatedFields);
    };


    const handleDescriptionChange = (e, index) => {
        const updatedFields = [...customFields];
        const field = updatedFields[index];

        if (field.customFieldType.toLowerCase() === 'dropdown') {
            field.description = e.target.value;
        } else if (field.customFieldType.toLowerCase() === 'multiselect') {
            field.description = e.target.value.join(', ');
        } else {
            field.description = e.target.value;
        }

        setCustomFields(updatedFields);
    };

    const fetchProjectPinCustomFieldShareable = (model) => {
        projectPinCustomFieldService.getProjectPinCustomFieldByProjectPinIdShareableReport(model).then(res => {
            setCustomFields(res);
        }).catch(err => {
            console.log(err);
        })
    }

    const hasValidationError = () => {
        for (let index = 0; index < customFields.length; index++) {
            const field = customFields[index];
            if (validateDescription(field.description, field.customFieldType)) {
                return true;
            }
        }
        return false;
    };

    const saveCustomFieldName = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // if (hasValidationError) {
        //     showAlert({ message: "Please correct the errors before submitting.", variant: 'error' });
        //     return;
        // }
        if (token) {
            const savePromises = customFields.map(field =>
                projectPinCustomFieldService.saveProjectPinCustomFieldShareableReport({ ...field, token })
            );

            Promise.all(savePromises)
                .then(responses => {
                    responses.forEach(res => {
                        showAlert({ message: res.message, variant: 'success' });
                    });
                    hideModal();
                })
                .catch(ex => {
                    showAlert({ message: ex.message, variant: 'error' });
                });
        } else {
            const savePromises = customFields.map(field =>
                projectPinCustomFieldService.saveProjectPinCustomField({ ...field })
            );

            Promise.all(savePromises)
                .then(responses => {
                    responses.forEach(res => {
                        showAlert({ message: res.message, variant: 'success' });
                    });
                    hideModal();
                })
                .catch(ex => {
                    showAlert({ message: ex.message, variant: 'error' });
                });
        }
    };


    const hideModal = () => {
        hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Custom Field
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={saveCustomFieldName}>
                <Modal.Body>
                    <div className="row" style={{ alignContent: 'center' }}>
                        <div className='col-lg-12'>
                            <div>
                                {customFields.map((field, index) => {
                                    const validationError = validateDescription(field.description, field.customFieldType, field.options);
                                    let inputComponent;

                                    switch (field.customFieldType.toLowerCase()) {
                                        case 'dropdown':
                                            inputComponent = (
                                                <Select
                                                    value={field.description || ''}
                                                    onChange={(e) => handleDescriptionChange(e, index)}
                                                    displayEmpty
                                                    variant="outlined"
                                                    margin="normal"
                                                >
                                                    {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                        <MenuItem key={idx} value={option.trim()}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            );
                                            break;
                                        case 'checkbox':
                                            inputComponent = field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                <div className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                                    <FormControlLabel
                                                        key={idx}
                                                        control={
                                                            <Checkbox
                                                                checked={field.description?.split(', ').includes(option.trim()) || false}
                                                                onChange={(e) => handleCheckboxChange(e, index, option.trim())}
                                                                name={field.name}
                                                            />
                                                        }
                                                        label={option.trim()}
                                                    />
                                                </div>
                                            ));
                                            break;
                                        case 'multiselect':
                                            inputComponent = (
                                                <Select
                                                    multiple
                                                    value={field.description ? field.description.split(', ') : []}
                                                    onChange={(e) => handleDescriptionChange(e, index)}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    variant="outlined"
                                                    margin="normal"
                                                >
                                                    {field.customFieldTypeOptions.split(',').map((option, idx) => (
                                                        <MenuItem key={idx} value={option.trim()}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            );
                                            break;
                                        default:
                                            inputComponent = (
                                                <TextField
                                                    label="Description"
                                                    value={field.description || ''}
                                                    onChange={(e) => handleDescriptionChange(e, index)}
                                                    multiline={field.customFieldType.toLowerCase().includes('text')}
                                                    rowsMax={field.customFieldType.toLowerCase().includes('text') ? "4" : "1"}
                                                    margin="normal"
                                                    variant="outlined"
                                                    className="large-textarea"
                                                    InputLabelProps={field.customFieldType.toLowerCase().includes('date') ? { shrink: true } : undefined}
                                                    type={field.customFieldType.toLowerCase().includes('date') ? 'date' : 'text'}
                                                />
                                            );
                                    }

                                    return (
                                        customFields[index]?.isViewable && (
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <FormControl variant="outlined" style={{ width: '100%', marginTop: 15 }}>
                                                        <h5>{field.name}</h5>
                                                        {field.customFieldType && <h6>({field.customFieldType})</h6>}
                                                        {inputComponent}
                                                        {validationError && <div className="text-danger">{validationError}</div>}
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ))
                                })}
                            </div>
                        </div>
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideModal}>Cancel</Button>
                    <Button className="btn-success" type="submit" disabled={hasValidationError()}>Update</Button>
                </Modal.Footer>
            </form>
        </Modal >
    );
}

export default connect(null, alert.actions)(UpdateCustomFieldModal);