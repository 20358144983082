export const actionTypes = {
    ShowAlert: "SHOW_ALERT",
    HideAlert: "HIDE_ALERT",
};

const initialAlertState = {
    isVisible: false,
    message: "",
    variant: "success",
};

export const reducer = (state = initialAlertState, action) => {

    switch (action.type) {
        case actionTypes.ShowAlert: {
            return {
                isVisible: true,
                message: action.payload.message,
                variant: action.payload.variant,
                top: action.payload.top
            };
        }

        case actionTypes.HideAlert: {
            return initialAlertState;
        }

        default:
            return state;
    }
};

export const actions = {
    showAlert: (model) => ({ type: actionTypes.ShowAlert, payload: model }),
    hideAlert: () => ({ type: actionTypes.HideAlert }),
};
