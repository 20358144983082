/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { useFormik } from "formik";
import ChangePasswordValidator from "../../helpers/validators/ChangePasswordValidator";
import { getErrorField } from "../../helpers/helper";
import * as userService from "../../services/management/userService";
import * as alert from "../../redux/alertSnackBarRedux";

const defaultState = {
    password: '',
    confirmPassword: '',
}

const ChangePasswordModal = (props) => {
    const { showAlert, show, hide, userToEditId, resetUserToEdit } = props;
    const [initialState, setInitialState] = useState(defaultState);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialState,
        validationSchema: ChangePasswordValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            changePassword(values, setSubmitting);
        }
    });

    const changePassword = (values, setSubmitting) => {
        userService.changeUserPassword(userToEditId, values.password).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            hidePasswordModal();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => {
            setSubmitting(false);
        })
    }

    const hidePasswordModal = () => {
        setInitialState(defaultState);
        formik.resetForm();
        resetUserToEdit();
        hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hidePasswordModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Password
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "password")}
                                id="outlined-name"
                                label="New Password"
                                margin="normal"
                                type="password"
                                variant="outlined"
                                {...formik.getFieldProps("password")}
                            />

                            {formik.touched.password && formik.errors.password ? (
                                <div className="text-danger">
                                    {formik.errors.password}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "confirmPassword")}
                                id="outlined-name"
                                label="Confirm Password"
                                margin="normal"
                                type="password"
                                variant="outlined"
                                {...formik.getFieldProps("confirmPassword")}
                            />

                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <div className="text-danger">
                                    {formik.errors.confirmPassword}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hidePasswordModal}>Cancel</Button>
                    <Button className="btn-success" disabled={formik.isSubmitting || (formik.touched && !formik.isValid)} type="submit"> Save Password </Button>
                </Modal.Footer>
            </form>

        </Modal>
    );
}

export default connect(null, alert.actions)(ChangePasswordModal);