import React, { useState } from "react";
import { Link } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import * as clientService from "../../../../../services/management/clientService";

export function QuickActions({ role, clientRefId }) {
  const [paymentProfileLinks, setPaymentProfileLinks] = useState({});

  const loadPaymentProfileLink = (id) => {
    const existingLinkInfo = paymentProfileLinks[id];

    if (existingLinkInfo) {
      const expiresAt = new Date(existingLinkInfo.expiresAt);
      const now = new Date();

      if (expiresAt > now) {
        window.open(existingLinkInfo.url, '_blank');
        return;
      }
    }

    clientService.getManagementLink(id).then(res => {
      if (res && res.url && res.expiresAt) {
        const expiresAt = new Date(res.expiresAt);
        const now = new Date();

        if (expiresAt > now) {
          setPaymentProfileLinks({
            ...paymentProfileLinks,
            [id]: { url: res.url, expiresAt: res.expiresAt }
          });
          window.open(res.url, '_blank');
        }
      }
    }).catch(console.log);
  }

  const closeMenu = () => {
    const menu = document.getElementById('kt_quick_actions');
    const overlay = document.querySelector('.offcanvas-overlay');
    
    if (menu) {
      menu.classList.remove('offcanvas-on');
    }
    
    if (overlay) {
      overlay.remove();
    }
  };

  return (
    <>
      <div id="kt_quick_actions" className="offcanvas offcanvas-left">
        <div className="offcanvas-header">
          <h3 className="font-weight-bold m-0">Quick Actions</h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-danger"
            id="kt_quick_actions_close"
            onClick={(e) => {
              e.preventDefault();
              closeMenu();
            }}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>

        <div className="offcanvas-content">
          <div className="menu-grid">
            {role !== 'Viewer' && role !== 'User' && (
              <>
                <Link
                  to="/projects"
                  onClick={closeMenu}
                  className="menu-item"
                >
                  <span className="icon-wrapper">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} /> {/* Colorful Icon */}
                  </span>
                  <span className="menu-text">Nspections</span>
                </Link>

                <Link
                  to="/projectTypes"
                  onClick={closeMenu}
                  className="menu-item"
                >
                  <span className="icon-wrapper">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box1.svg")} /> {/* Colorful Icon */}
                  </span>
                  <span className="menu-text">Nspection Types</span>
                </Link>
              </>
            )}

            {(role === 'SuperAdmin' || role === 'Admin') && (
              <Link to="/users" onClick={closeMenu} className="menu-item">
                <span className="icon-wrapper">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} /> {/* Colorful Icon */}
                </span>
                <span className="menu-text">Users</span>
              </Link>
            )}

            {role !== 'Viewer' && (
              <Link to="/clients" onClick={closeMenu} className="menu-item">
                <span className="icon-wrapper">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Color-profile.svg")} /> {/* Colorful Icon */}
                </span>
                <span className="menu-text">Clients</span>
              </Link>
            )}

            {role === 'SuperAdmin' && (
              <Link
                to="/settings/setting"
                onClick={closeMenu}
                className="menu-item"
              >
                <span className="icon-wrapper">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} /> {/* Colorful Icon */}
                </span>
                <span className="menu-text">Settings</span>
              </Link>
            )}

            {role === 'Admin' && (
              <a
                onClick={() => {
                  loadPaymentProfileLink(clientRefId);
                  closeMenu();
                }}
                className="menu-item"
              >
                <span className="icon-wrapper">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} /> {/* Colorful Icon */}
                </span>
                <span className="menu-text">Billing</span>
              </a>
            )}
          </div>
        </div>
      </div>

      <style>{`
        #kt_quick_actions {
          width: 300px !important;
          position: fixed;
          top: 0;
          bottom: 0;
          overflow-y: auto;
          background: #ffffff;
          z-index: 9999;
        }

        .offcanvas-header {
          padding: 1.25rem;
          border-bottom: 1px solid #EBEDF3;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .offcanvas-header h3 {
          font-size: 1.2rem;
          color: #181C32;
          margin: 0;
        }

        .offcanvas-content {
          padding: 1.25rem;
        }

        .menu-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
        }

        .menu-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem;
          background: #F3F6F9;
          border-radius: 0.42rem;
          transition: all 0.15s ease;
          cursor: pointer;
          text-decoration: none !important;
          border: 1px solid transparent;
        }

        .menu-item:hover {
          background: #E1F0FF;
          border-color: #D7EBFF;
          transform: translateY(-1px);
        }

        .icon-wrapper svg {
          width: 24px;
          height: 24px;
        }

        .icon-wrapper svg path {
          fill: currentColor;
        }

        /* Color for specific menu items */
        .menu-item:nth-child(1) .icon-wrapper {
          color: #FFA800; /* Orange */
        }

        .menu-item:nth-child(2) .icon-wrapper {
          color: #3699FF; /* Blue */
        }

        .menu-item:nth-child(3) .icon-wrapper {
          color: #8950FC; /* Purple */
        }

        .menu-item:nth-child(4) .icon-wrapper {
          color: #1BC5BD; /* Green */
        }

        .menu-item:nth-child(5) .icon-wrapper {
          color: #8950FC; /* Purple */
        }

        .menu-item:nth-child(6) .icon-wrapper {
          color: #F64E60; /* Red */
        }

        .menu-text {
          font-size: 0.9rem;
          font-weight: 500;
          color: #3F4254;
          text-align: center;
        }

        #kt_quick_actions_close {
          padding: 0.5rem;
          margin: -0.5rem -0.5rem -0.5rem auto;
        }

        #kt_quick_actions_close:hover {
          background: #F3F6F9;
          border-radius: 0.42rem;
        }

        @media (max-width: 991.98px) {
          #kt_quick_actions {
            left: -300px;
            transition: left 0.3s ease;
          }

          #kt_quick_actions.offcanvas-on {
            left: 0;
          }
        }

        @media (min-width: 992px) {
          #kt_quick_actions {
            right: -300px;
            transition: right 0.3s ease;
          }

          #kt_quick_actions.offcanvas-on {
            right: 0;
          }
        }

        .offcanvas-overlay {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          overflow: hidden;
          z-index: 9998;
          background: rgba(0, 0, 0, 0.1);
          animation: animation-offcanvas-fade-in .3s ease;
        }
      `}</style>
    </>
  );
}

export default QuickActions;
