import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Role/";

const getRoles = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetRoles', constants.REQUIRE_INTERCEPTORS);

const saveRole = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveRole', model, constants.REQUIRE_INTERCEPTORS);

export {
    getRoles,
    saveRole,
}