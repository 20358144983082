import * as Yup from "yup";

export default Yup.object().shape({
    id: Yup.number(),
    logoFile: Yup.string().nullable(),
    businessName: Yup.string().required("Business is required"),
    clientTierId: Yup.string().required("Client Tier is required"),
    noOfProject: Yup.number().required("Please specify no of projects"),
    noOfProject: Yup.number().nullable(),
    contactName: Yup.string().required("Contact Name is required"),
    contactEmail: Yup.string().email("Please enter a valid email").required("Contact Email is required"),
    contactPhone: Yup.string().required("Contact Phone is required"),
    companyAddress: Yup.string().required("Company Address is required"),
});