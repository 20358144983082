import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectTypePOIStatus/";

const getPOIStatusById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetPOIStatusById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getPOIStatusByProjectTypeId = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetPOIStatusByProjectTypeId?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getPOIStatuses = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetPOIStatuses', constants.REQUIRE_INTERCEPTORS);

const savePOIStatus = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SavePOIStatus', model, constants.REQUIRE_INTERCEPTORS);

const deletePOIStatus = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeletePOIStatus?id=' + id, constants.REQUIRE_INTERCEPTORS);

const deletePOIStatusIsDeletedFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeletePOIStatusByIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS);

export {
    getPOIStatusById,
    getPOIStatusByProjectTypeId,
    getPOIStatuses,
    savePOIStatus,
    deletePOIStatus,
    deletePOIStatusIsDeletedFlag
}