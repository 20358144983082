import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ClientTier/";

const getClientTierById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetClientTierById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getClientTiers = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetClientTiers', constants.REQUIRE_INTERCEPTORS);
const getProductsFromChargify = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetProductsFromChargify', constants.REQUIRE_INTERCEPTORS);

const saveClientTier = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveClientTier', model, { ...constants.REQUIRE_INTERCEPTORS, fileHandler: true });

const deleteClientTierByIsDeleteFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteClientTierByIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS)

export {
    getClientTierById,
    getClientTiers,
    saveClientTier,
    getProductsFromChargify,
    deleteClientTierByIsDeleteFlag
}