import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import * as poiStatusService from '../../../services/settings/POIStatusService';
import * as clientTierService from '../../../services/management/clientTierService';
import * as alert from '../../../redux/alertSnackBarRedux';
import * as confirmModal from '../../../redux/confirmModalRedux';
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';
import { Button } from 'react-bootstrap';
import { checkTierPlanAndRoleTier3 } from '../../../helpers/helper';

const ProjectTypePOIStatusListingPage = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { projectType } = location.state || {};
  const { showAlert, showConfirmModal, hideConfirmModal, auth } = props;
  const [poiColor, setPoiColor] = useState('');
  const [isBusinessTier, setIsBusinessTier] = useState(false);
  const [tier, setTier] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    loadTableData();
  }, [id]);

  const loadTableData = useCallback(() => {
    if (id) {
      poiStatusService.getPOIStatusByProjectTypeId(id)
        .then(res => {
          setData(res);
          return res[0];
        })
        .then(projectType => {
          if (projectType) {
            fetchClientTier(projectType);
          }
        })
        .catch(ex => {
          showAlert({ message: ex.message, variant: 'error' });
        });
    }
  }, [id]);

  const fetchClientTier = (poi) => {
    let tierId = poi.projectType.client.clientTier.id;
    if (tierId) {
      clientTierService.getClientTierById(tierId).then(res => {
        setTier(res);
        const isTier3 = checkTierPlanAndRoleTier3(res, auth.role);
        if (isTier3) setIsBusinessTier(isTier3);
      });
    }
  };

  const deletePOIStatus = (id, projectTypeId) => {
    const deleteModel = { id, projectTypeId, isDeleted: true };
    poiStatusService.deletePOIStatusIsDeletedFlag(deleteModel)
      .then(res => {
        showAlert({ message: res.message, variant: 'success' });
        loadTableData();
        hideConfirmModal();
      })
      .catch(ex => {
        showAlert({ message: ex.message, variant: 'error' });
      });
  };

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => data[dataIndex].description || '-',
      },
    },
    ...(isBusinessTier ? [{
      name: 'poiColorCode',
      label: 'POI Color Code',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => data[dataIndex].poiColorCode || '-',
      },
    }] : []),
    {
      name: 'isDefault',
      label: 'User Upload Value',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => data[dataIndex].isDefault && (
          <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{ whiteSpace: 'nowrap' }}>
            {'User Upload Value'}
          </span>
        ),
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <>
            <button
              className="btn btn-sm btn-info font-weight-bold py-2 px-3 my-1"
              onClick={() => history.push(`/settings/poi-status/manage/${id}/${data[dataIndex].id}`, { projectType })}
            >
              Edit
            </button>
            &nbsp;
            <button
              className="btn btn-sm btn-danger font-weight-bold py-2 px-3 my-1"
              onClick={() => showConfirmModal({
                title: 'Delete POI Status',
                text: 'Are you sure you want to delete this POI status?',
                rightBtnText: 'Confirm',
                btnAction: () => deletePOIStatus(data[dataIndex].id, data[dataIndex].projectTypeId),
              })}
            >
              Delete
            </button>
          </>
        ),
      },
    },
  ];

  return (
    <div className="col-lg-12 col-xxl-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">{projectType?.name}</span>
            <span className="text-muted mt-3 font-weight-bold">POI Status</span>
          </h3>
          <div className="card-toolbar">
            <Button
              onClick={() => history.push(`/settings/poi-status/manage/${id}`, { projectType })}
              className="btn btn-success"
            >
              <span className="fa fa-plus"></span> Add POI Status
            </Button>
          </div>
        </div>
        <ProjectTypeNavbar id={id} projectType={projectType} />
        <div className="container-fluid">
          <MUIDataTable data={data} columns={columns} options={{ selectableRows: 'none' }} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(ProjectTypePOIStatusListingPage);
