import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectTypeCheckBox/";

const getProjectTypeCheckBoxById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeCheckBoxById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectTypeCheckBoxByProjectTypeId = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeCheckBoxByProjectTypeId?projTypeId=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectTypeCheckBoxes = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeCheckBoxes', constants.REQUIRE_INTERCEPTORS);

const saveProjectTypeCheckBox = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectTypeCheckBox', model, constants.REQUIRE_INTERCEPTORS);

const deleteProjectTypeCheckBox = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProjectTypeCheckBox?id=' + id, constants.REQUIRE_INTERCEPTORS);

const deleteProjectTypeCheckBoxIsDeletedFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteProjectTypeCheckBoxIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS);

export {
    getProjectTypeCheckBoxById,
    getProjectTypeCheckBoxByProjectTypeId,
    getProjectTypeCheckBoxes,
    saveProjectTypeCheckBox,
    deleteProjectTypeCheckBox,
    deleteProjectTypeCheckBoxIsDeletedFlag
}