import React from 'react';
import {
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import PropertyImprovementPlanReportHeader from './PropertyImprovementPlanReportHeader';
import PropertyImprovementPlanReportPropertyDetails from './PropertyImprovementPlanReportPropertyDetails';
import PropertyImprovementPlanReportTable from './PropertyImprovementPlanReportTable';

const styles = StyleSheet.create({
  container: { marginTop: 18 }
}
);

const PropertyImprovementPlanReport = (props) => {
  const { formJson, pins, project, fetchHashLinkForReport, userId } = props;
  
  const groupedPins = groupByCategory(pins.projectPinListingDtos);

  function groupByCategory(pins) {
    if(pins && pins.length > 0) {
      return pins.reduce((acc, pin) => {
        if (!acc[pin.pointCategory]) {
          acc[pin.pointCategory] = [];
        }
        acc[pin.pointCategory].push(pin);
        return acc;
      }, {});
    }
  }

  function sortPinsAlphabetically(pins) {
    return pins.sort((a, b) => a.title.localeCompare(b.title));
  }

  return (
    <PDFViewer style={{
      height: '90vh',
      width: '100%'
    }}>
      {
        (formJson && pins) &&
        <Document
          author="NSPECT"
          keywords="nspect, inspection, property improvement plan"
          subject="Property Improvement Plan Report"
          title="Property Improvement Plan Report"
        >
          <Page size="A4" style={{
            padding: 30,
          }}>
            <PropertyImprovementPlanReportHeader />
            <View style={styles.container}>
              <PropertyImprovementPlanReportPropertyDetails
                formJson={formJson}
              />
              {
                groupedPins && Object.keys(groupedPins).map(category => (
                  <PropertyImprovementPlanReportTable
                    key={category}
                    userId={userId}
                    project={project}
                    fetchHashLinkForReport={fetchHashLinkForReport}
                    modelLink={project.modelLink}
                    title={category.toUpperCase()}
                    pins={sortPinsAlphabetically(groupedPins[category])}
                  />
                ))
              }
            </View>
          </Page>
        </Document>
      }

    </PDFViewer>
  );
};

export default PropertyImprovementPlanReport;