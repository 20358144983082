import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import * as projectTypeCheckBoxService from '../../../services/settings/projectTypeCheckBoxService';
import * as alert from '../../../redux/alertSnackBarRedux';
import * as confirmModal from '../../../redux/confirmModalRedux';
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';
import { Button } from 'react-bootstrap';

const ProjectTypeCheckBoxListingPage = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const { projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        loadTableData();
    }, [id]);

    const loadTableData = useCallback(() => {
        if (id) {
            projectTypeCheckBoxService.getProjectTypeCheckBoxByProjectTypeId(id).then((res) => {
                setData(res);
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: 'error' });
            });
        }
    }, [id]);

    const deleteProjectTypeCheckBox = (id, projectTypeId) => {
        let newModel = { id: id, projectTypeId: projectTypeId, isDeleted: true };
        projectTypeCheckBoxService.deleteProjectTypeCheckBoxIsDeletedFlag(newModel).then((res) => {
            showAlert({ message: res.message, variant: 'success' });
            loadTableData();
            hideConfirmModal();
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: 'error' });
        });
    };

    const columns = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'isDefault',
            label: 'User Upload Value',
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return data[dataIndex].isDefault && (
                        <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{ whiteSpace: 'nowrap' }}>
                            {'User Upload Value'}
                        </span>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 my-1"
                                onClick={() => {
                                    history.push(`/settings/projectType-checkbox/manage/${id}/${data[dataIndex].id}`, { projectType });
                                }}
                            >
                                Edit
                            </button>
                            &nbsp;
                            <button
                                onClick={() =>
                                    showConfirmModal({
                                        title: 'Delete CheckBox',
                                        text: 'Are you sure you want to delete this checkbox?',
                                        rightBtnText: 'Confirm',
                                        btnAction: () => deleteProjectTypeCheckBox(data[dataIndex].id, data[dataIndex].projectTypeId),
                                    })
                                }
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 my-1"
                            >
                                Delete
                            </button>
                        </>
                    );
                },
            },
        },
    ];

    return (
        <div className="col-lg-12 col-xxl-12">
            <div className="card card-custom card-stretch gutter-b">
                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{projectType?.name}</span>
                        <span className="text-muted mt-3 font-weight-bold">Checkbox</span>
                    </h3>
                    <div className="card-toolbar">
                        <Button
                            onClick={() => history.push(`/settings/projectType-checkbox/manage/${id}`, { projectType })}
                            className="btn btn-success"
                        >
                            <span className="fa fa-plus"></span> Add CheckBox
                        </Button>
                    </div>
                </div>
                <ProjectTypeNavbar id={id} projectType={projectType} />
                <div className="container-fluid">
                    <MUIDataTable data={data} columns={columns} options={{ selectableRows: 'none' }} />
                </div>
            </div>
        </div>
    );
};

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ProjectTypeCheckBoxListingPage);
