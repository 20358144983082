import React, { useState } from "react";
// import MatterportModelWindow from '../../../app/components/MatterportModelWindow';
// import MatterportPointWindow from '../../../app/components/MatterportPointWindow';
// import AddPointModal from '../../../app/components/AddPointModal';
// import FullWidthTable from '../../../app/components/FullWidthTable';
// import TableFullWidth from '../../../app/components/TableFullWidth';
import UserListingPage from '../../../app/pages/management/user/UserListingPage';
import ClientListingPage from '../../../app/pages/management/client/ClientListingPage';
import ProjectListingPage from '../../../app/pages/services/project/ProjectListingPage';

import { connect } from "react-redux";

const Demo3Dashboard = (props) => {
  const { auth } = props;
  return (
    <>
      <ProjectListingPage {...props}/>
      <br />
      {
        auth.role === "SuperAdmin" ?
          <>
            <ClientListingPage {...props}/>
            <br />
            <UserListingPage {...props}/>
          </>
           : 
          auth.role === 'Admin' ?
            <>
              <UserListingPage {...props}/>
              </>
              : <></>
      }


      {/* begin::Dashboard */}

      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-xl-4">
          <MixedWidget4 className="gutter-b card-stretch" />
        </div>
        <div className="col-xl-8">
          <BaseTablesWidget6 className="gutter-b" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-xl-4">
          <div className="row">
            <div className="col-xl-12">
              <StatsWidget11
                className="gutter-b"
                symbolShape="circle"
                baseColor="danger"
              />
            </div>
            <div className="col-xl-12">
              <StatsWidget10
                className="gutter-b"
                symbolShape="circle"
                baseColor="info"
              />
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <ListsWidget14 className="gutter-b card-stretch" />
        </div>
      </div> */}
      {/* end::Row */}


      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <AdvanceTablesWidget9 className="card-stretch gutter-b" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <AdvanceTablesWidget9 className="card-stretch gutter-b" />
        </div>
      </div> */}

      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-xl-6">
          <ListsWidget10 className="card-stretch gutter-b" />
        </div>
        <div className="col-xl-6">
          <BaseTablesWidget1 className="card-stretch gutter-b" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-lg-4">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <BaseTablesWidget2 className="card-stretch gutter-b" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* end::Dashboard */}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, null)(Demo3Dashboard);