import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import jwt_decode from "jwt-decode";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  UpgradeTier: "[Upgrade Tier] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  role: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth-token", whitelist: ["user", "authToken", "role", "clientRefId"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token } = action.payload;
        const decodedToken = jwt_decode(token);
        return { authToken: token, user: undefined, role: decodedToken.role, clientRefId: decodedToken.RefId };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.UpgradeTier: {
        const { user } = state;
        const updatedUser = { ...user, tier: action.payload };
        return { ...state, user: updatedUser };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user) => ({ type: actionTypes.Login, payload: user }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: user }),
  upgradeTier: (tier) => ({ type: actionTypes.UpgradeTier, payload: tier })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload }) {
    yield put(actions.requestUser(payload));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested({ payload }) {

    yield put(actions.fulfillUser(payload.user));
  });
}
