import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Users/";

const getAllUsers = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetAllUser', constants.REQUIRE_INTERCEPTORS)

const registerUser = async (user) => await axiosInstance.post(ENDPOINTORIGIN + 'AddUser', user, constants.REQUIRE_INTERCEPTORS)

const getUserById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetUserById/' + id, constants.REQUIRE_INTERCEPTORS);

const updateUser = async (user) => await axiosInstance.post(ENDPOINTORIGIN + 'edit', user, constants.REQUIRE_INTERCEPTORS)

const changeUserPassword = async (userId, pwd) => await axiosInstance.post(ENDPOINTORIGIN + `ChangePassword/${userId}/${pwd}`, {}, constants.REQUIRE_INTERCEPTORS)

const deleteUser = async (userId) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteUser/' + userId, constants.REQUIRE_INTERCEPTORS)

const deleteUserByIsDelete = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteUserByIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS)

export {
    getAllUsers,
    registerUser,
    getUserById,
    changeUserPassword,
    deleteUser,
    updateUser,
    deleteUserByIsDelete
}