import * as Yup from "yup";

export default Yup.object().shape({
    id: Yup.number(),
    projectId: Yup.number(),
    pointCategoryId: Yup.string().required("Category is required"),
    projectTypePOIStatusId: Yup.string().nullable(),
    title: Yup.string().required('Title is required'),
    description: Yup.string().nullable(),
    updatedBy: Yup.number().nullable(),
    matterPortResolutionLink: Yup.string().nullable(),
    updatedDateTime: Yup.string().nullable(),
    isCritical: Yup.bool(),
    isOsha: Yup.bool(),
});