import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Button } from 'react-bootstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as pointCategoryService from '../../../services/settings/pointCategoryService';
import * as projectTypeService from '../../../services/service/projectTypeService';
import * as projectTypeCustomFieldService from '../../../services/settings/projectTypeCustomFieldService';
import PointCategoryValidator from '../../../helpers/validators/PointCategoryValidator';
import { getErrorField } from '../../../helpers/helper';
import * as alert from '../../../redux/alertSnackBarRedux';

const initialPointCategory = {
  id: 0,
  name: '',
  description: '',
  projectTypeId: '',
  projectTypeCustomFieldId: 0,
  isDeleted: false,
  isDefault: false,  // Ensure this is handled correctly
};

const PointCategoryManagePage = (props) => {
  const { showAlert } = props;
  const { id, pointCategoryId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedPointCategory, setSelectedPointCategory] = useState(initialPointCategory);
  const [projectTypeCustomFields, setProjectTypeCustomFields] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [projectType, setProjectType] = useState(location.state?.projectType || null);

  useEffect(() => {
    if (!projectType && id) {
      projectTypeService.getProjectTypeById(id).then(res => {
        setProjectType(res);
      });
    }
  }, [id, projectType]);

  useEffect(() => {
    if (pointCategoryId) {
      pointCategoryService.getPointCategoryById(pointCategoryId).then((res) => {
        setSelectedPointCategory(res);
        setSelectedCustomFields(res.pointCategoryProjectTypeCustomFields.map((x) => x.projectTypeCustomFieldId));
      });
    }
    loadProjectTypeCustomFields();
  }, [pointCategoryId]);

  const loadProjectTypeCustomFields = () => {
    if (id) {
      projectTypeCustomFieldService.getProjectTypeCustomFieldByProjectTypeId(id).then((res) => {
        const formattedFields = res.map((field) => ({
          value: field.id,
          label: field.name,
          isDefault: field.isDefault
        }));
        setProjectTypeCustomFields(formattedFields);
      });
    }
  };

  // const editPointCategory = (id) => {
  //   pointCategoryService.getPointCategoryById(id).then((res) => {
  //     setSelectedPointCategory(res);

  //     if (res.isDefault) {
  //       setSelectedCustomFields(
  //         res.pointCategoryProjectTypeCustomFields
  //           .filter((field) => field.projectTypeCustomField.isDefault)
  //           .map((x) => x.projectTypeCustomFieldId)
  //       );
  //     } else {
  //       setSelectedCustomFields(
  //         res.pointCategoryProjectTypeCustomFields
  //           .map((x) => x.projectTypeCustomFieldId)
  //       );
  //     }

  //     loadProjectTypeCustomFields(res.isDefault);
  //   });
  // };

  const handleCustomFieldChange = (event) => {
    setSelectedCustomFields(event.target.value);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedPointCategory,
    validationSchema: PointCategoryValidator,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      const newValues = {
        ...values,
        projectTypeId: id,
        projectTypeCustomFieldIds: selectedCustomFields,
      };

      pointCategoryService
        .savePointCategory(newValues)
        .then((res) => {
          showAlert({ message: res.message, variant: 'success' });
          history.push(`/settings/point-category-listing/${id}`, { projectType: location.state.projectType });
        })
        .catch((ex) => {
          showAlert({ message: ex.message, variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="col-lg-12 col-xxl-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body">
          <h3 className="card-title">
            {/* Show Project Type Name */}
            {projectType && (
              <div>
                <h5>{projectType.name}</h5>
              </div>
            )}
            {pointCategoryId ? 'Edit Point Category' : 'Add New Point Category'}
          </h3>
          <form onSubmit={formik.handleSubmit}>
            <FormControl className="col-lg-12 mb-4">
              <TextField
                label="Name"
                variant="outlined"
                {...formik.getFieldProps('name')}
                error={getErrorField(formik, 'name')}
                helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
              />
            </FormControl>

            <FormControl className="col-lg-12 mb-4">
              <TextField
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                {...formik.getFieldProps('description')}
                error={getErrorField(formik, 'description')}
                helperText={formik.touched.description && formik.errors.description ? formik.errors.description : ''}
              />
            </FormControl>

            {/* Handle the isDefault checkbox */}
            <FormControl className="col-lg-12 mb-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isDefault}
                    onChange={(e) => formik.setFieldValue('isDefault', e.target.checked)}
                  />
                }
                label="User Upload Value"
              />
            </FormControl>

            <FormControl variant="outlined" className="col-lg-12 mb-4">
              <InputLabel id="custom-fields-label">Project Type Custom Fields</InputLabel>
              <Select
                labelId="custom-fields-label"
                multiple
                value={selectedCustomFields}
                onChange={handleCustomFieldChange}
              >
                {projectTypeCustomFields.map((field) => (
                  <MenuItem key={field.value} value={field.value}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="d-flex justify-content-end">
              <Button className="btn-light mr-2" onClick={() => history.push(`/settings/point-category-listing/${id}`, { projectType: location.state.projectType })}>
                Cancel
              </Button>
              <Button className="btn-success" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                {pointCategoryId ? 'Update' : 'Create'} Point Category
              </Button>
            </div>
          </form>

          {loading && <LinearProgress />}
        </div>
      </div>
    </div>
  );
};

export default connect(null, alert.actions)(PointCategoryManagePage);
