import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import UserListingPage from "./pages/management/user/UserListingPage";
import AssignProjectPage from './pages/management/user/AssignProjectPage';
import ChangePasswordPage from './pages/management/user/ChangePasswordPage';
import ClientListingPage from './pages/management/client/ClientListingPage';
import ProjectListingPage from './pages/services/project/ProjectListingPage';
import ProjectTypeListingPage from './pages/services/project/ProjectTypeListingPage';
import ProjectTypeAddPage from './pages/services/project/ProjectTypeAddPage';
import ProjectTypeEditPage from './pages/services/project/ProjectTypeEditPage';
import ProjectEditPage from './pages/services/project/ProjectEditPage';
import ProjectManagePage from './pages/services/project/ProjectManagePage';
import NspectionPinsManagePage from './pages/services/project/NspectionPinsManagePage';
import ChargifyForm from "./pages/chargifyForm/ChargifyForm";
import PointCategoryListingPage from './pages/settings/PointCategoryListingPage';
import PointCategoryManagePage from './pages/settings/PointCategoryManagePage';
import SettingListingPage from './pages/settings/SettingListingPage';
import SettingManagePage from './pages/settings/SettingManagePage';
import GenerateNewReportPage from './pages/services/project/GenerateNewReportPage';
import ProjectTypeCheckBoxListingPage from "./pages/settings/ProjectTypeCheckBoxListingPage";
import ProjectTypeCheckBoxManagePage from "./pages/settings/ProjectTypeCheckBoxManagePage";
import ProjectTypeCustomFieldListingPage from "./pages/settings/ProjectTypeCustomFieldListingPage";
import ProjectTypeCustomFieldManagePage from "./pages/settings/ProjectTypeCustomFieldManagePage";
import ProjectTypePOIStatusListingPage from "./pages/settings/ProjectTypePOIStatusListingPage";
import ProjectTypePOIStatusManagePage from "./pages/settings/ProjectTypePOIStatusManagePage";
import ProjectTypeMetaDataFieldListingPage from "./pages/settings/ProjectTypeMetaDataFieldListingPage";
import ProjectTypeMetaDataFieldManagePage from "./pages/settings/ProjectTypeMetaDataFieldManagePage";
import PropertyImprovementPlanReport from "./components/PropertyImprovementPlanReport";
import ClientManagementPage from "./pages/management/client/ClientManagementPage";
import UserManagementPage from "./pages/management/user/UserManagementPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path='/users' component={UserListingPage} />
        <ContentRoute path='/user/manage/:id' component={UserManagementPage} />
        <ContentRoute path='/chargify/:clientId' component={ChargifyForm} />
        <ContentRoute path='/clients' component={ClientListingPage} />
        <ContentRoute path='/client/manage/:id' component={ClientManagementPage} />
        <ContentRoute path='/project/edit-project/:id' component={ProjectEditPage} />
        <ContentRoute path='/project/manage/:id' component={ProjectManagePage} />
        <ContentRoute path='/nspection-pins/manage/:id' component={NspectionPinsManagePage} />
        <ContentRoute path='/projectType/edit-ProjectType/:id' component={ProjectTypeEditPage} />
        <ContentRoute exact path='/projects' component={ProjectListingPage} />
        <ContentRoute exact path='/projecttypes' component={ProjectTypeListingPage} />
        <ContentRoute path='/projecttypes/add' component={ProjectTypeAddPage} />
        <ContentRoute path='/projecttypes/edit/:id' component={ProjectTypeEditPage} />
        <ContentRoute path='/settings/point-category-listing/:id' component={PointCategoryListingPage} />
        <ContentRoute path='/settings/point-category/manage/:id/:pointCategoryId?' component={PointCategoryManagePage} />
        <Route exact path="/settings/setting" component={SettingListingPage} />
        <Route path="/settings/setting/manage/:id?" component={SettingManagePage} />
        <ContentRoute path='/settings/poi-listing/:id' component={ProjectTypePOIStatusListingPage} />
        <ContentRoute path='/settings/poi-status/manage/:id/:statusId?' component={ProjectTypePOIStatusManagePage} />
        <ContentRoute path='/settings/projectType-checkbox-listing/:id' component={ProjectTypeCheckBoxListingPage} />
        <ContentRoute path='/settings/projectType-checkbox/manage/:id/:checkboxId?' component={ProjectTypeCheckBoxManagePage} />
        <ContentRoute path='/settings/projectType-customField-listing/:id' component={ProjectTypeCustomFieldListingPage} />
        <ContentRoute path='/settings/projectType-customField/manage/:id/:fieldId?' component={ProjectTypeCustomFieldManagePage} />
        <ContentRoute path='/settings/projectType-metaDataField-listing/:id' component={ProjectTypeMetaDataFieldListingPage} />
        <Route path="/settings/projectType-metaDataField/manage/:id/:fieldId?" component={ProjectTypeMetaDataFieldManagePage} />
        <Route path="/user/change-password/:userId" component={ChangePasswordPage} />
        <Route path="/user/assign-project/:userId" component={AssignProjectPage} />
        <ContentRoute path='/project/report' component={GenerateNewReportPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
