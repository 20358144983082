import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import NewProjectModal from "../../../components/NewProjectModal";
import * as alert from "../../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../../redux/confirmModalRedux";
import * as projectService from '../../../../services/service/projectService';
import { Link } from 'react-router-dom';

const moment = require('moment');

const ProjectListingPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal, auth, history, setProcessing } = props;
    const [deleteProjectModel, setDeleteProjectModel] = useState(
        {
            id: 0,
            projectName: '',
            clientId: '',
            scanDate: '',
            modelLink: '',
            projectTypeId: '',
            notes: '',
            isDeleted: false
        }
    )
    // const [addProjectModalVisible, setAddProjectModalVisible] = useState(false);
    const [activeProjects, setActiveProjects] = useState([]);
    const [completedProjects, setCompletedProjects] = useState([]);
    const [archivedProjects, setArchivedProjects] = useState([]);
    // const [projectToEditId, setProjectToEditId] = useState(null);

    useEffect(() => {
        loadActiveProjectTable();
        loadCompletedProjectTable();
        if (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager') {
            loadArchivedProjectTable();
        }
    }, [auth.role, history]);

    const loadActiveProjectTable = () => {
        projectService.getProjects(false).then(res => {
            setActiveProjects(res);
        })
    }

    const loadCompletedProjectTable = () => {
        projectService.getProjects(true).then(res => {
            setCompletedProjects(res);
        })
    }

    const loadArchivedProjectTable = () => {
        projectService.getArchivedProjects().then(res => {
            setArchivedProjects(res);
        })
    }

    const columns = [
        {
            name: "projectName",
            label: "Nspection Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "projectType",
            label: "Nspection Type",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{activeProjects[dataIndex].projectType}</p>
                    )
                }
            },
        },
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{activeProjects[dataIndex].client}</p>
                    )
                }
            },
        },
        {
            name: "scanDate",
            label: "Scan Date",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{moment(activeProjects[dataIndex].scanDate).format('MM/DD/yyyy')}</p>
                    )
                }
            },
        },

        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div className='d-flex'>
                            <Link
                                className="btn btn-info btn-hover-info btn-sm mr-3"
                                to={{
                                    pathname: "/project/edit-project/" + activeProjects[dataIndex].id,
                                    state: { projectId: activeProjects[dataIndex].id },
                                    // state: { projectId: activeProjects[dataIndex].id}
                                }}
                            >
                                View
                            </Link>
                            {
                                (auth.role === 'SuperAdmin' || auth.role === 'Admin' || auth.role === 'Manager' || auth.role === 'User') &&
                                (
                                    <>
                                        <Link
                                            to={`/project/manage/${activeProjects[dataIndex].id}`}
                                            className="btn btn-success btn-hover-success btn-sm mr-3"
                                        >
                                            Edit
                                        </Link>
                                    </>
                                )
                            }
                            {
                                (auth.role === 'SuperAdmin' || auth.role === 'Admin' || auth.role === 'Manager') &&
                                <a href
                                    className="btn btn-danger btn-hover-danger btn-sm mr-3"
                                    onClick={() => showConfirmModal({
                                        title: "Delete Nspection",
                                        text: "Are you sure you want to delete this project?",
                                        rightBtnText: "Confirm",
                                        btnAction: () => deleteProject(activeProjects[dataIndex].id, activeProjects[dataIndex].clientId, activeProjects[dataIndex].projectTypeId, activeProjects[dataIndex].scanDate, false, true)
                                    })
                                    }
                                >
                                    Delete
                                </a>
                            }

                        </div >
                    );
                }
            }
        }
    ];

    const completed_columns = [
        {
            name: "projectName",
            label: "Nspection Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "projectType",
            label: "Nspection Type",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{completedProjects[dataIndex].projectType}</p>
                    )
                }
            },
        },
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{completedProjects[dataIndex].client}</p>
                    )
                }
            },
        },
        {
            name: "scanDate",
            label: "Scan Date",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{moment(completedProjects[dataIndex].scanDate).format('MM/DD/yyyy')}</p>
                    )
                }
            },
        },
        {
            name: "completedDateTime",
            label: "Completed Date",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{moment(completedProjects[dataIndex].completedDateTime).format('MM/DD/yyyy')}</p>
                    )
                }
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <Link
                                className="btn btn-info btn-hover-info btn-sm mr-3"
                                to={{
                                    pathname: "/project/edit-project/" + completedProjects[dataIndex].id,
                                    state: { projectId: completedProjects[dataIndex].id }
                                }}
                            >
                                View
                            </Link>
                            {
                                (auth.role === 'SuperAdmin' || auth.role === 'Admin' || auth.role === 'Manager' || auth.role === 'User') &&
                                (
                                    <>
                                         <>
                                        <Link
                                            to={`/project/manage/${completedProjects[dataIndex].id}`}
                                            className="btn btn-success btn-hover-success btn-sm mr-3"
                                        >
                                            Edit
                                        </Link>
                                    </>
                                        <a href
                                            className="btn btn-danger btn-hover-danger btn-sm mr-3"
                                            onClick={() => showConfirmModal({
                                                title: "Delete Nspection",
                                                text: "Are you sure you want to delete this project?",
                                                rightBtnText: "Confirm",
                                                btnAction: () => deleteProject(completedProjects[dataIndex].id, completedProjects[dataIndex].clientId, completedProjects[dataIndex].projectTypeId, completedProjects[dataIndex].scanDate, false, true)
                                            })
                                            }
                                        >
                                            Delete
                                        </a>
                                    </>
                                )
                            }
                        </>
                    );
                }
            }
        }
    ];
    const archived_column = [
        {
            name: "projectName",
            label: "Nspection Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "projectType",
            label: "Nspection Type",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{archivedProjects[dataIndex].projectType}</p>
                    )
                }
            },
        },
        {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{archivedProjects[dataIndex].client}</p>
                    )
                }
            },
        },
        {
            name: "scanDate",
            label: "Scan Date",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{moment(archivedProjects[dataIndex].scanDate).format('MM/DD/yyyy')}</p>
                    )
                }
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {
                                (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager') ?
                                    <a href
                                        className="btn btn-primary btn-hover-primary btn-sm mr-3"
                                        onClick={() => showConfirmModal({
                                            title: "Retrieve",
                                            text: "Are you sure you want to retrieve this project?",
                                            rightBtnText: "Confirm",
                                            btnAction: () => deleteProject(archivedProjects[dataIndex].id, archivedProjects[dataIndex].clientId, archivedProjects[dataIndex].projectTypeId, archivedProjects[dataIndex].scanDate, false, false)
                                        })
                                        }
                                    >
                                        Retrieve Nspection
                                    </a>
                                    : <></>
                            }
                        </>
                    );
                }
            }
        }
    ];

    const deleteProject = (projectId, clientId, projectTypeId, scanDate, isCompleted, isDeleted) => {
        setProcessing(true);
        let newModel = { ...deleteProjectModel, isDeleted: isDeleted, id: projectId, clientId: clientId, projectTypeId: projectTypeId, scanDate: scanDate };
        projectService.deleteProjectByIsDeleteFlag(newModel).then(res => {
            showAlert({ message: res.message, variant: 'success' })
            loadCompletedProjectTable();
            loadActiveProjectTable();
            loadArchivedProjectTable();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' });
        }).finally(() => {
            setProcessing(false);
            hideConfirmModal();
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className={`card card-custom card-stretch gutter-b}`}>
                        <div className="card-header bordmedia/svg/icons/Communication/Contact1.svger-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Active Nspections
                                </span>
                                <span className="text-muted mt-3 font-weight-bold fz-xs">
                                    View and Manage Active Nspections
                                </span>
                            </h3>
                            {/* <div className="card-toolbar">
                                {
                                    (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager' || auth.role === 'User') ?
                                        <button
                                            onClick={() => {
                                                setProjectToEditId(null);
                                                setAddProjectModalVisible(true);
                                            }}
                                            className="btn btn-success font-weight-bolder font-size-sm mr-3"
                                        >
                                            <span className="fa fa-plus"></span> Add Nspection
                                        </button>
                                        : <></>
                                }
                            </div> */}
                            <div className="card-toolbar">
                                {(auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager' || auth.role === 'User') ? (
                                    <Link
                                        to="/project/manage/0"
                                        className="btn btn-success font-weight-bolder font-size-sm mr-3"
                                    >
                                        <span className="fa fa-plus"></span> Add Nspection
                                    </Link>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="container-fluid">
                            <MUIDataTable
                                data={activeProjects}
                                columns={columns}
                                options={{
                                    selectableRows: false
                                }}
                            />
                            {/* {
                                (auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager' || auth.role === 'User') ?
                                    <NewProjectModal
                                        projectToEditId={projectToEditId}
                                        show={addProjectModalVisible}
                                        hide={() => {
                                            setAddProjectModalVisible(false);
                                            setProjectToEditId(null);
                                        }}
                                        resetProjectToEdit={() => setProjectToEditId(null)}
                                        reloadTable={() => loadActiveProjectTable()}
                                    /> : <></>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className={`card card-custom card-stretch gutter-b}`}>
                        <div className="card-header bordmedia/svg/icons/Communication/Contact1.svger-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Completed Nspections
                                </span>
                                <span className="text-muted mt-3 font-weight-bold fz-xs">
                                    View and Manage Completed Nspections
                                </span>
                            </h3>
                        </div>
                        <div className="container-fluid">
                            <MUIDataTable
                                data={completedProjects}
                                columns={completed_columns}
                                options={{
                                    selectableRows: false
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
            <br />
            {(auth.role === "SuperAdmin" || auth.role === "Admin" || auth.role === 'Manager') &&
                <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                        <div className={`card card-custom card-stretch gutter-b}`}>
                            <div className="card-header bordmedia/svg/icons/Communication/Contact1.svger-0 py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark">
                                        Archived Nspections
                                    </span>
                                    <span className="text-muted mt-3 font-weight-bold fz-xs">
                                        View and Manage Archived Nspections
                                    </span>
                                </h3>
                            </div>
                            <div className="container-fluid">
                                <MUIDataTable
                                    data={archivedProjects}
                                    columns={archived_column}
                                    options={{
                                        selectableRows: false
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            }


        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(ProjectListingPage);