import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import {toAbsoluteUrl} from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
    };
  }, [uiService]);

  return (
    <>
      <div
        id="kt_header_mobile"
        className={`header-mobile ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/* Logo */}
        <Link to="/">
          <img
            alt="Logo"
            className="logo-default max-h-40px"
            src={toAbsoluteUrl("/media/logos/logo.png")}
          />
        </Link>

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          {/* Quick Actions Toggle */}
          <button
            className="btn p-0 burger-icon ml-4"
            id="kt_quick_actions_toggle"
          >
            <span />
          </button>

          {/* Aside Toggle */}
          {/* {layoutProps.asideDisplay && (
            <button
              className="btn p-0 burger-icon burger-icon-left"
              id="kt_aside_mobile_toggle"
            >
              <span />
            </button>
          )} */}
        </div>
      </div>

      <style>{`
        @media (max-width: 991.98px) {
          .header-mobile .burger-icon {
            width: 25px;
            height: 25px;
          }

          .header-mobile .burger-icon span {
            background-color: #484f66;
          }

          .header-mobile .burger-icon span::before,
          .header-mobile .burger-icon span::after {
            background-color: #484f66;
          }

          .burger-icon {
            position: relative;
            display: inline-block;
            cursor: pointer;
          }

          .burger-icon span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            top: 50%;
            margin-top: -1px;
            transition: all 0.3s ease;
          }

          .burger-icon span::before,
          .burger-icon span::after {
            content: '';
            position: absolute;
            display: block;
            height: 2px;
            width: 100%;
            transition: all 0.3s ease;
          }

          .burger-icon span::before {
            top: -8px;
          }

          .burger-icon span::after {
            bottom: -8px;
          }

          .ml-4 {
            margin-left: 1rem !important;
          }
        }
      `}</style>
    </>
  );
}