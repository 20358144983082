import React from 'react';
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import * as confirmModal from "../../redux/confirmModalRedux";

const ConfirmModal = (props) => {
    const { showConfirmModal, hideConfirmModal, confirmModal, setProcessing } = props;
    return (
        <Modal centered size="md" show={confirmModal.isVisible} onHide={hideConfirmModal}>
            <Modal.Header closeButton>
                <Modal.Title>{confirmModal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {confirmModal.text}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideConfirmModal}>
                    Close
                    </Button>
                <button
                    type="submit"
                    className="btn btn-success mr-2"
                    disabled={confirmModal.isProcessing}
                    onClick={setProcessing, confirmModal.btnAction}
                >
                    {confirmModal.rightBtnText}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateFromProps = (state) => ({
    confirmModal: state.confirmModal
})

export default connect(mapStateFromProps, confirmModal.actions)(ConfirmModal)