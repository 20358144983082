import React, { useState, useEffect } from 'react';
import {
  Text,
  View,
  Image,
  StyleSheet,
  Link,
  Font
} from "@react-pdf/renderer";
import constants from '../../constants/constants';
import * as reportService from '../../services/settings/reportService';

const styles = StyleSheet.create({
  container: {
    marginTop: 4,
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: 10,
  },
  tableNameRow: {
    flexDirection: 'row',
    backgroundColor: '#e2eaf5',
    color: 'black',
    border: 1
  },
  tableSecondaryRow: {
    flexDirection: 'row',
    color: 'black',
    borderRight: 0.55,
    borderLeft: 0.55,
    borderBottom: 0.55
  },
  tableSecondaryRowHeaderContainer: {
    borderLeft: 0.55,
    borderBottom: 0.55,
    borderRight: 0.55,
    padding: 2,
    display: 'flex',
    justifyContent: 'center'
  },
  tableNameTextView: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#fff',
    textAlign: 'center',
    fontSize: 14,
    // fontFamily: 'Lato Bold'
  },
  tableSecondaryNameTextView: {
    flex: 1,
    borderWidth: 1,
    padding: 3,
    borderColor: '#fff',
    fontSize: 8,
    // fontFamily: 'Lato Bold'
  },
  dataRowContainer: {
    flexDirection: 'row',
  },
  dataCellContainer: {
    flex: 1,
    borderLeft: 0.55,
    borderBottom: 0.55,
    borderRight: 0.55,
    padding: 2,
    display: 'flex',
    justifyContent: 'center'
  },
  dataCellAnswer: {
    padding: 2,
    fontSize: 8,
    // fontFamily: 'Lato Bold',
  },
  dataCellLabel: {
    padding: 2,
    fontSize: 8,
  },
  tableHeaderLabel: {
    padding: 2,
    fontSize: 8,
    textAlign: 'center',
    // fontFamily: 'Lato Bold',
  },
  secondTableCellContainer: {
    borderLeft: 0.55,
    borderBottom: 0.55,
    borderRight: 0.55,
    padding: 2,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  }
});

Font.register({
  family: "Lato Bold",
  src: "https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf",
});

const PropertyImprovementPlanReportTable = (props) => {
  const { title, pins, modelLink, userId, project } = props;
  const [linkMap, setLinkMap] = useState({});

  useEffect(() => {
    // Generate the links for all pins and store them in the linkMap
    const generateLinksForAllPins = async () => {
      const newLinkMap = {};
      const link = await reportService.getHashLinkForReport(project.id, userId); 
      for(let pin of pins) {
        const key = pin.id;
        newLinkMap[key] = `${link}&key=${key}`;
      }
      setLinkMap(newLinkMap);
    };

    generateLinksForAllPins();
  }, []);
  
  return (
    <View style={styles.container}>
      <View style={styles.tableContainer}>
        {/* Header row with colspan */}
        <View style={styles.tableNameRow}>
          <View style={styles.tableNameTextView}>
            <Text colSpan={5}>{title}</Text>
          </View>
        </View>

        {title !== 'EXTERIOR/SITE' && (
          <View style={styles.tableSecondaryRow}>
            <View style={styles.tableSecondaryNameTextView}>
              <Text colSpan={5}>The below items are only interim solutions/repairs and are not part of the complete cycle renovations.For additional
                information, see the 14 (fourteen) year renovation cycles that include the entire Public Area, per the current renovation
                package.</Text>
            </View>
          </View>
        )}

        {/* Fixed Headers */}
        <View style={styles.dataRowContainer}>
          <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 35 }}>
            <Text style={styles.tableHeaderLabel}>Item #:</Text>
          </View>
          <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 120 }}>
            <Text style={styles.tableHeaderLabel}>THUMBNAIL</Text>
          </View>
          <View style={styles.dataCellContainer}>
            <Text style={styles.tableHeaderLabel}>SCOPE OF WORK</Text>
          </View>
          <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 55 }}>
            <Text style={styles.tableHeaderLabel}>Required Completion Date</Text>
          </View>
          <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 55 }}>
            <Text style={styles.tableHeaderLabel}>Actual Completion Date</Text>
          </View>
        </View>

        {pins.map((pin, index) => {
          const completeImageUrl = `${constants.API_URL}api/${pin.imageUrl}`;
          const requiredCompletionDate = pin.projectPinCustomFields.find(cf => cf.name === 'Required Completion Date')?.description;
          const actualCompletionDate = pin.projectPinCustomFields.find(cf => cf.name === 'Actual Completion Date')?.description;
          const key = pin.id;
          const pinLink = linkMap[key];
          
          return (
            <View key={pin.id} style={styles.dataRowContainer}>
              <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 35 }}>
                <Text style={styles.dataCellAnswer}>{index + 1}</Text>
              </View>
              <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 120, alignItems: 'center', textAlign: 'center' }}>
                <Image src={completeImageUrl} style={{ width: 90, height: 70 }} />
                {
                  modelLink ?
                    (
                      <Link src={pinLink}>
                        <Text style={styles.dataCellLabel}>{pin.title}</Text>
                      </Link>
                    )
                    :
                    <Text style={styles.dataCellLabel}>{pin.title}</Text>
                }
              </View>
              <View style={styles.dataCellContainer}>
                <Text style={styles.dataCellLabel}>{pin.description}</Text>
              </View>
              <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 55 }}>
                <Text style={styles.dataCellLabel}>{requiredCompletionDate}</Text>
              </View>
              <View style={{ ...styles.tableSecondaryRowHeaderContainer, width: 55 }}>
                <Text style={styles.dataCellLabel}>{actualCompletionDate}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export default PropertyImprovementPlanReportTable;