import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "UserProject/";

const GetUserProjects = async (userId) => await axiosInstance.get(ENDPOINTORIGIN + 'GetUserProjects?userId=' + userId, constants.REQUIRE_INTERCEPTORS);

const getUserProjectByUserId = async (userId) => await axiosInstance.get(ENDPOINTORIGIN + 'GetUserProjectByUserId?id=' + userId, constants.REQUIRE_INTERCEPTORS);

const saveUserProject = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveUserProject', model, constants.REQUIRE_INTERCEPTORS);

export {
    GetUserProjects,
    getUserProjectByUserId,
    saveUserProject,
}