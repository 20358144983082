import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { useFormik } from "formik";
import ChangePasswordValidator from "../../../../helpers/validators/ChangePasswordValidator";
import { getErrorField } from "../../../../helpers/helper";
import * as userService from "../../../../services/management/userService";
import * as alert from "../../../../redux/alertSnackBarRedux";

const defaultState = {
    password: '',
    confirmPassword: '',
}

const ChangePasswordPage = (props) => {
    const { showAlert } = props;
    const { userId } = useParams();
    const history = useHistory();
    const [initialState] = useState(defaultState);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialState,
        validationSchema: ChangePasswordValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            changePassword(values, setSubmitting);
        }
    });

    const changePassword = (values, setSubmitting) => {
        userService.changeUserPassword(userId, values.password).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            history.push('/users'); // Redirect to users list after successful password change
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => {
            setSubmitting(false);
        })
    }

    return (
        <div className="card card-custom">
            <div className="card-header">
                <h3 className="card-title">Update Password</h3>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                error={getErrorField(formik, "password")}
                                id="outlined-name"
                                label="New Password"
                                margin="normal"
                                type="password"
                                variant="outlined"
                                fullWidth
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="text-danger">
                                    {formik.errors.password}
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-6">
                            <TextField
                                error={getErrorField(formik, "confirmPassword")}
                                id="outlined-name"
                                label="Confirm Password"
                                margin="normal"
                                type="password"
                                variant="outlined"
                                fullWidth
                                {...formik.getFieldProps("confirmPassword")}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <div className="text-danger">
                                    {formik.errors.confirmPassword}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => history.push('/users')}
                        >
                            Cancel
                        </button>
                        &nbsp;
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
                        >
                            Save Password
                        </button>

                    </div>
                </div>
            </form>
        </div>
    );
}

export default connect(null, alert.actions)(ChangePasswordPage);