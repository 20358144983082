import React, { useState, useEffect, useCallback } from 'react';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as settingService from "../../../services/settings/settingService";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';

const SettingListingPage = (props) => {
    const { showAlert, showConfirmModal, hideConfirmModal, auth } = props;
    const [data, setData] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (auth?.user?.token) {
            const decodedToken = jwtDecode(auth.user.token);
            if (decodedToken.ClientId) {
                setClientId(decodedToken.ClientId);
            }
            if (decodedToken.role && decodedToken.role === 'SuperAdmin') {
                setIsSuperAdmin(true);
                setClientId(null);
            }
        }
    }, [auth]);

    useEffect(() => {
        if (clientId !== null || isSuperAdmin) {
            loadTableData();
        }
    }, [clientId, isSuperAdmin]);

    const loadTableData = useCallback(() => {
        if (isSuperAdmin) {
            settingService.getSettings().then(res => {
                setData(res);
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        } else if (clientId !== null) {
            settingService.getSettingsForClient(clientId).then(res => {
                setData(res);
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        }
    }, [clientId, isSuperAdmin, showAlert]);

    const deleteSetting = (id) => {
        settingService.deleteSetting(id).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "key",
            label: "Key",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return data[dataIndex].value ? data[dataIndex].value : "-";
                }
            }
        },
         // {
        //     name: "clientName",
        //     label: "Client",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRenderLite: (dataIndex) => {
        //             return (
        //                 <>
        //                     {data[dataIndex].client ? data[dataIndex].client.businessName : '-'}
        //                 </>
        //             );
        //         }
        //     }
        // },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => history.push(`/settings/setting/manage/${data[dataIndex].id}`)}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete Setting",
                                    text: "Are you sure you want to delete this setting?",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deleteSetting(data[dataIndex].id)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1 ml-2"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },
        },
    ];

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                Setting
                            </span>
                            <span className="text-muted mt-3 font-weight-bold fz-xs">
                                View and Manage Settings
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => history.push('/settings/setting/manage')}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add Setting
                            </button>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(SettingListingPage);