import * as Yup from "yup";

export default Yup.object().shape({
    id: Yup.number(),
    projectName: Yup.string().required("Nspection Name is required"),
    clientId: Yup.string().required("Client is required"),
    scanDate: Yup.string().required("Scan Date is required"),
    modelLink: Yup.string().nullable(),
    projectTypeId: Yup.string().required("Nspection Type is required"),
    notes: Yup.string().nullable(),
});