import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';

const ProjectTypeNavbar = ({ id, projectType }) => {

  return (
    <Navbar>
      <Container fluid>
        <Navbar.Brand as={Link} to="/projectTypes" className='btn btn-warning c-white'>
          Back to Inspection Type
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Item>
            <Link 
              to={{
                pathname: `/settings/point-category-listing/${id}`,
                state: { projectTypeId: id, projectType }
              }} 
              className='btn btn-info c-white ml-2'
            >
              N-Point Categories
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link 
              to={{
                pathname: `/settings/projectType-checkbox-listing/${id}`,
                state: { projectTypeId: id, projectType }
              }} 
              className='btn btn-info c-white ml-2'
            >
              Checkboxes
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link 
              to={{
                pathname: `/settings/poi-listing/${id}`,
                state: { projectTypeId: id, projectType }
              }} 
              className='btn btn-info c-white ml-2'
            >
              N-Point Statuses
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link 
              to={{
                pathname: `/settings/projectType-customField-listing/${id}`,
                state: { projectTypeId: id, projectType }
              }} 
              className='btn btn-info c-white ml-2'
            >
              Custom Fields
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link 
              to={{
                pathname: `/settings/projectType-metaDataField-listing/${id}`,
                state: { projectTypeId: id, projectType }
              }} 
              className='btn btn-info c-white ml-2'
            >
              Nspection Properties
            </Link>
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default ProjectTypeNavbar;