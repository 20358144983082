import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as settingService from "../../../services/settings/settingService";
import * as clientService from "../../../services/management/clientService";
import SettingValidator from "../../../helpers/validators/SettingValidator";
import { getErrorField } from "../../../helpers/helper";
import * as alert from "../../../redux/alertSnackBarRedux";
import jwtDecode from 'jwt-decode';

const SettingManagePage = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const { showAlert, auth } = props;
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        if (auth?.user?.token) {
            const decodedToken = jwtDecode(auth.user.token);
            if (decodedToken.ClientId) {
                setClientId(decodedToken.ClientId);
            }
            if (decodedToken.role && decodedToken.role === 'SuperAdmin') {
                setIsSuperAdmin(true);
                setClientId(null);
            }
        }
    }, [auth]);

    useEffect(() => {
        loadClients();
        if (id) {
            loadSetting();
        }
    }, [id, clientId, isSuperAdmin]);

    const loadClients = useCallback(() => {
        clientService.getClients().then(res => {
            setClients(res);
            if (!isSuperAdmin && clientId) {
                const preselectedClient = res.find(client => client.id === clientId);
                if (preselectedClient) {
                    formik.setFieldValue("clientIds", [clientId]);
                }
            }
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        });
    }, [clientId, isSuperAdmin, showAlert]);

    const loadSetting = () => {
        settingService.getSettingById(id).then(res => {
            formik.setValues({
                ...res,
                clientIds: res.clientId ? [res.clientId] : [],
            });
        });
    };

    const ExtendedSettingValidator = Yup.object().shape({
        ...SettingValidator.fields,
        clientIds: Yup.array().when('$isSuperAdmin', {
            is: false,
            then: Yup.array().min(1, "Please select a client").required("Client selection is required"),
            otherwise: Yup.array()
        })
    });

    const formik = useFormik({
        initialValues: {
            id: 0,
            key: '',
            value: '',
            clientIds: [],
        },
        validationSchema: ExtendedSettingValidator,
        onSubmit: (values, { setSubmitting }) => {
            if (!isSuperAdmin && (!values.clientIds || values.clientIds.length === 0)) {
                showAlert({ message: "Please select a client before saving the setting.", variant: "error" });
                setSubmitting(false);
                return;
            }

            const savePromise = id
                ? settingService.updateSetting(values)
                : settingService.saveSetting({ ...values, id: 0 });

            savePromise
                .then(res => {
                    showAlert({ message: res.message, variant: "success" });
                    history.push('/settings/setting');
                })
                .catch((ex) => {
                    showAlert({ message: ex.message, variant: "error" });
                    setSubmitting(false);
                });
        },
        context: { isSuperAdmin }
    });

    return (
        <div className="card card-custom">
            <div className="card-header">
                <h3 className="card-title">
                    {id ? 'Edit Setting' : 'Add Setting'}
                </h3>
            </div>
            <form className="form" onSubmit={formik.handleSubmit}>
                <div className="card-body">
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "key")}
                                id="outlined-name"
                                label="Title"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                {...formik.getFieldProps("key")}
                            />
                            {formik.touched.key && formik.errors.key ? (
                                <div className="text-danger">
                                    {formik.errors.key}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "value")}
                                id="outlined-name"
                                label="Value"
                                multiline
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                {...formik.getFieldProps("value")}
                            />
                            {formik.touched.value && formik.errors.value ? (
                                <div className="text-danger">
                                    {formik.errors.value}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {/* <div className="form-group row">
                                <div className="col-lg-12">
                                    {isSuperAdmin ? (
                                        isEditing ? (
                                            <Autocomplete
                                                options={clients}
                                                getOptionLabel={(option) => option.businessName}
                                                value={clients.find(client => client.id === formik.values.clientIds[0]) || null}
                                                onChange={(event, newValue) => {
                                                    formik.setFieldValue("clientIds", newValue ? [newValue.id] : []);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Client"
                                                        variant="outlined"
                                                        margin="normal"
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Autocomplete
                                                multiple
                                                options={clients}
                                                getOptionLabel={(option) => option.businessName}
                                                value={clients.filter(client => formik.values.clientIds?.includes(client.id))}
                                                onChange={(event, newValue) => {
                                                    formik.setFieldValue("clientIds", newValue.map(client => client.id));
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Clients (SuperAdmin can select multiple clients)"
                                                        variant="outlined"
                                                        margin="normal"
                                                    />
                                                )}
                                            />
                                        )
                                    ) : (
                                        <Autocomplete
                                            options={clients}
                                            getOptionLabel={(option) => option.businessName}
                                            value={clients.find(client => client.id === formik.values.clientIds[0]) || null}
                                            onChange={(event, newValue) => {
                                                formik.setFieldValue("clientIds", newValue ? [newValue.id] : []);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Client"
                                                    variant="outlined"
                                                    margin="normal"
                                                    error={formik.touched.clientIds && Boolean(formik.errors.clientIds)}
                                                    helperText={formik.touched.clientIds && formik.errors.clientIds}
                                                    required
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            </div> */}
                    <div className="d-flex justify-content-end mt-4">

                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => history.push('/settings/setting')}
                        >
                            Cancel
                        </button>
                        &nbsp;
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {id ? 'Update' : 'Save'}
                        </button>
                    </div>
                </div>

            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { ...alert.actions })(SettingManagePage);