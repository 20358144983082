import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectPinCustomField/";

const getProjectPinCustomFieldById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinCustomFieldById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectPinCustomFieldByProjectPinId = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinCustomFieldByProjectPinId?projPinId=' + id, constants.REQUIRE_INTERCEPTORS);

const getProjectPinCustomFields = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectPinCustomFields', constants.REQUIRE_INTERCEPTORS);

const saveProjectPinCustomField = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectPinCustomField', model, constants.REQUIRE_INTERCEPTORS);

const getProjectPinCustomFieldByProjectPinIdShareableReport = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'GetProjectPinCustomFieldByProjectPinIdShareableReport', model, constants.REQUIRE_INTERCEPTORS);

const saveProjectPinCustomFieldShareableReport = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectPinCustomFieldShareableReport', model, constants.REQUIRE_INTERCEPTORS);

const deleteProjectPinCustomField = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProjectPinCustomField?id=' + id, constants.REQUIRE_INTERCEPTORS);

export {
    getProjectPinCustomFieldById,
    getProjectPinCustomFieldByProjectPinId,
    getProjectPinCustomFields,
    saveProjectPinCustomField,
    saveProjectPinCustomFieldShareableReport,
    getProjectPinCustomFieldByProjectPinIdShareableReport,
    deleteProjectPinCustomField
}