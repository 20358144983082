import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Client/";

const getClientById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetClientById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getClients = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetClients', constants.REQUIRE_INTERCEPTORS);
const getPaymentProfilesForClient = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetPaymentProfilesForClient', constants.REQUIRE_INTERCEPTORS);
const getManagementLink = async (chargifyCustomerId) => await axiosInstance.get(ENDPOINTORIGIN + `GetManagementLink?chargifyCustomerId=${chargifyCustomerId}`, constants.REQUIRE_INTERCEPTORS);
const getSubscriptionById = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetSubscriptionById', constants.REQUIRE_INTERCEPTORS);

const generateSecurityToken = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GenerateSecurityToken', constants.REQUIRE_INTERCEPTORS);

const saveClient = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveClient', model, { ...constants.REQUIRE_INTERCEPTORS, fileHandler: true });

const upgradeClientTier = async (clientId, tierToUpgradeTo) => await axiosInstance.post(ENDPOINTORIGIN + 'UpgradeClientTier?clientId=' + clientId + '&tierToUpgradeTo=' + tierToUpgradeTo, {}, constants.REQUIRE_INTERCEPTORS)

const deleteClientByIsDeleteFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteClientByIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS);

const savePaymentProfileAndOrSubscribe = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SavePaymentProfileAndOrSubscribe', model, constants.REQUIRE_INTERCEPTORS);
const savePaymentProfile = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SavePaymentProfile', model, constants.REQUIRE_INTERCEPTORS);

export {
    getClientById,
    getClients,
    saveClient,
    generateSecurityToken,
    upgradeClientTier,
    getManagementLink,
    deleteClientByIsDeleteFlag,
    getPaymentProfilesForClient,
    savePaymentProfileAndOrSubscribe,
    getSubscriptionById,
    savePaymentProfile
}