import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { useFormik } from "formik";
import { makeStyles, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from "react-bootstrap";
import { blue } from '@material-ui/core/colors';
import ProjectTypeCustomFieldValidator from "../../../helpers/validators/ProjectTypeCustomFieldValidator";
import { getErrorField } from "../../../helpers/helper";
import * as projectTypeCustomFieldService from "../../../services/settings/projectTypeCustomFieldService";
import * as alert from "../../../redux/alertSnackBarRedux";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const BlueCheckBox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const fieldTypeList = [
    { value: 'fractional', label: 'Text (Fractional)' },
    { value: 'decimal', label: 'Text (Decimal)' },
    { value: 'fractionalWithUnit', label: 'Text (Fractional with Unit on Inches)' },
    { value: 'date', label: 'Text (Date)' },
    { value: 'textual', label: 'Text (Plain)' },
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'dropdown', label: 'Dropdown' },
    { value: 'multiselect', label: 'Multiselect' }
];

const ProjectTypeCustomFieldManagePage = (props) => {
    const { id, fieldId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const { showAlert } = props;
    const classes = useStyles();
    const [options, setOptions] = useState([]);
    const [showFieldOptions, setShowFieldOptions] = useState(false);
    const [projectType, setProjectType] = useState(location.state?.projectType || null);

    useEffect(() => {
        if (fieldId) {
            projectTypeCustomFieldService.getProjectTypeCustomFieldById(fieldId).then(res => {
                formik.setValues(res);
                if (res.customFieldTypeOptions) {
                    setOptions(res.customFieldTypeOptions.split(","));
                    setShowFieldOptions(true);
                }
            });
        }
    }, [fieldId]);

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            isEditable: false,
            isViewable: false,
            projectTypeId: id,
            customFieldType: '',
            isDeleted: false,
            isDefault: false
        },
        validationSchema: ProjectTypeCustomFieldValidator,
        onSubmit: (values) => {
            const optionsString = options.join(",");
            let newProjTypeCustomFields = { ...values, customFieldTypeOptions: optionsString };
            projectTypeCustomFieldService.saveProjectTypeCustomField(newProjTypeCustomFields).then(res => {
                showAlert({ message: res.message, variant: "success" });
                history.push(`/settings/projectType-customField-listing/${id}`, { projectType });
            }).catch((ex) => {
                showAlert({ message: ex.message, variant: "error" });
            });
        }
    });

    const handleCustomFieldTypeChange = (e) => {
        const selectedType = e.target.value;
        formik.setFieldValue("customFieldType", selectedType);

        if (selectedType === 'dropdown' || selectedType === 'multiselect') {
            setShowFieldOptions(true);
        } else {
            setShowFieldOptions(false);
            setOptions([]);
            formik.setFieldValue("customFieldTypeOptions", "");
        }
    };

    const addOption = () => {
        setOptions([...options, ""]);
    };

    const removeOption = index => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    const handleOptionChange = (value, index) => {
        const newOptions = options.map((option, i) => {
            if (i === index) {
                return value;
            }
            return option;
        });
        setOptions(newOptions);
        formik.setFieldValue("customFieldTypeOptions", newOptions.join(","));
    };

    return (
        <div className="col-lg-12 col-xxl-12">
            <div className="card card-custom card-stretch gutter-b">
                <div className="card-body">
                    <h3 className="card-title">
                        {/* Show Project Type Name */}
                        {projectType && (
                            <div>
                                <h5>{projectType.name}</h5>
                            </div>
                        )}
                        {fieldId ? "Edit Custom Field" : "Add Custom Field"}
                    </h3>
                    <form className="form" onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <TextField
                                    error={getErrorField(formik, "name")}
                                    id="outlined-name"
                                    label="Name"
                                    margin="normal"
                                    variant="outlined"
                                    {...formik.getFieldProps("name")}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-danger">
                                        {formik.errors.name}
                                    </div>
                                ) : null}
                            </div>
                            <div className='col-lg-12'>
                                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginTop: 10 }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Custom Field Type</InputLabel>
                                    <Select
                                        error={getErrorField(formik, "customFieldType")}
                                        native
                                        label="Custom Field Type"
                                        onChange={handleCustomFieldTypeChange}
                                        value={formik.values.customFieldType}
                                        inputProps={{
                                            name: 'customFieldType',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {fieldTypeList.map(x => <option key={x.value} value={x.value}>{x.label}</option>)}
                                    </Select>
                                    {formik.touched.customFieldType && formik.errors.customFieldType ? (
                                        <div className="text-danger">
                                            {formik.errors.customFieldType}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </div>
                            <div className="col-lg-12">
                                <TextField
                                    error={getErrorField(formik, "customFieldTypeOptions")}
                                    id="outlined-name"
                                    label="Custom Field Type Options"
                                    margin="normal"
                                    variant="outlined"
                                    value={options.join(",")}
                                    disabled
                                />
                                {formik.touched.customFieldTypeOptions && formik.errors.customFieldTypeOptions ? (
                                    <div className="text-danger">
                                        {formik.errors.customFieldTypeOptions}
                                    </div>
                                ) : null}
                            </div>

                            {showFieldOptions && (
                                <div className='col-lg-12' style={{ width: '100%' }}>
                                    <span className="text mt-3 font-weight-bold fz-xs">
                                        Manage your custom field options
                                    </span>
                                    <div className='col-lg-12' style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                        <Button className='btn btn-sm btn-info' onClick={addOption}>
                                            Add Option
                                        </Button>
                                    </div>
                                    {options.map((option, index) => (
                                        <div key={index} className='col-lg-12' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <FormControl variant="outlined" className='col-lg-6' >
                                                <TextField
                                                    margin="normal"
                                                    value={option}
                                                    onChange={(e) => handleOptionChange(e.target.value, index)}
                                                />
                                            </FormControl>
                                            <div className='col-lg-2' style={{ display: 'flex', alignItems: 'end' }}>
                                                <Button variant="danger" className='btn btn-sm' onClick={() => removeOption(index)}>
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="col-lg-12">
                                <div className="checkbox-container">
                                    <FormControlLabel
                                        control={
                                            <BlueCheckBox
                                                checked={formik.values.isEditable}
                                                onChange={(e, val) => {
                                                    formik.setFieldValue("isEditable", val);
                                                }}
                                            />
                                        }
                                        label="Is Editable in Report"
                                        labelPlacement="end"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="checkbox-container">
                                    <FormControlLabel
                                        control={
                                            <BlueCheckBox
                                                checked={formik.values.isViewable}
                                                onChange={(e, val) => {
                                                    formik.setFieldValue("isViewable", val);
                                                }}
                                            />
                                        }
                                        label="Is Viewable in Report"
                                        labelPlacement="end"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="checkbox-container">
                                    <FormControlLabel
                                        control={
                                            <BlueCheckBox
                                                checked={formik.values.isDefault}
                                                onChange={(e, val) => {
                                                    formik.setFieldValue("isDefault", val);
                                                }}
                                            />
                                        }
                                        label="User Upload Value"
                                        labelPlacement="end"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button className="btn-light mr-2" onClick={() => history.push(`/settings/projectType-customField-listing/${id}`, { projectType })}>
                                Cancel
                            </Button>
                            <Button className="btn-success" type="submit" disabled={formik.isSubmitting}>
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { ...alert.actions })(ProjectTypeCustomFieldManagePage);