/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as pointCategoryService from "../../../services/settings/pointCategoryService";
import { Button } from "react-bootstrap";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';

const PointCategorysListingPage = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { projectType } = location.state || {};
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    loadTableData();
  }, [id]);

  const loadTableData = useCallback(() => {
    if (id) {
      pointCategoryService.getPointCategoryByProjectTypeId(id).then(res => {
        setData(res);
      }).catch((ex) => {
        showAlert({ message: ex.message, variant: "error" });
      });
    }
  }, [id]);

  const deletePointCategory = (id, projectTypeId) => {
    let deleteModel = { id: id, projectTypeId: projectTypeId, isDeleted: true };
    pointCategoryService.deletePointCategoryIsDeletedFlag(deleteModel).then(res => {
      showAlert({ message: res.message, variant: "success" });
      loadTableData();
      hideConfirmModal();
    }).catch((ex) => {
      showAlert({ message: ex.message, variant: "error" });
    });
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].description || "-";
        },
      },
    },
    {
      name: "pointCategoryProjectTypeCustomFields",
      label: "Custom Fields",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            data[dataIndex].pointCategoryProjectTypeCustomFields
              .map((field) => field.projectTypeCustomField.name)
              .join(', ') || "-"
          );
        },
      },
    },
    {
      name: "isDefault",
      label: "User Upload Value",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].isDefault ? (
            <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2">
              User Upload Value
            </span>
          ) : (
           <></>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <button
                className="btn btn-sm btn-info font-weight-bold py-2 px-3 my-1"
                onClick={() =>
                  history.push(`/settings/point-category/manage/${id}/${data[dataIndex].id}`, { projectType: location.state.projectType })
                }
              >
                Edit
              </button>
              &nbsp;
              <button
                onClick={() =>
                  showConfirmModal({
                    title: "Delete Point Category",
                    text: "Are you sure you want to delete this point category?",
                    rightBtnText: "Confirm",
                    btnAction: () =>
                      deletePointCategory(data[dataIndex].id, data[dataIndex].projectTypeId),
                  })
                }
                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 my-1"
              >
                Delete
              </button>
            </>
          );
        },
      },
    },
  ];

  return (
    <div className="col-lg-12 col-xxl-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {projectType?.name}
            </span>
            <span className="text-muted mt-3 font-weight-bold">Point Categories</span>
          </h3>
          <div className="card-toolbar">
            <Button
              onClick={() => history.push(`/settings/point-category/manage/${id}`, { projectType: location.state.projectType })}
              className="btn btn-success"
            >
              <span className="fa fa-plus"></span> Add Point Category
            </Button>
          </div>
        </div>
        <ProjectTypeNavbar id={id} projectType={projectType} />
        <div className="container-fluid">
          <MUIDataTable data={data} columns={columns} options={{ selectableRows: 'none' }} />
        </div>
      </div>
    </div>
  );
};

export default connect(null, { ...alert.actions, ...confirmModal.actions })(PointCategorysListingPage);
