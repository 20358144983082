import React, { useState, useEffect, useCallback } from 'react';
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from '@material-ui/core';
import { connect } from "react-redux";
import { useParams, useLocation, useHistory } from 'react-router-dom';
import * as projectTypeCustomFieldService from "../../../services/settings/projectTypeCustomFieldService";
import * as alert from "../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../redux/confirmModalRedux";
import ProjectTypeNavbar from '../../components/ProjectTypeNavbar';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ProjectTypeCustomFieldListingPage = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const { projectTypeId, projectType } = location.state || {};
    const { showAlert, showConfirmModal, hideConfirmModal } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        loadTableData();
    }, [loadTableData]);

    const loadTableData = useCallback(() => {
        projectTypeCustomFieldService.getProjectTypeCustomFieldByProjectTypeId(id).then(res => {
            setData(res);
        }).catch((ex) => {
            showAlert({ message: ex.message, variant: "error" });
        });
    }, [id, showAlert]);

    const deleteProjectTypeCustomField = (id, projectTypeId) => {
        let newModel = { id: id, projectTypeId: projectTypeId, isDeleted: true }
        projectTypeCustomFieldService.deleteProjectTypeCustomFieldIsDeletedFlag(newModel).then(res => {
            showAlert({ message: res.message, variant: "success" });
            loadTableData();
            hideConfirmModal();
        })
    }

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "isEditable",
            label: "Is Editable In Report",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].isEditable ?
                                <span className="label label-lg c-white bg-blue label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                                    whiteSpace: 'nowrap'
                                }}>
                                    {'true'}
                                </span>
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "isViewable",
            label: "Is Viewable In Report",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].isViewable ?
                                <span className="label label-lg c-white bg-blue label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                                    whiteSpace: 'nowrap'
                                }}>
                                    {'true'}
                                </span>
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "customFieldType",
            label: "Custom Field Data Type",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].customFieldType ?
                                data[dataIndex].customFieldType
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "customFieldTypeOptions",
            label: "Custom Field Data Type Options",
            options: {
                filter: false,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {data[dataIndex].customFieldTypeOptions ?
                                data[dataIndex].customFieldTypeOptions
                                : "-"}
                        </>
                    )
                }
            },
        },
        {
            name: "isDefault",
            label: "Is Public",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return data[dataIndex].isDefault &&
                        <span className="label label-lg c-white bg-success label-inline font-weight-bold py-4 mr-3 mt-2 mb-2" style={{
                            whiteSpace: 'nowrap'
                        }}>
                            {'User Upload Value'}
                        </span>
                }

            }
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                width: 1,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                className="btn btn-sm btn-info font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={() => {
                                    history.push(`/settings/projectType-customField/manage/${id}/${data[dataIndex].id}`, { projectType });
                                }}
                            >
                                Edit
                            </button> &nbsp;
                            <button
                                onClick={() => showConfirmModal({
                                    title: "Delete N-Point Custom Field",
                                    text: "Are you sure you want to delete this n-point field? This will delete all the data related to custom fields in the Nspections.",
                                    rightBtnText: "Confirm",
                                    btnAction: () => deleteProjectTypeCustomField(data[dataIndex].id, data[dataIndex].projectTypeId)
                                })}
                                className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                            >
                                Delete
                            </button>
                        </>
                    );
                }
            },
        },
    ];

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                {projectType?.name}
                            </span>
                            <span className="card-label font-weight-bolder text-dark">
                                Custom Fields
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <button
                                onClick={() => history.push(`/settings/projectType-customField/manage/${id}`, { projectType })}
                                className="btn btn-success font-weight-bolder font-size-sm mr-3"
                            >
                                <span className="fa fa-plus"></span> Add Custom Field
                            </button>
                        </div>
                    </div>
                    <ProjectTypeNavbar id={id} projectType={projectType} />
                    <div className="container-fluid">
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ProjectTypeCustomFieldListingPage);