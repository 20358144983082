import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import * as projectService from "../../../../services/service/projectService";
import * as userProjectService from "../../../../services/management/userProjectService";
import * as alert from "../../../../redux/alertSnackBarRedux";

const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const AssignProjectPage = (props) => {
    const { showAlert } = props;
    const { userId } = useParams();
    const history = useHistory();
    const [projectList, setProjectList] = useState([]);
    const [userProjectList, setUserProjectList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (userId) {
            setLoading(true);
            Promise.all([
                projectService.getProjectByClientId(userId),
                userProjectService.GetUserProjects(userId)
            ]).then(([projects, userProjects]) => {
                setProjectList(projects);
                setUserProjectList(userProjects);
            }).catch(ex => {
                console.error(ex);
                showAlert({ message: "Error loading projects", variant: 'error' });
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [userId, showAlert]);

    const saveUserProject = (values) => {
        userProjectService.saveUserProject(values).then(res => {
            showAlert({ message: res.message, variant: 'success' });
        }).catch(ex => {
            console.error(ex);
            showAlert({ message: ex.message, variant: 'error' });
        });
    }

    return (
        <div className="card card-custom">
            <div className="card-header">
                <h3 className="card-title">Assign Nspections</h3>
            </div>
            <div className="card-body">
                {loading ? (
                    <div>Loading...</div>
                ) : projectList.length === 0 ? (
                    <div>No projects to display.</div>
                ) : (
                    <div className="row">
                        <div className="col-lg-12" style={{ maxHeight: 685, overflowY: 'auto' }}>
                            {projectList.map((proj, i) => (
                                <div key={i}>
                                    <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                        <FormControlLabel
                                            control={
                                                <BlueCheckbox
                                                    checked={userProjectList.find(x => x.projectID === proj.id)?.accessibleToUser ?? false}
                                                    onChange={(e, val) => {
                                                        let tempArr = [...userProjectList];
                                                        let tempUserProject = tempArr.find(x => x.projectID === proj.id);
                                                        if (!tempUserProject) {
                                                            tempUserProject = {
                                                                projectID: proj.id,
                                                                userID: userId,
                                                                accessibleToUser: val
                                                            };
                                                            tempArr.push(tempUserProject);
                                                        } else {
                                                            tempUserProject.accessibleToUser = val;
                                                        }
                                                        setUserProjectList(tempArr);
                                                        saveUserProject(tempUserProject);
                                                    }}
                                                />
                                            }
                                            label={proj.projectName}
                                            labelPlacement="end"
                                        />
                                    </FormControl>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="card-footer">
                <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={() => history.push('/users')}
                >
                    Back to Users
                </button>
            </div>
        </div>
    );
}

export default connect(null, alert.actions)(AssignProjectPage);