import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as projectTypeService from '../../../../services/service/projectTypeService';
import * as alert from '../../../../redux/alertSnackBarRedux';
import { getErrorField } from '../../../../helpers/helper';
import CreateProjectTypeValidator from "../../../../helpers/validators/CreateProjectTypeValidator";

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: theme.spacing(4),
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

const ProjectTypeEditPage = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const projectType = location.state || {};
    const { showAlert } = props;
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [projectTypeData, setProjectTypeData] = useState(null);

    useEffect(() => {
        projectTypeService.getProjectTypeById(id).then(res => {
            setProjectTypeData(res);
        });
    }, [id]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: projectTypeData || { name: '', description: '', clientId: '' },
        validationSchema: CreateProjectTypeValidator,
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true);
            projectTypeService.saveProjectType(values)
                .then(res => {
                    showAlert({ message: res.message, variant: 'success' });
                    history.push('/projecttypes');
                })
                .catch(ex => {
                    showAlert({ message: ex.message, variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                    setSubmitting(false);
                });
        }
    });

    if (!projectTypeData) return <div>Loading...</div>;

    return (
        <div className="col-lg-12 col-xxl-12">
            <div className="card card-custom card-stretch gutter-b">
                <div className="card-body">
                    <h3 className="card-title">Edit Project Type</h3>

                    <form onSubmit={formik.handleSubmit}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                {...formik.getFieldProps("name")}
                                error={getErrorField(formik, "name")}
                                helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                            />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                {...formik.getFieldProps("description")}
                                error={getErrorField(formik, "description")}
                                helperText={formik.touched.description && formik.errors.description ? formik.errors.description : ''}
                            />
                        </FormControl>

                        <div className="d-flex justify-content-end">
                            <Button className="btn-light mr-2" onClick={() => history.push('/projecttypes')}>Cancel</Button>
                            <Button className="btn-success" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                Save Changes
                            </Button>
                        </div>
                    </form>

                    {loading && <LinearProgress />}
                </div>
            </div>
        </div>
    );
}

export default connect(null, alert.actions)(ProjectTypeEditPage);
