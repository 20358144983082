import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from 'react-bootstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as projectTypeCheckBoxService from '../../../services/settings/projectTypeCheckBoxService';
import ProjectTypeCheckBoxValidator from '../../../helpers/validators/ProjectTypeCheckBoxValidator';
import * as alert from '../../../redux/alertSnackBarRedux';

const initialCheckBoxState = {
  id: 0,
  name: '',
  projectTypeId: '',
  isDefault: false,
  isDeleted: false,
};

const ProjectTypeCheckBoxManagePage = (props) => {
  const { showAlert } = props;
  const { id, checkboxId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState(initialCheckBoxState);
  const [projectType, setProjectType] = useState(location.state?.projectType || null);

  useEffect(() => {
    if (checkboxId) {
      loadCheckBoxData(checkboxId);
    }
  }, [checkboxId]);

  const loadCheckBoxData = (id) => {
    projectTypeCheckBoxService.getProjectTypeCheckBoxById(id).then((res) => {
      setCheckBoxData(res);
    }).catch((ex) => {
      showAlert({ message: ex.message, variant: 'error' });
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: checkBoxData,
    validationSchema: ProjectTypeCheckBoxValidator,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      const updatedValues = {
        ...values,
        projectTypeId: id,
      };

      projectTypeCheckBoxService.saveProjectTypeCheckBox(updatedValues)
        .then((res) => {
          showAlert({ message: res.message, variant: 'success' });
          history.push(`/settings/projectType-checkbox-listing/${id}`, { projectType });
        })
        .catch((ex) => {
          showAlert({ message: ex.message, variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="col-lg-12 col-xxl-12">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body">
          <h3 className="card-title">
            {/* Show Project Type Name */}
            {projectType && (
              <div>
                <h5>{projectType.name}</h5>
              </div>
            )}
            {checkboxId ? 'Edit CheckBox' : 'Add New CheckBox'}
          </h3>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Name"
              variant="outlined"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              margin="normal"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isDefault}
                  onChange={(e) => formik.setFieldValue('isDefault', e.target.checked)}
                />
              }
              label="User Upload Value"
            />

            <div className="d-flex justify-content-end">
              <Button className="btn-light mr-2" onClick={() => history.push(`/settings/projectType-checkbox-listing/${id}`, { projectType })}>
                Cancel
              </Button>
              <Button className="btn-success" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                {checkboxId ? 'Update' : 'Create'} CheckBox
              </Button>
            </div>
          </form>

          {loading && <LinearProgress />}
        </div>
      </div>
    </div>
  );
};

export default connect(null, alert.actions)(ProjectTypeCheckBoxManagePage);
