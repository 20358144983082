/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import CreateProjectTypeValidator from "../../helpers/validators/CreateProjectTypeValidator";
import { useFormik } from "formik";
import { getErrorField } from '../../helpers/helper';

import * as projectTypeService from '../../services/service/projectTypeService';
import * as clientService from '../../services/management/clientService';

import * as alert from '../../redux/alertSnackBarRedux';
import * as confirmModal from "../../redux/confirmModalRedux";

const initialState = {
    id: 0,
    name: '',
    description: '',
    clientId: '',
    isDeleted: false
}
const NewProjectTypeModal = (props) => {
    const { show, hide, reloadTable, showAlert, showConfirmModal, hideConfirmModal } = props;

    const [projectType, setProjectType] = useState(initialState);
    const [clientList, setClientList] = useState([]);
    const [clientId, setClientId] = useState(0);

    useEffect(() => {
        clientService.getClients().then(res => {
            setClientList(res);
        });
    }, [show, clientId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: projectType,
        validationSchema: CreateProjectTypeValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
           
            createProjectType(values, setSubmitting);
        }
    });

    const createProjectType = (values, setSubmitting) => {
        projectTypeService.saveProjectType(values).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            reloadTable();
            hideModal();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'success' });
        }).finally(() => {
            setSubmitting(false);
        })
    }

    const hideModal = () => {
        setProjectType(initialState);
        formik.resetForm();
        hide();
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create New Nspection Type
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row" style={{alignContent:'center'}}>
                        <div className="col-lg-12">
                            <TextField
                                error={getErrorField(formik, "name")}
                                id="outlined-name"
                                label="Name"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("name")}
                            />

                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                    {formik.errors.name}
                                </div>
                            ) : null}

                            <TextField
                                error={getErrorField(formik, "description")}
                                id="outlined-description"
                                label="Description"
                                margin="normal"
                                variant="outlined"
                                {...formik.getFieldProps("description")}
                            />

                            {formik.touched.description && formik.errors.description ? (
                                <div className="text-danger">
                                    {formik.errors.description}
                                </div>
                            ) : null}


                            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                                <InputLabel htmlFor="outlined-age-native-simple">Client</InputLabel>
                                <Select
                                    error={getErrorField(formik, "clientId")}
                                    native
                                    label="Client"
                                    onChange={e => {
                                        if (e.target.value === "") {
                                            formik.setFieldValue("clientId", "");
                                            setClientId(0);
                                        }
                                        else {
                                            formik.setFieldValue("clientId", Number(e.target.value));
                                            setClientId(Number(e.target.value));
                                        }
                                    }}
                                    inputProps={{
                                        name: 'Client',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {
                                        clientList.map(x => <option key={x.id} value={x.id}>{x.businessName}</option>)
                                    }
                                </Select>
                                {formik.touched.clientId && formik.errors.clientId ? (
                                    <div className="text-danger">
                                        {formik.errors.clientId}
                                    </div>
                                ) : null}
                            </FormControl>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-light" onClick={hideModal}>Cancel</Button>
                    <Button className="btn-success" disabled={formik.isSubmitting || (formik.touched && !formik.isValid)} type="submit">Create Nspect-Type</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default connect(null, {...alert.actions, ...confirmModal.actions })(NewProjectTypeModal);