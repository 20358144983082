import store from "../../redux/store";

const isEnabled = (config, property) => config.hasOwnProperty(property) && config[property];

//handles requests going from app to server
const requestHandler = async (request) => {
    if (isEnabled(request, 'requestHandlerEnabled')) {
        if (request.url.includes('report')) { 
            const reportToken = localStorage.getItem('reportToken');
            if (reportToken) {
                request.headers['Authorization'] = 'Bearer ' + reportToken;
            }
        } else {
            const {
                auth: { authToken }
            } = store.getState();

            const storedToken = localStorage.getItem('authToken') || authToken;
            
            if (storedToken) {
                request.headers['Authorization'] = 'Bearer ' + storedToken;
            }
        }
    }

    if (isEnabled(request, 'params')) {
        request.params = request.params || {};
        const params = request.params || {};
        Object.assign(request.params, params);
      }

    request.headers['Content-Type'] = !isEnabled(request, 'fileHandler') ? 'application/json' : 'multipart/form-data';
    request.headers['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, DELETE';
    return request;
}

//handles responses coming from server to app
const successHandler = (response) => {
    if (isEnabled(response.config, 'responseHandlerEnabled')) {
        return response.data;
    }

    return response;
}

const errorHandler = async (response) => {
    if (isEnabled(response.config, 'responseHandlerEnabled')) {
        // console.log("I am handling error responses");
    }

    var message = "Something went wrong!";

    await Promise.resolve(response.response).then(res => {
        message = res.data.Message;
       
        //if 401 redirect to login page
        if (res.status === 401) {
            localStorage.removeItem('reportToken');
            localStorage.removeItem('persist:auth-token');
            window.location = window.origin + '/login';
            return;
        }
        //if not authorized redirect to dashboard
        if (res.status === 403) {
            localStorage.removeItem('reportToken');
            window.location = window.origin + '/';
            return;
        }
    });

    throw Error(message);
}

export {
    requestHandler,
    successHandler,
    errorHandler
}