import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import * as userService from "../../../../services/management/userService";
import { connect } from "react-redux";
import * as alert from "../../../../redux/alertSnackBarRedux";
import { checkTierPlanAndRoleFreeTier } from '../../../../helpers/helper';
import * as confirmModal from "../../../../redux/confirmModalRedux";
import ChangePasswordModal from "../../../components/ChangePasswordModal";

const UserListingPage = (props) => {
    const history = useHistory();
    const { showAlert, showConfirmModal, hideConfirmModal, tierPlan, auth } = props;
    const [users, setUsers] = useState([]);
    const [userToEditId, setUserToEditId] = useState(null);
    const [changePasswordModalIsVisible, setChangePasswordModalIsVisible] = useState(false);

    useEffect(() => {
        loadUsersTable();
    }, []);

    const loadUsersTable = () => {
        userService.getAllUsers().then(res => {
            setUsers(res);
        })
    }

    const columns = [
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "phoneNumber",
            label: "Phone Number",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        },
        {
            name: "username",
            label: "Username",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
            },
        }, {
            name: "client",
            label: "Client",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{users[dataIndex].client ? users[dataIndex].client : '-'}</p>
                    )
                }
            },
        },
        {
            name: "role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
                width: 1,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>{users[dataIndex].role}</p>
                    )
                }
            },
        },

        // {
        //     name: "isAdmin",
        //     label: "Is Admin?",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRenderLite: (dataIndex) => {
        //             return (
        //                 <SVG
        //                     className="h-50 align-self-center"
        //                     src={toAbsoluteUrl(users[dataIndex].role =='SuperAdmin' ? "/media/svg/icons/Navigation/Check.svg" : "/media/svg/icons/Navigation/Close.svg")}
        //                 >
        //                 </SVG>
        //             )
        //         }
        //     }
        // },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <button
                                disabled={users[dataIndex].role === "SuperAdmin" || users[dataIndex].role === "Admin" || users[dataIndex].role === 'Manager'}
                                className="btn btn-warning btn-sm mr-3"
                                onClick={() => {
                                    history.push(`/user/assign-project/${users[dataIndex].id}`);
                                }}
                            >
                                Assign
                            </button>
                            <button
                                className="btn btn-info btn-sm mr-3"
                                onClick={() => {
                                    history.push(`/user/manage/${users[dataIndex].id}`);
                                }}
                            >
                                Edit
                            </button>
                            {
                                checkTierPlanAndRoleFreeTier(tierPlan, auth.role) &&
                                <button
                                    className="btn btn-danger btn-sm mr-3"
                                    onClick={() => showConfirmModal({
                                        title: "Delete User",
                                        text: "Are you sure you want to delete this user?",
                                        rightBtnText: "Confirm",
                                        btnAction: () => deleteUser(users[dataIndex].id, users[dataIndex].clientId, users[dataIndex].roleId)
                                    })}
                                >
                                    Delete
                                </button>
                            }
                            <button
                                className="btn btn-success btn-sm mr-3"
                                onClick={() => {
                                    history.push(`/user/change-password/${users[dataIndex].id}`);
                                }}
                            >
                                Update Password
                            </button>
                        </>
                    );
                }
            }
        }
    ];

    const deleteUser = (userId, clientId, roleId) => {
        let newModel = { isDeleted: true, id: userId, roleId: roleId, clientId: clientId };
        userService.deleteUserByIsDelete(newModel).then(res => {
            showAlert({ message: res.message, variant: 'success' });
            loadUsersTable();
        }).catch(ex => {
            showAlert({ message: ex.message, variant: 'error' })
        }).finally(() => hideConfirmModal())
    }

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b}`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                Users
                            </span>
                            <span className="text-muted mt-3 font-weight-bold fz-xs">
                                View and Manage Users
                            </span>
                        </h3>
                        {
                            (auth.role === 'SuperAdmin' || auth.role === 'Admin') &&
                            <div className="card-toolbar">
                                <button
                                    onClick={() => history.push('/user/manage/0')}
                                    className="btn btn-success font-weight-bolder font-size-sm mr-3"
                                >
                                    <span className="fa fa-plus"></span> Add User
                                </button>
                            </div>
                        }
                    </div>
                    <div className="container-fluid">
                        <MUIDataTable
                            data={users}
                            columns={columns}
                            options={{
                                selectableRows: 'none'
                            }}
                        />
                        <ChangePasswordModal
                            userToEditId={userToEditId}
                            show={changePasswordModalIsVisible}
                            resetUserToEdit={() => setUserToEditId(null)}
                            hide={() => setChangePasswordModalIsVisible(false)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps, { ...alert.actions, ...confirmModal.actions })(UserListingPage);