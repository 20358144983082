/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import constants from "../../../../constants/constants";
import { useFormik } from "formik";
import { blue } from '@material-ui/core/colors';
import { Button, Spinner } from 'react-bootstrap';
import ClientValidator from "../../../../helpers/validators/ClientValidator";
import { getErrorField, convertJsonToFormData } from "../../../../helpers/helper";
import { makeStyles, withStyles } from '@material-ui/core';
import * as clientService from "../../../../services/management/clientService";
import * as clientTierService from "../../../../services/management/clientTierService";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as alert from "../../../../redux/alertSnackBarRedux";
import * as confirmModal from "../../../../redux/confirmModalRedux";
import { FilePond } from "react-filepond";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const initialState = {
    id: 0,
    logoPath: null,
    businessName: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    companyAddress: '',
    clientTierId: '',
    noOfProject: '',
    noOfReport: '',
    isDeleted: false,
    removeWaterMark: false,
    removeCobrandedReport: true,
    allowAnonymousCollaboration: false
}

const ClientManagementPage = (props) => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { history, showAlert } = props;
    const [client, setClient] = useState(initialState);
    const [tierList, setTierList] = useState([]);
    const [logo, setLogo] = useState([]);

    useEffect(() => {
        if (id > 0) {
            getClientById();
        }
        setToFreeTier();
    }, [getClientById, id, setToFreeTier]);

    const getClientById = async () => {
        setIsLoading(true);
        const res = await clientService.getClientById(id);
        setClient(res);
        setIsLoading(false);
    }

    const setToFreeTier = async () => {
        const res = await clientTierService.getClientTiers();
        setTierList(res);
        if (id == 0) {
            var freeTier = res.find(x => x.name === 'Individual');
            if (freeTier) {
                formik.setFieldValue('clientTierId', freeTier.id);
            }
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: client,
        validationSchema: ClientValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveClient(values, setSubmitting);
        }
    });

    const saveClient = async (values, setSubmitting) => {
        const clientModel = {
            ...values,
            logoFile: logo.length > 0 ? logo[0].file : null
        };

        try {
            setIsLoading(true);
            const res = await clientService.saveClient(convertJsonToFormData(clientModel));
            showAlert({ message: res.message, variant: 'success' });
            if (parseInt(id, 10) === 0) {
                history.push('/clients');
            }
        } catch (ex) {
            showAlert({ message: ex.message, variant: 'error' });
        } finally {
            setIsLoading(false);
            setSubmitting(false);
        }
    }

    // const hideClientModal = () => {
    //     setClient(initialState);
    //     formik.resetForm();
    //     setToFreeTier();
    //     props.hide();
    // }

    return (
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className={`card card-custom card-stretch gutter-b`}>
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                Client
                            </span>
                            <span className="text-muted mt-3 font-weight-bold fz-xs">
                                View and Manage Client
                            </span>
                        </h3>
                        {
                            isLoading ? <Spinner animation="border" /> : (
                                <>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="card-toolbar">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    {
                                                        client.logoPath ?
                                                            <>
                                                                <div
                                                                    className="image-input-wrapper background-image-container"
                                                                    style={{ backgroundImage: `url(${constants.API_URL + 'api/' + client.logoPath})`, height: '200px' }}
                                                                />
                                                                <button onClick={() => {
                                                                    setClient({ ...client, logoPath: null })
                                                                }} className="btn btn-info w-100 mt-lg-5 mb-lg-5">Change Logo</button>
                                                            </> :
                                                            <FilePond
                                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                                onupdatefiles={setLogo}
                                                            />
                                                    }
                                                </div>
                                                <div className="col-lg-6">
                                                    <TextField
                                                        error={getErrorField(formik, "businessName")}
                                                        id="outlined-name"
                                                        label="Business Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                        {...formik.getFieldProps("businessName")}
                                                    />

                                                    {formik.touched.businessName && formik.errors.businessName ? (
                                                        <div className="text-danger">
                                                            {formik.errors.businessName}
                                                        </div>
                                                    ) : null}
                                                </div>


                                                <div className="col-lg-6">
                                                    <TextField
                                                        error={getErrorField(formik, "contactName")}
                                                        id="outlined-name"
                                                        label="Contact Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                        {...formik.getFieldProps("contactName")}
                                                    />
                                                    {formik.touched.contactName && formik.errors.contactName ? (
                                                        <div className="text-danger">
                                                            {formik.errors.contactName}
                                                        </div>
                                                    ) : null}
                                                </div>


                                                <div className="col-lg-6">
                                                    <TextField
                                                        error={getErrorField(formik, "contactPhone")}
                                                        id="outlined-name"
                                                        label="Contact Phone"
                                                        margin="normal"
                                                        variant="outlined"
                                                        {...formik.getFieldProps("contactPhone")}
                                                    />
                                                    {formik.touched.contactPhone && formik.errors.contactPhone ? (
                                                        <div className="text-danger">
                                                            {formik.errors.contactPhone}
                                                        </div>
                                                    ) : null}
                                                </div>


                                                <div className="col-lg-6">
                                                    <TextField
                                                        error={getErrorField(formik, "contactEmail")}
                                                        id="outlined-name"
                                                        label="Contact Email"
                                                        margin="normal"
                                                        variant="outlined"
                                                        {...formik.getFieldProps("contactEmail")}
                                                    />
                                                    {formik.touched.contactEmail && formik.errors.contactEmail ? (
                                                        <div className="text-danger">
                                                            {formik.errors.contactEmail}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="col-lg-12">
                                                    <TextField
                                                        error={getErrorField(formik, "companyAddress")}
                                                        id="outlined-name"
                                                        label="Company Address"
                                                        margin="normal"
                                                        variant="outlined"
                                                        {...formik.getFieldProps("companyAddress")}
                                                    />
                                                    {formik.touched.companyAddress && formik.errors.companyAddress ? (
                                                        <div className="text-danger">
                                                            {formik.errors.companyAddress}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="col-lg-6">
                                                    <TextField
                                                        error={getErrorField(formik, "noOfProject")}
                                                        id="outlined-name"
                                                        label="No of Nspections"
                                                        margin="normal"
                                                        variant="outlined"
                                                        {...formik.getFieldProps("noOfProject")}
                                                    />
                                                    {formik.touched.noOfProject && formik.errors.noOfProject ? (
                                                        <div className="text-danger">
                                                            {formik.errors.noOfProject}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="col-lg-6">
                                                    <TextField
                                                        error={getErrorField(formik, "noOfReport")}
                                                        id="outlined-name"
                                                        label="No of Report"
                                                        margin="normal"
                                                        variant="outlined"
                                                        {...formik.getFieldProps("noOfReport")}
                                                    />
                                                    {formik.touched.noOfReport && formik.errors.noOfReport ? (
                                                        <div className="text-danger">
                                                            {formik.errors.noOfReport}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="col-lg-6">
                                                    <FormControl variant="outlined" style={{ width: '100%', marginTop: 15 }}>
                                                        <InputLabel htmlFor="outlined-age-native-simple">Tier Plan</InputLabel>
                                                        <Select
                                                            error={getErrorField(formik, "clientTierId")}
                                                            native
                                                            disabled={!id || (client && !client.paymentProfileId)}
                                                            label="Client Tier"
                                                            inputProps={{
                                                                name: 'ClientTier',
                                                                id: 'outlined-age-native-simple',
                                                            }}
                                                            {...formik.getFieldProps("clientTierId")}
                                                            onChange={e => {
                                                                if (e.target.value === "") {
                                                                    formik.setFieldValue("clientTierId", "");
                                                                }
                                                                else {
                                                                    formik.setFieldValue("clientTierId", Number(e.target.value));
                                                                }
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" />
                                                            {
                                                                tierList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                                            }

                                                        </Select>
                                                        {formik.touched.clientTierId && formik.errors.clientTierId ? (
                                                            <div className="text-danger">
                                                                {formik.errors.clientTierId}
                                                            </div>
                                                        ) : null}
                                                    </FormControl>
                                                </div>

                                                <div className="col-lg-6">
                                                    <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                                        <div className="checkbox-container">
                                                            {
                                                                <FormControlLabel
                                                                    control={
                                                                        <BlueCheckbox
                                                                            checked={formik.values.removeWaterMark}
                                                                            onChange={(e, val) => {
                                                                                formik.setFieldValue("removeWaterMark", val);
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Remove Water Mark"
                                                                    labelPlacement="end"
                                                                />
                                                            }
                                                        </div>
                                                    </FormControl>
                                                </div>

                                                <div className="col-lg-6">
                                                    <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                                        <div className="checkbox-container">
                                                            {
                                                                <FormControlLabel
                                                                    control={
                                                                        <BlueCheckbox
                                                                            checked={formik.values.removeCobrandedReport}
                                                                            onChange={(e, val) => {
                                                                                formik.setFieldValue("removeCobrandedReport", val);
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Remove Co-branding"
                                                                    labelPlacement="end"
                                                                />
                                                            }
                                                        </div>
                                                    </FormControl>
                                                </div>
                                                <div className="col-lg-6">
                                                    <FormControl className="col-lg-12 d-flex justify-content-between flex-column pt-4">
                                                        <div className="checkbox-container">
                                                            {
                                                                <FormControlLabel
                                                                    control={
                                                                        <BlueCheckbox
                                                                            checked={formik.values.allowAnonymousCollaboration}
                                                                            onChange={(e, val) => {
                                                                                formik.setFieldValue("allowAnonymousCollaboration", val);
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Allow Anonymous Collaboration"
                                                                    labelPlacement="end"
                                                                />
                                                            }
                                                        </div>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-content-end w-100 justify-content-end'>
                                            <Button
                                                className="btn-danger ml-3 mr-3"
                                                type="button"
                                                onClick={history.goBack}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn-success"
                                                type="submit"
                                                disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
                                            >
                                                {id > 0 ? "Save" : "Add"} Client    {formik.isSubmitting && <span className="ml-3 mr-3 spinner spinner-white"></span>}
                                            </Button>
                                        </div>
                                    </form>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { ...alert.actions, ...confirmModal.actions })(ClientManagementPage);