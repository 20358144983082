import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProjectType/";

const getProjectTypes = async (clientId) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypes?clientId=' + clientId, constants.REQUIRE_INTERCEPTORS);

const getProjectTypeById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const setDefaultProjectType = async (clientId, id) => await axiosInstance.get(ENDPOINTORIGIN + `SetDefaultProjectType?clientId=${clientId}&projectTypeId=${id}`, constants.REQUIRE_INTERCEPTORS);

const getAllProjectTypes = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetAllProjectTypes', constants.REQUIRE_INTERCEPTORS);

const getProjectTypeMetaDataFields = async (projectTypeId) => 
    await axiosInstance.get(ENDPOINTORIGIN + 'GetProjectTypeMetaDataFields?projectTypeId=' + projectTypeId, constants.REQUIRE_INTERCEPTORS);

const saveProjectType = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProjectType', model, constants.REQUIRE_INTERCEPTORS);

const deleteProjectType = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteProjectType?id=' + id, constants.REQUIRE_INTERCEPTORS);

const deleteProjectTypeIsDeletedFlag = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteProjectTypeIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS);

export {
    getProjectTypes,
    getProjectTypeById,
    getAllProjectTypes,
    getProjectTypeMetaDataFields,
    saveProjectType,
    setDefaultProjectType,
    deleteProjectType,
    deleteProjectTypeIsDeletedFlag
}